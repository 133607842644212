import { ElmApp, FromElm } from "types/elm";

import connect from "./connect";
import Sentry, { reportIssue } from "./sentry";
import { FromElmData } from "./types";

const handlers: {
  [Tag in FromElm["tag"]]: (data: FromElmData<Tag>, app: ElmApp) => void;
} = {
  saveCaller(data) {
    if (data.phone) localStorage.setItem("caller.phone", data.phone);
    if (data.name) localStorage.setItem("caller.name", data.name);
    if (data.email) localStorage.setItem("caller.email", data.email);
    if (data.lastMedium)
      localStorage.setItem("caller.lastMedium", data.lastMedium);
  },
  breadcrumb(data) {
    Sentry.addBreadcrumb({
      category: "elm",
      message: "State transition",
      level: "info",
      data,
    });
  },
  error(data) {
    console.error("elm error: ", data.message);
    Sentry.captureMessage(data.message);
  },
  connect,
  reset(_data) {
    localStorage.clear();
    Sentry.addBreadcrumb({
      category: "elm",
      message: "reset",
      level: "info",
    });
    window.location.reload();
  },
  reload(_data) {
    window.location.reload();
  },
  issue(_data) {
    reportIssue();
  },
};

export default handlers;
