// -- (function(scope){
// -- 'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}

console.warn('Compiled in DEV mode. Follow the advice at https://elm-lang.org/0.19.1/optimize for better performance and smaller assets.');


// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**_UNUSED/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**_UNUSED/
	if (typeof x.$ === 'undefined')
	//*/
	/**/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0_UNUSED = 0;
var _Utils_Tuple0 = { $: '#0' };

function _Utils_Tuple2_UNUSED(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3_UNUSED(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr_UNUSED(c) { return c; }
function _Utils_chr(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil_UNUSED = { $: 0 };
var _List_Nil = { $: '[]' };

function _List_Cons_UNUSED(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log_UNUSED = F2(function(tag, value)
{
	return value;
});

var _Debug_log = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString_UNUSED(value)
{
	return '<internals>';
}

function _Debug_toString(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash_UNUSED(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.start.line === region.end.line)
	{
		return 'on line ' + region.start.line;
	}
	return 'on lines ' + region.start.line + ' through ' + region.end.line;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap(value) { return { $: 0, a: value }; }
function _Json_unwrap(value) { return value.a; }

function _Json_wrap_UNUSED(value) { return value; }
function _Json_unwrap_UNUSED(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.init,
		impl.update,
		impl.subscriptions,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


/*
function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}

*/

/*
function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}
*/


/*
function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}

*/

/*
function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}
*/




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**_UNUSED/
	var node = args['node'];
	//*/
	/**/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		message: func(record.message),
		stopPropagation: record.stopPropagation,
		preventDefault: record.preventDefault
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.message;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.stopPropagation;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.preventDefault) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.init,
		impl.update,
		impl.subscriptions,
		function(sendToApp, initialModel) {
			var view = impl.view;
			/**_UNUSED/
			var domNode = args['node'];
			//*/
			/**/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.init,
		impl.update,
		impl.subscriptions,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.setup && impl.setup(sendToApp)
			var view = impl.view;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.body);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.title) && (_VirtualDom_doc.title = title = doc.title);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.onUrlChange;
	var onUrlRequest = impl.onUrlRequest;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		setup: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.protocol === next.protocol
							&& curr.host === next.host
							&& curr.port_.a === next.port_.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		init: function(flags)
		{
			return A3(impl.init, flags, _Browser_getUrl(), key);
		},
		view: impl.view,
		update: impl.update,
		subscriptions: impl.subscriptions
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { hidden: 'hidden', change: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { hidden: 'mozHidden', change: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { hidden: 'msHidden', change: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { hidden: 'webkitHidden', change: 'webkitvisibilitychange' }
		: { hidden: 'hidden', change: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		scene: _Browser_getScene(),
		viewport: {
			x: _Browser_window.pageXOffset,
			y: _Browser_window.pageYOffset,
			width: _Browser_doc.documentElement.clientWidth,
			height: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		width: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		height: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			scene: {
				width: node.scrollWidth,
				height: node.scrollHeight
			},
			viewport: {
				x: node.scrollLeft,
				y: node.scrollTop,
				width: node.clientWidth,
				height: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			scene: _Browser_getScene(),
			viewport: {
				x: x,
				y: y,
				width: _Browser_doc.documentElement.clientWidth,
				height: _Browser_doc.documentElement.clientHeight
			},
			element: {
				x: x + rect.left,
				y: y + rect.top,
				width: rect.width,
				height: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.multiline) { flags += 'm'; }
	if (options.caseInsensitive) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.expect.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.expect.b, xhr)); });
		$elm$core$Maybe$isJust(request.tracker) && _Http_track(router, xhr, request.tracker.a);

		try {
			xhr.open(request.method, request.url, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.url));
		}

		_Http_configureRequest(xhr, request);

		request.body.a && xhr.setRequestHeader('Content-Type', request.body.a);
		xhr.send(request.body.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.headers; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.timeout.a || 0;
	xhr.responseType = request.expect.d;
	xhr.withCredentials = request.allowCookiesFromOtherDomains;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		url: xhr.responseURL,
		statusCode: xhr.status,
		statusText: xhr.statusText,
		headers: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			sent: event.loaded,
			size: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			received: event.loaded,
			size: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}

function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}




// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});
var $elm$core$Basics$EQ = {$: 'EQ'};
var $elm$core$Basics$GT = {$: 'GT'};
var $elm$core$Basics$LT = {$: 'LT'};
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === 'RBEmpty_elm_builtin') {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0.a;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (node.$ === 'SubTree') {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 'Err', a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 'Failure', a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 'Field', a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 'Index', a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 'Ok', a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 'OneOf', a: a};
};
var $elm$core$Basics$False = {$: 'False'};
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 'Just', a: a};
};
var $elm$core$Maybe$Nothing = {$: 'Nothing'};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 'Field':
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 'Nothing') {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 'Index':
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 'OneOf':
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 'Array_elm_builtin', a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 'Leaf', a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 'SubTree', a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.nodeListSize) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.tail),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.tail);
		} else {
			var treeLen = builder.nodeListSize * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.nodeList) : builder.nodeList;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.nodeListSize);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.tail) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.tail);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{nodeList: nodeList, nodeListSize: (len / $elm$core$Array$branchFactor) | 0, tail: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = {$: 'True'};
var $elm$core$Result$isOk = function (result) {
	if (result.$ === 'Ok') {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 'Normal':
			return 0;
		case 'MayStopPropagation':
			return 1;
		case 'MayPreventDefault':
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 'External', a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 'Internal', a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = function (a) {
	return {$: 'NotFound', a: a};
};
var $elm$url$Url$Http = {$: 'Http'};
var $elm$url$Url$Https = {$: 'Https'};
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {fragment: fragment, host: host, path: path, port_: port_, protocol: protocol, query: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 'Nothing') {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		$elm$url$Url$Http,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		$elm$url$Url$Https,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0.a;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = function (a) {
	return {$: 'Perform', a: a};
};
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(_Utils_Tuple0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0.a;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return _Utils_Tuple0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(_Utils_Tuple0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0.a;
		return $elm$core$Task$Perform(
			A2($elm$core$Task$map, tagger, task));
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			$elm$core$Task$Perform(
				A2($elm$core$Task$map, toMessage, task)));
	});
var $elm$browser$Browser$element = _Browser_element;
var $author$project$Main$IncorrectParameters = {$: 'IncorrectParameters'};
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $dillonkearns$elm_ts_json$TsJson$Decode$decoder = function (_v0) {
	var decoder_ = _v0.a;
	return decoder_;
};
var $author$project$InteropDefinitions$Flags = F7(
	function (domain, query, name, phone, email, lastMedium, currentTimeMillis) {
		return {currentTimeMillis: currentTimeMillis, domain: domain, email: email, lastMedium: lastMedium, name: name, phone: phone, query: query};
	});
var $dillonkearns$elm_ts_json$TsJson$Internal$Decode$Decoder = F2(
	function (a, b) {
		return {$: 'Decoder', a: a, b: b};
	});
var $dillonkearns$elm_ts_json$Internal$TsJsonType$Required = {$: 'Required'};
var $dillonkearns$elm_ts_json$Internal$TsJsonType$TypeObject = function (a) {
	return {$: 'TypeObject', a: a};
};
var $elm$json$Json$Decode$field = _Json_decodeField;
var $dillonkearns$elm_ts_json$TsJson$Decode$field = F2(
	function (fieldName, _v0) {
		var innerDecoder = _v0.a;
		var innerType = _v0.b;
		return A2(
			$dillonkearns$elm_ts_json$TsJson$Internal$Decode$Decoder,
			A2($elm$json$Json$Decode$field, fieldName, innerDecoder),
			$dillonkearns$elm_ts_json$Internal$TsJsonType$TypeObject(
				_List_fromArray(
					[
						_Utils_Tuple3($dillonkearns$elm_ts_json$Internal$TsJsonType$Required, fieldName, innerType)
					])));
	});
var $dillonkearns$elm_ts_json$Internal$TsJsonType$Integer = {$: 'Integer'};
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $dillonkearns$elm_ts_json$TsJson$Decode$int = A2($dillonkearns$elm_ts_json$TsJson$Internal$Decode$Decoder, $elm$json$Json$Decode$int, $dillonkearns$elm_ts_json$Internal$TsJsonType$Integer);
var $dillonkearns$elm_ts_json$Internal$TsJsonType$Intersection = function (a) {
	return {$: 'Intersection', a: a};
};
var $elm$json$Json$Decode$map7 = _Json_map7;
var $dillonkearns$elm_ts_json$TsJson$Decode$map7 = F8(
	function (mapFn, _v0, _v1, _v2, _v3, _v4, _v5, _v6) {
		var innerDecoder1 = _v0.a;
		var innerType1 = _v0.b;
		var innerDecoder2 = _v1.a;
		var innerType2 = _v1.b;
		var innerDecoder3 = _v2.a;
		var innerType3 = _v2.b;
		var innerDecoder4 = _v3.a;
		var innerType4 = _v3.b;
		var innerDecoder5 = _v4.a;
		var innerType5 = _v4.b;
		var innerDecoder6 = _v5.a;
		var innerType6 = _v5.b;
		var innerDecoder7 = _v6.a;
		var innerType7 = _v6.b;
		return A2(
			$dillonkearns$elm_ts_json$TsJson$Internal$Decode$Decoder,
			A8($elm$json$Json$Decode$map7, mapFn, innerDecoder1, innerDecoder2, innerDecoder3, innerDecoder4, innerDecoder5, innerDecoder6, innerDecoder7),
			$dillonkearns$elm_ts_json$Internal$TsJsonType$Intersection(
				_List_fromArray(
					[innerType1, innerType2, innerType3, innerType4, innerType5, innerType6, innerType7])));
	});
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (maybeValue.$ === 'Just') {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $dillonkearns$elm_ts_json$TsJson$Decode$map = F2(
	function (mapFn, _v0) {
		var innerDecoder = _v0.a;
		var innerType = _v0.b;
		return A2(
			$dillonkearns$elm_ts_json$TsJson$Internal$Decode$Decoder,
			A2($elm$json$Json$Decode$map, mapFn, innerDecoder),
			innerType);
	});
var $dillonkearns$elm_ts_json$Internal$TsJsonType$Literal = function (a) {
	return {$: 'Literal', a: a};
};
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $dillonkearns$elm_ts_json$Internal$TsJsonType$TsNever = {$: 'TsNever'};
var $dillonkearns$elm_ts_json$Internal$TsJsonType$Union = function (a) {
	return {$: 'Union', a: a};
};
var $dillonkearns$elm_ts_json$Internal$TsJsonType$Unknown = {$: 'Unknown'};
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$Basics$neq = _Utils_notEqual;
var $dillonkearns$elm_ts_json$Internal$TypeReducer$union = function (tsTypes) {
	var withoutNevers = A2(
		$elm$core$List$filter,
		$elm$core$Basics$neq($dillonkearns$elm_ts_json$Internal$TsJsonType$TsNever),
		tsTypes);
	var hadNevers = !_Utils_eq(
		$elm$core$List$length(tsTypes),
		$elm$core$List$length(withoutNevers));
	if (!withoutNevers.b) {
		return hadNevers ? $dillonkearns$elm_ts_json$Internal$TsJsonType$TsNever : $dillonkearns$elm_ts_json$Internal$TsJsonType$Unknown;
	} else {
		if (!withoutNevers.b.b) {
			var singleType = withoutNevers.a;
			return singleType;
		} else {
			var first = withoutNevers.a;
			var rest = withoutNevers.b;
			return $dillonkearns$elm_ts_json$Internal$TsJsonType$Union(
				_Utils_Tuple2(first, rest));
		}
	}
};
var $dillonkearns$elm_ts_json$TsJson$Decode$nullable = function (_v0) {
	var innerDecoder = _v0.a;
	var innerType = _v0.b;
	return A2(
		$dillonkearns$elm_ts_json$TsJson$Internal$Decode$Decoder,
		$elm$json$Json$Decode$nullable(innerDecoder),
		$dillonkearns$elm_ts_json$Internal$TypeReducer$union(
			_List_fromArray(
				[
					innerType,
					$dillonkearns$elm_ts_json$Internal$TsJsonType$Literal($elm$json$Json$Encode$null)
				])));
};
var $dillonkearns$elm_ts_json$Internal$TsJsonType$Optional = {$: 'Optional'};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $dillonkearns$elm_ts_json$TsJson$Decode$optionalField = F2(
	function (fieldName, _v0) {
		var innerDecoder = _v0.a;
		var innerType = _v0.b;
		var finishDecoding = function (json) {
			var _v1 = A2(
				$elm$json$Json$Decode$decodeValue,
				A2($elm$json$Json$Decode$field, fieldName, $elm$json$Json$Decode$value),
				json);
			if (_v1.$ === 'Ok') {
				var val = _v1.a;
				return A2(
					$elm$json$Json$Decode$map,
					$elm$core$Maybe$Just,
					A2($elm$json$Json$Decode$field, fieldName, innerDecoder));
			} else {
				return $elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing);
			}
		};
		return A2(
			$dillonkearns$elm_ts_json$TsJson$Internal$Decode$Decoder,
			A2($elm$json$Json$Decode$andThen, finishDecoding, $elm$json$Json$Decode$value),
			$dillonkearns$elm_ts_json$Internal$TsJsonType$TypeObject(
				_List_fromArray(
					[
						_Utils_Tuple3($dillonkearns$elm_ts_json$Internal$TsJsonType$Optional, fieldName, innerType)
					])));
	});
var $dillonkearns$elm_ts_json$TsJson$Decode$optionalNullableField = F2(
	function (fieldName, interopDecoder) {
		return A2(
			$dillonkearns$elm_ts_json$TsJson$Decode$map,
			$elm$core$Maybe$andThen($elm$core$Basics$identity),
			A2(
				$dillonkearns$elm_ts_json$TsJson$Decode$optionalField,
				fieldName,
				$dillonkearns$elm_ts_json$TsJson$Decode$nullable(interopDecoder)));
	});
var $dillonkearns$elm_ts_json$Internal$TsJsonType$String = {$: 'String'};
var $elm$json$Json$Decode$string = _Json_decodeString;
var $dillonkearns$elm_ts_json$TsJson$Decode$string = A2($dillonkearns$elm_ts_json$TsJson$Internal$Decode$Decoder, $elm$json$Json$Decode$string, $dillonkearns$elm_ts_json$Internal$TsJsonType$String);
var $author$project$InteropDefinitions$flags = A8(
	$dillonkearns$elm_ts_json$TsJson$Decode$map7,
	$author$project$InteropDefinitions$Flags,
	A2($dillonkearns$elm_ts_json$TsJson$Decode$field, 'domain', $dillonkearns$elm_ts_json$TsJson$Decode$string),
	A2($dillonkearns$elm_ts_json$TsJson$Decode$field, 'query', $dillonkearns$elm_ts_json$TsJson$Decode$string),
	A2($dillonkearns$elm_ts_json$TsJson$Decode$optionalNullableField, 'name', $dillonkearns$elm_ts_json$TsJson$Decode$string),
	A2($dillonkearns$elm_ts_json$TsJson$Decode$optionalNullableField, 'phone', $dillonkearns$elm_ts_json$TsJson$Decode$string),
	A2($dillonkearns$elm_ts_json$TsJson$Decode$optionalNullableField, 'email', $dillonkearns$elm_ts_json$TsJson$Decode$string),
	A2($dillonkearns$elm_ts_json$TsJson$Decode$optionalNullableField, 'lastMedium', $dillonkearns$elm_ts_json$TsJson$Decode$string),
	A2($dillonkearns$elm_ts_json$TsJson$Decode$field, 'currentTimeMillis', $dillonkearns$elm_ts_json$TsJson$Decode$int));
var $dillonkearns$elm_ts_json$Internal$TsJsonType$Boolean = {$: 'Boolean'};
var $dillonkearns$elm_ts_json$TsJson$Internal$Encode$Encoder = F2(
	function (a, b) {
		return {$: 'Encoder', a: a, b: b};
	});
var $elm$json$Json$Encode$bool = _Json_wrap;
var $dillonkearns$elm_ts_json$TsJson$Encode$bool = A2($dillonkearns$elm_ts_json$TsJson$Internal$Encode$Encoder, $elm$json$Json$Encode$bool, $dillonkearns$elm_ts_json$Internal$TsJsonType$Boolean);
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $dillonkearns$elm_ts_json$TsJson$Encode$unwrapUnion = function (_v0) {
	var rawValue = _v0.a;
	return rawValue;
};
var $dillonkearns$elm_ts_json$TsJson$Encode$buildUnion = function (_v0) {
	var toValue = _v0.a;
	var tsTypes_ = _v0.b;
	return A2(
		$dillonkearns$elm_ts_json$TsJson$Internal$Encode$Encoder,
		A2($elm$core$Basics$composeR, toValue, $dillonkearns$elm_ts_json$TsJson$Encode$unwrapUnion),
		$dillonkearns$elm_ts_json$Internal$TypeReducer$union(tsTypes_));
};
var $dillonkearns$elm_ts_json$Internal$TsJsonType$List = function (a) {
	return {$: 'List', a: a};
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(_Utils_Tuple0),
				entries));
	});
var $dillonkearns$elm_ts_json$TsJson$Encode$list = function (_v0) {
	var encodeFn = _v0.a;
	var tsType_ = _v0.b;
	return A2(
		$dillonkearns$elm_ts_json$TsJson$Internal$Encode$Encoder,
		function (input) {
			return A2($elm$json$Json$Encode$list, encodeFn, input);
		},
		$dillonkearns$elm_ts_json$Internal$TsJsonType$List(tsType_));
};
var $dillonkearns$elm_ts_json$TsJson$Encode$literal = function (literalValue) {
	return A2(
		$dillonkearns$elm_ts_json$TsJson$Internal$Encode$Encoder,
		function (_v0) {
			return literalValue;
		},
		$dillonkearns$elm_ts_json$Internal$TsJsonType$Literal(literalValue));
};
var $dillonkearns$elm_ts_json$TsJson$Encode$null = $dillonkearns$elm_ts_json$TsJson$Encode$literal($elm$json$Json$Encode$null);
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (_v0.$ === 'Just') {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (maybe.$ === 'Just') {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(_Utils_Tuple0),
			pairs));
};
var $dillonkearns$elm_ts_json$TsJson$Encode$object = function (propertyEncoders) {
	var propertyTypes = $dillonkearns$elm_ts_json$Internal$TsJsonType$TypeObject(
		A2(
			$elm$core$List$map,
			function (_v1) {
				var optionality = _v1.a;
				var propertyName = _v1.b;
				var tsType_ = _v1.d;
				return _Utils_Tuple3(optionality, propertyName, tsType_);
			},
			propertyEncoders));
	var encodeObject = function (input) {
		return $elm$json$Json$Encode$object(
			A2(
				$elm$core$List$filterMap,
				function (_v0) {
					var propertyName = _v0.b;
					var encodeFn = _v0.c;
					return A2(
						$elm$core$Maybe$map,
						function (encoded) {
							return _Utils_Tuple2(propertyName, encoded);
						},
						encodeFn(input));
				},
				propertyEncoders));
	};
	return A2($dillonkearns$elm_ts_json$TsJson$Internal$Encode$Encoder, encodeObject, propertyTypes);
};
var $dillonkearns$elm_ts_json$TsJson$Encode$Property = F4(
	function (a, b, c, d) {
		return {$: 'Property', a: a, b: b, c: c, d: d};
	});
var $dillonkearns$elm_ts_json$TsJson$Encode$optional = F3(
	function (name, getter, _v0) {
		var encodeFn = _v0.a;
		var tsType_ = _v0.b;
		return A4(
			$dillonkearns$elm_ts_json$TsJson$Encode$Property,
			$dillonkearns$elm_ts_json$Internal$TsJsonType$Optional,
			name,
			function (input) {
				return A2(
					$elm$core$Maybe$map,
					encodeFn,
					getter(input));
			},
			tsType_);
	});
var $dillonkearns$elm_ts_json$TsJson$Encode$required = F3(
	function (name, getter, _v0) {
		var encodeFn = _v0.a;
		var tsType_ = _v0.b;
		return A4(
			$dillonkearns$elm_ts_json$TsJson$Encode$Property,
			$dillonkearns$elm_ts_json$Internal$TsJsonType$Required,
			name,
			function (input) {
				return $elm$core$Maybe$Just(
					encodeFn(
						getter(input)));
			},
			tsType_);
	});
var $elm$json$Json$Encode$string = _Json_wrap;
var $dillonkearns$elm_ts_json$TsJson$Encode$string = A2($dillonkearns$elm_ts_json$TsJson$Internal$Encode$Encoder, $elm$json$Json$Encode$string, $dillonkearns$elm_ts_json$Internal$TsJsonType$String);
var $dillonkearns$elm_ts_json$TsJson$Internal$Encode$UnionBuilder = F2(
	function (a, b) {
		return {$: 'UnionBuilder', a: a, b: b};
	});
var $dillonkearns$elm_ts_json$TsJson$Encode$union = function (constructor) {
	return A2($dillonkearns$elm_ts_json$TsJson$Internal$Encode$UnionBuilder, constructor, _List_Nil);
};
var $dillonkearns$elm_ts_json$TsJson$Internal$Encode$UnionEncodeValue = function (a) {
	return {$: 'UnionEncodeValue', a: a};
};
var $dillonkearns$elm_ts_json$TsJson$Encode$variant = F2(
	function (_v0, _v1) {
		var encoder_ = _v0.a;
		var tsType_ = _v0.b;
		var builder = _v1.a;
		var tsTypes_ = _v1.b;
		return A2(
			$dillonkearns$elm_ts_json$TsJson$Internal$Encode$UnionBuilder,
			builder(
				A2($elm$core$Basics$composeR, encoder_, $dillonkearns$elm_ts_json$TsJson$Internal$Encode$UnionEncodeValue)),
			A2($elm$core$List$cons, tsType_, tsTypes_));
	});
var $dillonkearns$elm_ts_json$TsJson$Encode$variantObject = F3(
	function (variantName, objectFields, unionBuilder) {
		return A2(
			$dillonkearns$elm_ts_json$TsJson$Encode$variant,
			$dillonkearns$elm_ts_json$TsJson$Encode$object(
				A2(
					$elm$core$List$cons,
					A3(
						$dillonkearns$elm_ts_json$TsJson$Encode$required,
						'tag',
						$elm$core$Basics$identity,
						$dillonkearns$elm_ts_json$TsJson$Encode$literal(
							$elm$json$Json$Encode$string(variantName))),
					objectFields)),
			unionBuilder);
	});
var $dillonkearns$elm_ts_json$TsJson$Encode$variantTagged = F3(
	function (tagName, dataEncoder, builder) {
		return A3(
			$dillonkearns$elm_ts_json$TsJson$Encode$variantObject,
			tagName,
			_List_fromArray(
				[
					A3($dillonkearns$elm_ts_json$TsJson$Encode$required, 'data', $elm$core$Basics$identity, dataEncoder)
				]),
			builder);
	});
var $author$project$InteropDefinitions$fromElm = $dillonkearns$elm_ts_json$TsJson$Encode$buildUnion(
	A3(
		$dillonkearns$elm_ts_json$TsJson$Encode$variantTagged,
		'saveCaller',
		$dillonkearns$elm_ts_json$TsJson$Encode$object(
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_ts_json$TsJson$Encode$optional,
					'phone',
					function ($) {
						return $.phone;
					},
					$dillonkearns$elm_ts_json$TsJson$Encode$string),
					A3(
					$dillonkearns$elm_ts_json$TsJson$Encode$optional,
					'name',
					function ($) {
						return $.name;
					},
					$dillonkearns$elm_ts_json$TsJson$Encode$string),
					A3(
					$dillonkearns$elm_ts_json$TsJson$Encode$optional,
					'email',
					function ($) {
						return $.email;
					},
					$dillonkearns$elm_ts_json$TsJson$Encode$string),
					A3(
					$dillonkearns$elm_ts_json$TsJson$Encode$optional,
					'lastMedium',
					function ($) {
						return $.lastMedium;
					},
					$dillonkearns$elm_ts_json$TsJson$Encode$string)
				])),
		A3(
			$dillonkearns$elm_ts_json$TsJson$Encode$variantTagged,
			'error',
			$dillonkearns$elm_ts_json$TsJson$Encode$object(
				_List_fromArray(
					[
						A3(
						$dillonkearns$elm_ts_json$TsJson$Encode$required,
						'message',
						function ($) {
							return $.message;
						},
						$dillonkearns$elm_ts_json$TsJson$Encode$string)
					])),
			A3(
				$dillonkearns$elm_ts_json$TsJson$Encode$variantTagged,
				'breadcrumb',
				$dillonkearns$elm_ts_json$TsJson$Encode$object(
					_List_fromArray(
						[
							A3(
							$dillonkearns$elm_ts_json$TsJson$Encode$required,
							'receivedMsg',
							function ($) {
								return $.receivedMsg;
							},
							$dillonkearns$elm_ts_json$TsJson$Encode$string),
							A3(
							$dillonkearns$elm_ts_json$TsJson$Encode$required,
							'currentModel',
							function ($) {
								return $.currentModel;
							},
							$dillonkearns$elm_ts_json$TsJson$Encode$string),
							A3(
							$dillonkearns$elm_ts_json$TsJson$Encode$required,
							'newModel',
							function ($) {
								return $.newModel;
							},
							$dillonkearns$elm_ts_json$TsJson$Encode$string)
						])),
				A3(
					$dillonkearns$elm_ts_json$TsJson$Encode$variantTagged,
					'connect',
					$dillonkearns$elm_ts_json$TsJson$Encode$object(
						_List_fromArray(
							[
								A3(
								$dillonkearns$elm_ts_json$TsJson$Encode$required,
								'dial_in',
								function ($) {
									return $.dial_in;
								},
								$dillonkearns$elm_ts_json$TsJson$Encode$bool),
								A3(
								$dillonkearns$elm_ts_json$TsJson$Encode$optional,
								'endpoint_login',
								function ($) {
									return $.endpoint_login;
								},
								$dillonkearns$elm_ts_json$TsJson$Encode$string),
								A3(
								$dillonkearns$elm_ts_json$TsJson$Encode$optional,
								'endpoint_password',
								function ($) {
									return $.endpoint_password;
								},
								$dillonkearns$elm_ts_json$TsJson$Encode$string),
								A3(
								$dillonkearns$elm_ts_json$TsJson$Encode$required,
								'phone',
								function ($) {
									return $.phone;
								},
								$dillonkearns$elm_ts_json$TsJson$Encode$string),
								A3(
								$dillonkearns$elm_ts_json$TsJson$Encode$required,
								'name',
								function ($) {
									return $.name;
								},
								$dillonkearns$elm_ts_json$TsJson$Encode$string),
								A3(
								$dillonkearns$elm_ts_json$TsJson$Encode$required,
								'email',
								function ($) {
									return $.email;
								},
								$dillonkearns$elm_ts_json$TsJson$Encode$string),
								A3(
								$dillonkearns$elm_ts_json$TsJson$Encode$optional,
								'referralCode',
								function ($) {
									return $.referralCode;
								},
								$dillonkearns$elm_ts_json$TsJson$Encode$string),
								A3(
								$dillonkearns$elm_ts_json$TsJson$Encode$required,
								'pusherConfigs',
								function ($) {
									return $.pusherConfigs;
								},
								$dillonkearns$elm_ts_json$TsJson$Encode$list(
									$dillonkearns$elm_ts_json$TsJson$Encode$object(
										_List_fromArray(
											[
												A3(
												$dillonkearns$elm_ts_json$TsJson$Encode$required,
												'appId',
												function ($) {
													return $.appId;
												},
												$dillonkearns$elm_ts_json$TsJson$Encode$string),
												A3(
												$dillonkearns$elm_ts_json$TsJson$Encode$required,
												'cluster',
												function ($) {
													return $.cluster;
												},
												$dillonkearns$elm_ts_json$TsJson$Encode$string),
												A3(
												$dillonkearns$elm_ts_json$TsJson$Encode$required,
												'key',
												function ($) {
													return $.key;
												},
												$dillonkearns$elm_ts_json$TsJson$Encode$string)
											])))),
								A3(
								$dillonkearns$elm_ts_json$TsJson$Encode$required,
								'base_url',
								function ($) {
									return $.base_url;
								},
								$dillonkearns$elm_ts_json$TsJson$Encode$string),
								A3(
								$dillonkearns$elm_ts_json$TsJson$Encode$required,
								'tenant',
								function ($) {
									return $.tenant;
								},
								$dillonkearns$elm_ts_json$TsJson$Encode$string),
								A3(
								$dillonkearns$elm_ts_json$TsJson$Encode$required,
								'token',
								function ($) {
									return $.token;
								},
								$dillonkearns$elm_ts_json$TsJson$Encode$string),
								A3(
								$dillonkearns$elm_ts_json$TsJson$Encode$optional,
								'provider',
								function ($) {
									return $.provider;
								},
								$dillonkearns$elm_ts_json$TsJson$Encode$string),
								A3(
								$dillonkearns$elm_ts_json$TsJson$Encode$optional,
								'twilio_token',
								function ($) {
									return $.twilio_token;
								},
								$dillonkearns$elm_ts_json$TsJson$Encode$string),
								A3(
								$dillonkearns$elm_ts_json$TsJson$Encode$required,
								'caller_channel_id',
								function ($) {
									return $.caller_channel_id;
								},
								$dillonkearns$elm_ts_json$TsJson$Encode$string)
							])),
					A3(
						$dillonkearns$elm_ts_json$TsJson$Encode$variantTagged,
						'reset',
						$dillonkearns$elm_ts_json$TsJson$Encode$null,
						A3(
							$dillonkearns$elm_ts_json$TsJson$Encode$variantTagged,
							'reload',
							$dillonkearns$elm_ts_json$TsJson$Encode$null,
							A3(
								$dillonkearns$elm_ts_json$TsJson$Encode$variantTagged,
								'issue',
								$dillonkearns$elm_ts_json$TsJson$Encode$null,
								$dillonkearns$elm_ts_json$TsJson$Encode$union(
									F8(
										function (vIssue, vReload, vReset, vConnect, vBreadcrumb, vError, vSaveCaller, value) {
											switch (value.$) {
												case 'IssuePortMessage':
													var info = value.a;
													return vIssue(info);
												case 'ReloadPortMessage':
													var info = value.a;
													return vReload(info);
												case 'ResetPortMessage':
													var info = value.a;
													return vReset(info);
												case 'ConnectPortMessage':
													var info = value.a;
													return vConnect(info);
												case 'BreadcrumbPortMessage':
													var info = value.a;
													return vBreadcrumb(info);
												case 'ErrorPortMessage':
													var info = value.a;
													return vError(info);
												default:
													var info = value.a;
													return vSaveCaller(info);
											}
										}))))))))));
var $author$project$InteropDefinitions$CallerCompleteEvent = {$: 'CallerCompleteEvent'};
var $dillonkearns$elm_ts_json$TsJson$Decode$succeed = function (value_) {
	return A2(
		$dillonkearns$elm_ts_json$TsJson$Internal$Decode$Decoder,
		$elm$json$Json$Decode$succeed(value_),
		$dillonkearns$elm_ts_json$Internal$TsJsonType$Unknown);
};
var $author$project$InteropDefinitions$callerCompleteEventDecoder = $dillonkearns$elm_ts_json$TsJson$Decode$succeed($author$project$InteropDefinitions$CallerCompleteEvent);
var $author$project$InteropDefinitions$CampaignAlertsUpdateEvent = {$: 'CampaignAlertsUpdateEvent'};
var $author$project$InteropDefinitions$campaignAlertsUpdatedEventDecoder = $dillonkearns$elm_ts_json$TsJson$Decode$succeed($author$project$InteropDefinitions$CampaignAlertsUpdateEvent);
var $author$project$InteropDefinitions$CampaignUpdateEvent = function (a) {
	return {$: 'CampaignUpdateEvent', a: a};
};
var $author$project$InteropDefinitions$CampaignUpdate = F2(
	function (time, reloadRequired) {
		return {reloadRequired: reloadRequired, time: time};
	});
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $dillonkearns$elm_ts_json$TsJson$Decode$at = F2(
	function (location, _v0) {
		var innerDecoder = _v0.a;
		var innerType = _v0.b;
		return A2(
			$dillonkearns$elm_ts_json$TsJson$Internal$Decode$Decoder,
			A2($elm$json$Json$Decode$at, location, innerDecoder),
			A3(
				$elm$core$List$foldr,
				F2(
					function (fieldName, typeSoFar) {
						return $dillonkearns$elm_ts_json$Internal$TsJsonType$TypeObject(
							_List_fromArray(
								[
									_Utils_Tuple3($dillonkearns$elm_ts_json$Internal$TsJsonType$Required, fieldName, typeSoFar)
								]));
					}),
				innerType,
				location));
	});
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $dillonkearns$elm_ts_json$TsJson$Decode$bool = A2($dillonkearns$elm_ts_json$TsJson$Internal$Decode$Decoder, $elm$json$Json$Decode$bool, $dillonkearns$elm_ts_json$Internal$TsJsonType$Boolean);
var $dillonkearns$elm_ts_json$Internal$TsJsonType$ArrayIndex = F2(
	function (a, b) {
		return {$: 'ArrayIndex', a: a, b: b};
	});
var $elm$core$Dict$RBEmpty_elm_builtin = {$: 'RBEmpty_elm_builtin'};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Dict$Black = {$: 'Black'};
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: 'RBNode_elm_builtin', a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = {$: 'Red'};
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === 'RBNode_elm_builtin') && (right.a.$ === 'Red')) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === 'RBNode_elm_builtin') && (left.a.$ === 'Red')) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Red,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === 'RBNode_elm_builtin') && (left.a.$ === 'Red')) && (left.d.$ === 'RBNode_elm_builtin')) && (left.d.a.$ === 'Red')) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Red,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === 'RBEmpty_elm_builtin') {
			return A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1.$) {
				case 'LT':
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 'EQ':
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === 'RBNode_elm_builtin') && (_v0.a.$ === 'Red')) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $dillonkearns$elm_ts_json$Internal$TypeReducer$deduplicateBy = F2(
	function (toComparable, list) {
		return $elm$core$Dict$values(
			A3(
				$elm$core$List$foldl,
				F2(
					function (value, accum) {
						return A3(
							$elm$core$Dict$insert,
							toComparable(value),
							value,
							accum);
					}),
				$elm$core$Dict$empty,
				list));
	});
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === 'RBEmpty_elm_builtin') {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1.$) {
					case 'LT':
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 'EQ':
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === 'RBNode_elm_builtin') && (dict.d.$ === 'RBNode_elm_builtin')) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === 'RBNode_elm_builtin') && (dict.d.$ === 'RBNode_elm_builtin')) && (dict.e.$ === 'RBNode_elm_builtin')) {
		if ((dict.e.d.$ === 'RBNode_elm_builtin') && (dict.e.d.a.$ === 'Red')) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				$elm$core$Dict$Red,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr.$ === 'Black') {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === 'RBNode_elm_builtin') && (dict.d.$ === 'RBNode_elm_builtin')) && (dict.e.$ === 'RBNode_elm_builtin')) {
		if ((dict.d.d.$ === 'RBNode_elm_builtin') && (dict.d.d.a.$ === 'Red')) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				$elm$core$Dict$Red,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr.$ === 'Black') {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === 'RBNode_elm_builtin') && (left.a.$ === 'Red')) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === 'RBNode_elm_builtin') && (right.a.$ === 'Black')) {
					if (right.d.$ === 'RBNode_elm_builtin') {
						if (right.d.a.$ === 'Black') {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === 'RBNode_elm_builtin') && (dict.d.$ === 'RBNode_elm_builtin')) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor.$ === 'Black') {
			if ((lLeft.$ === 'RBNode_elm_builtin') && (lLeft.a.$ === 'Red')) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === 'RBNode_elm_builtin') {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === 'RBEmpty_elm_builtin') {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === 'RBNode_elm_builtin') && (left.a.$ === 'Black')) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === 'RBNode_elm_builtin') && (lLeft.a.$ === 'Red')) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === 'RBNode_elm_builtin') {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === 'RBNode_elm_builtin') {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === 'RBNode_elm_builtin') {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === 'RBNode_elm_builtin') && (_v0.a.$ === 'Red')) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (_v0.$ === 'Just') {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm_community$dict_extra$Dict$Extra$insertDedupe = F4(
	function (combine, key, value, dict) {
		var _with = function (mbValue) {
			if (mbValue.$ === 'Just') {
				var oldValue = mbValue.a;
				return $elm$core$Maybe$Just(
					A2(combine, oldValue, value));
			} else {
				return $elm$core$Maybe$Just(value);
			}
		};
		return A3($elm$core$Dict$update, key, _with, dict);
	});
var $elm_community$dict_extra$Dict$Extra$fromListDedupeBy = F3(
	function (combine, keyfn, xs) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (x, acc) {
					return A4(
						$elm_community$dict_extra$Dict$Extra$insertDedupe,
						combine,
						keyfn(x),
						x,
						acc);
				}),
			$elm$core$Dict$empty,
			xs);
	});
var $dillonkearns$elm_ts_json$Internal$TypeReducer$either = F2(
	function (predicateFn, _v0) {
		var type1 = _v0.a;
		var type2 = _v0.b;
		return predicateFn(type1) || predicateFn(type2);
	});
var $dillonkearns$elm_ts_json$Internal$TypeReducer$isNonEmptyObject = function (tsType) {
	if ((tsType.$ === 'TypeObject') && tsType.a.b) {
		var _v1 = tsType.a;
		var atLeastOne = _v1.a;
		var possiblyMore = _v1.b;
		return true;
	} else {
		return false;
	}
};
var $dillonkearns$elm_ts_json$Internal$TypeReducer$isPrimitive = function (tsType) {
	switch (tsType.$) {
		case 'Number':
			return true;
		case 'Integer':
			return true;
		case 'String':
			return true;
		case 'Boolean':
			return true;
		default:
			return false;
	}
};
var $dillonkearns$elm_ts_json$Internal$TypeReducer$isContradictory = function (types) {
	return A2($dillonkearns$elm_ts_json$Internal$TypeReducer$either, $dillonkearns$elm_ts_json$Internal$TypeReducer$isNonEmptyObject, types) && A2($dillonkearns$elm_ts_json$Internal$TypeReducer$either, $dillonkearns$elm_ts_json$Internal$TypeReducer$isPrimitive, types);
};
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$core$List$maximum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$max, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $dillonkearns$elm_ts_json$Internal$TypeToString$parenthesize = function (string) {
	return '(' + (string + ')');
};
var $dillonkearns$elm_ts_json$Internal$TypeToString$doubleQuote = function (string) {
	return '\"' + (string + '\"');
};
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {index: index, match: match, number: number, submatches: submatches};
	});
var $elm$regex$Regex$find = _Regex_findAtMost(_Regex_infinity);
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{caseInsensitive: false, multiline: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (maybe.$ === 'Just') {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $dillonkearns$elm_ts_json$Internal$TypeToString$identifierRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^[a-zA-Z_][a-zA-Z0-9_]*$'));
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $dillonkearns$elm_ts_json$Internal$TypeToString$isIdentifier = A2(
	$elm$core$Basics$composeR,
	$elm$regex$Regex$find($dillonkearns$elm_ts_json$Internal$TypeToString$identifierRegex),
	$elm$core$List$isEmpty);
var $dillonkearns$elm_ts_json$Internal$TypeToString$quoteObjectKey = function (key) {
	var needsQuotes = $dillonkearns$elm_ts_json$Internal$TypeToString$isIdentifier(key);
	return needsQuotes ? $dillonkearns$elm_ts_json$Internal$TypeToString$doubleQuote(key) : key;
};
var $elm$core$List$sortBy = _List_sortBy;
var $dillonkearns$elm_ts_json$Internal$TypeToString$parenthesizeToString = function (type_) {
	var needsParens = function () {
		if (type_.$ === 'Union') {
			var types = type_.a;
			return true;
		} else {
			return false;
		}
	}();
	return needsParens ? $dillonkearns$elm_ts_json$Internal$TypeToString$parenthesize(
		$dillonkearns$elm_ts_json$Internal$TypeToString$toString(type_)) : $dillonkearns$elm_ts_json$Internal$TypeToString$toString(type_);
};
var $dillonkearns$elm_ts_json$Internal$TypeToString$toString = function (tsType_) {
	switch (tsType_.$) {
		case 'TsNever':
			return 'never';
		case 'String':
			return 'string';
		case 'Integer':
			return 'number';
		case 'Number':
			return 'number';
		case 'Boolean':
			return 'boolean';
		case 'Unknown':
			return 'JsonValue';
		case 'List':
			var listType = tsType_.a;
			return $dillonkearns$elm_ts_json$Internal$TypeToString$parenthesizeToString(listType) + '[]';
		case 'Literal':
			var literalValue = tsType_.a;
			return A2($elm$json$Json$Encode$encode, 0, literalValue);
		case 'Union':
			var _v1 = tsType_.a;
			var firstType = _v1.a;
			var tsTypes = _v1.b;
			return A2(
				$elm$core$String$join,
				' | ',
				A2(
					$elm$core$List$map,
					$dillonkearns$elm_ts_json$Internal$TypeToString$parenthesizeToString,
					A2($elm$core$List$cons, firstType, tsTypes)));
		case 'TypeObject':
			var keyTypes = tsType_.a;
			return '{ ' + (A2(
				$elm$core$String$join,
				'; ',
				A2(
					$elm$core$List$map,
					function (_v3) {
						var optionality = _v3.a;
						var key = _v3.b;
						var tsType__ = _v3.c;
						var quotedKey = $dillonkearns$elm_ts_json$Internal$TypeToString$quoteObjectKey(key);
						return function () {
							if (optionality.$ === 'Required') {
								return quotedKey;
							} else {
								return quotedKey + '?';
							}
						}() + (' : ' + $dillonkearns$elm_ts_json$Internal$TypeToString$toString(tsType__));
					},
					A2(
						$elm$core$List$sortBy,
						function (_v2) {
							var fieldName = _v2.b;
							return fieldName;
						},
						keyTypes))) + ' }');
		case 'ObjectWithUniformValues':
			var tsType = tsType_.a;
			return '{ [key: string]: ' + ($dillonkearns$elm_ts_json$Internal$TypeToString$toString(tsType) + ' }');
		case 'Tuple':
			var tsTypes = tsType_.a;
			var maybeRestType = tsType_.b;
			var restTypePart = A2(
				$elm$core$Maybe$map,
				function (restType) {
					return '...(' + ($dillonkearns$elm_ts_json$Internal$TypeToString$toString(restType) + ')[]');
				},
				maybeRestType);
			return '[ ' + (A2(
				$elm$core$String$join,
				', ',
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_Utils_ap(
						A2(
							$elm$core$List$map,
							function (type_) {
								return $elm$core$Maybe$Just(
									$dillonkearns$elm_ts_json$Internal$TypeToString$toString(type_));
							},
							tsTypes),
						_List_fromArray(
							[restTypePart])))) + ' ]');
		case 'Intersection':
			var types = tsType_.a;
			return $dillonkearns$elm_ts_json$Internal$TypeToString$parenthesize(
				A2(
					$elm$core$String$join,
					' & ',
					A2($elm$core$List$map, $dillonkearns$elm_ts_json$Internal$TypeToString$parenthesizeToString, types)));
		default:
			var _v5 = tsType_.a;
			var index = _v5.a;
			var tsType = _v5.b;
			var otherIndices = tsType_.b;
			var dict = $elm$core$Dict$fromList(
				A2(
					$elm$core$List$cons,
					_Utils_Tuple2(index, tsType),
					otherIndices));
			var highestIndex = A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$List$maximum(
					$elm$core$Dict$keys(dict)));
			return '[' + (A2(
				$elm$core$String$join,
				',',
				_Utils_ap(
					A2(
						$elm$core$List$map,
						function (cur) {
							return $dillonkearns$elm_ts_json$Internal$TypeToString$toString(
								A2(
									$elm$core$Maybe$withDefault,
									$dillonkearns$elm_ts_json$Internal$TsJsonType$Unknown,
									A2($elm$core$Dict$get, cur, dict)));
						},
						A2($elm$core$List$range, 0, highestIndex)),
					_List_fromArray(
						['...JsonValue[]']))) + ']');
	}
};
var $dillonkearns$elm_ts_json$Internal$TypeReducer$intersect = F2(
	function (type1, type2) {
		if ($dillonkearns$elm_ts_json$Internal$TypeReducer$isContradictory(
			_Utils_Tuple2(type1, type2))) {
			return $dillonkearns$elm_ts_json$Internal$TsJsonType$TsNever;
		} else {
			if (_Utils_eq(type1, type2)) {
				return type1;
			} else {
				var _v8 = _Utils_Tuple2(type1, type2);
				_v8$1:
				while (true) {
					_v8$8:
					while (true) {
						switch (_v8.a.$) {
							case 'Unknown':
								var _v9 = _v8.a;
								var known = _v8.b;
								return known;
							case 'Intersection':
								switch (_v8.b.$) {
									case 'Unknown':
										break _v8$1;
									case 'Intersection':
										var types1 = _v8.a.a;
										var types2 = _v8.b.a;
										return $dillonkearns$elm_ts_json$Internal$TypeReducer$simplifyIntersection(
											_Utils_ap(types1, types2));
									default:
										break _v8$8;
								}
							case 'ArrayIndex':
								switch (_v8.b.$) {
									case 'Unknown':
										break _v8$1;
									case 'ArrayIndex':
										if ((!_v8.a.b.b) && (!_v8.b.b.b)) {
											var _v11 = _v8.a;
											var _v12 = _v11.a;
											var index1 = _v12.a;
											var indexType1 = _v12.b;
											var _v13 = _v8.b;
											var _v14 = _v13.a;
											var index2 = _v14.a;
											var indexType2 = _v14.b;
											return A2(
												$dillonkearns$elm_ts_json$Internal$TsJsonType$ArrayIndex,
												_Utils_Tuple2(index1, indexType1),
												_List_fromArray(
													[
														_Utils_Tuple2(index2, indexType2)
													]));
										} else {
											break _v8$8;
										}
									default:
										break _v8$8;
								}
							case 'TypeObject':
								switch (_v8.b.$) {
									case 'Unknown':
										break _v8$1;
									case 'TypeObject':
										var fields1 = _v8.a.a;
										var fields2 = _v8.b.a;
										return $dillonkearns$elm_ts_json$Internal$TsJsonType$TypeObject(
											A2($dillonkearns$elm_ts_json$Internal$TypeReducer$mergeFields, fields1, fields2));
									case 'Union':
										var fields1 = _v8.a.a;
										var unionedTypes = _v8.b.a;
										return $dillonkearns$elm_ts_json$Internal$TsJsonType$Intersection(
											_List_fromArray(
												[type1, type2]));
									default:
										break _v8$8;
								}
							case 'String':
								switch (_v8.b.$) {
									case 'Unknown':
										break _v8$1;
									case 'Number':
										var _v15 = _v8.a;
										var _v16 = _v8.b;
										return $dillonkearns$elm_ts_json$Internal$TsJsonType$TsNever;
									default:
										break _v8$8;
								}
							case 'Number':
								switch (_v8.b.$) {
									case 'Unknown':
										break _v8$1;
									case 'String':
										var _v17 = _v8.a;
										var _v18 = _v8.b;
										return $dillonkearns$elm_ts_json$Internal$TsJsonType$TsNever;
									default:
										break _v8$8;
								}
							default:
								if (_v8.b.$ === 'Unknown') {
									break _v8$1;
								} else {
									break _v8$8;
								}
						}
					}
					return _Utils_eq(type1, type2) ? type1 : $dillonkearns$elm_ts_json$Internal$TsJsonType$Intersection(
						_List_fromArray(
							[type1, type2]));
				}
				var known = _v8.a;
				var _v10 = _v8.b;
				return known;
			}
		}
	});
var $dillonkearns$elm_ts_json$Internal$TypeReducer$mergeFields = F2(
	function (fields1, fields2) {
		return $elm$core$Dict$values(
			A3(
				$elm_community$dict_extra$Dict$Extra$fromListDedupeBy,
				F2(
					function (_v5, _v6) {
						var optionality1 = _v5.a;
						var fieldName1 = _v5.b;
						var fieldType1 = _v5.c;
						var optionality2 = _v6.a;
						var fieldName2 = _v6.b;
						var fieldType2 = _v6.c;
						return (_Utils_eq(optionality1, $dillonkearns$elm_ts_json$Internal$TsJsonType$Required) || _Utils_eq(optionality2, $dillonkearns$elm_ts_json$Internal$TsJsonType$Required)) ? _Utils_Tuple3(
							$dillonkearns$elm_ts_json$Internal$TsJsonType$Required,
							fieldName1,
							A2($dillonkearns$elm_ts_json$Internal$TypeReducer$intersect, fieldType1, fieldType2)) : _Utils_Tuple3($dillonkearns$elm_ts_json$Internal$TsJsonType$Optional, fieldName1, fieldType1);
					}),
				function (_v7) {
					var fieldName = _v7.b;
					return fieldName;
				},
				_Utils_ap(fields1, fields2)));
	});
var $dillonkearns$elm_ts_json$Internal$TypeReducer$simplifyIntersection = function (types) {
	var thing = function () {
		var _v0 = A2($dillonkearns$elm_ts_json$Internal$TypeReducer$deduplicateBy, $dillonkearns$elm_ts_json$Internal$TypeToString$toString, types);
		if (_v0.b) {
			if (!_v0.b.b) {
				var single = _v0.a;
				return single;
			} else {
				var first = _v0.a;
				var rest = _v0.b;
				if (first.$ === 'TypeObject') {
					var fields = first.a;
					var _v2 = A3(
						$elm$core$List$foldr,
						F2(
							function (thisType, _v3) {
								var objectsSoFar = _v3.a;
								var otherSoFar = _v3.b;
								if (thisType.$ === 'TypeObject') {
									var theseFields = thisType.a;
									return _Utils_Tuple2(
										A2($dillonkearns$elm_ts_json$Internal$TypeReducer$mergeFields, theseFields, objectsSoFar),
										otherSoFar);
								} else {
									return _Utils_Tuple2(
										objectsSoFar,
										A2($elm$core$List$cons, thisType, otherSoFar));
								}
							}),
						_Utils_Tuple2(fields, _List_Nil),
						rest);
					var otherObjects = _v2.a;
					var nonObjectTypes = _v2.b;
					return $dillonkearns$elm_ts_json$Internal$TsJsonType$Intersection(
						A2(
							$elm$core$List$cons,
							$dillonkearns$elm_ts_json$Internal$TsJsonType$TypeObject(otherObjects),
							nonObjectTypes));
				} else {
					return $dillonkearns$elm_ts_json$Internal$TsJsonType$Intersection(types);
				}
			}
		} else {
			return $dillonkearns$elm_ts_json$Internal$TsJsonType$TsNever;
		}
	}();
	return thing;
};
var $dillonkearns$elm_ts_json$TsJson$Decode$map2 = F3(
	function (mapFn, _v0, _v1) {
		var innerDecoder1 = _v0.a;
		var innerType1 = _v0.b;
		var innerDecoder2 = _v1.a;
		var innerType2 = _v1.b;
		return A2(
			$dillonkearns$elm_ts_json$TsJson$Internal$Decode$Decoder,
			A3($elm$json$Json$Decode$map2, mapFn, innerDecoder1, innerDecoder2),
			A2($dillonkearns$elm_ts_json$Internal$TypeReducer$intersect, innerType1, innerType2));
	});
var $author$project$InteropDefinitions$campaignEventDataDecoder = A3(
	$dillonkearns$elm_ts_json$TsJson$Decode$map2,
	$author$project$InteropDefinitions$CampaignUpdate,
	A2(
		$dillonkearns$elm_ts_json$TsJson$Decode$at,
		_List_fromArray(
			['value', 'campaign_updated_at']),
		$dillonkearns$elm_ts_json$TsJson$Decode$string),
	A2(
		$dillonkearns$elm_ts_json$TsJson$Decode$at,
		_List_fromArray(
			['value', 'reloadRequired']),
		$dillonkearns$elm_ts_json$TsJson$Decode$bool));
var $author$project$InteropDefinitions$campaignUpdatedEventDecoder = A2($dillonkearns$elm_ts_json$TsJson$Decode$map, $author$project$InteropDefinitions$CampaignUpdateEvent, $author$project$InteropDefinitions$campaignEventDataDecoder);
var $author$project$InteropDefinitions$ConnectedEvent = function (a) {
	return {$: 'ConnectedEvent', a: a};
};
var $author$project$InteropDefinitions$callUUIDDecoder = A2(
	$dillonkearns$elm_ts_json$TsJson$Decode$at,
	_List_fromArray(
		['value', 'callUUID']),
	$dillonkearns$elm_ts_json$TsJson$Decode$string);
var $author$project$InteropDefinitions$connectedEventDecoder = A2($dillonkearns$elm_ts_json$TsJson$Decode$map, $author$project$InteropDefinitions$ConnectedEvent, $author$project$InteropDefinitions$callUUIDDecoder);
var $author$project$InteropDefinitions$ConnectionLostEvent = function (a) {
	return {$: 'ConnectionLostEvent', a: a};
};
var $author$project$InteropDefinitions$connectionLostEventDecoder = A2(
	$dillonkearns$elm_ts_json$TsJson$Decode$map,
	$author$project$InteropDefinitions$ConnectionLostEvent,
	A2(
		$dillonkearns$elm_ts_json$TsJson$Decode$at,
		_List_fromArray(
			['value', 'message']),
		$dillonkearns$elm_ts_json$TsJson$Decode$string));
var $elm$json$Json$Decode$fail = _Json_fail;
var $dillonkearns$elm_ts_json$TsJson$Decode$tsType = function (_v0) {
	var tsType_ = _v0.b;
	return tsType_;
};
var $dillonkearns$elm_ts_json$TsJson$Decode$discriminatedUnion = F2(
	function (discriminantField, decoders) {
		var table = $elm$core$Dict$fromList(decoders);
		return A2(
			$dillonkearns$elm_ts_json$TsJson$Internal$Decode$Decoder,
			A2(
				$elm$json$Json$Decode$andThen,
				function (discriminantValue) {
					var _v0 = A2($elm$core$Dict$get, discriminantValue, table);
					if (_v0.$ === 'Just') {
						var variantDecoder = _v0.a;
						return $dillonkearns$elm_ts_json$TsJson$Decode$decoder(variantDecoder);
					} else {
						return $elm$json$Json$Decode$fail('Unexpected discriminant value \'' + (discriminantValue + ('\' for field \'' + (discriminantField + '\''))));
					}
				},
				A2($elm$json$Json$Decode$field, discriminantField, $elm$json$Json$Decode$string)),
			$dillonkearns$elm_ts_json$Internal$TypeReducer$union(
				A2(
					$elm$core$List$map,
					function (_v1) {
						var discriminantValue = _v1.a;
						var variantDecoder = _v1.b;
						return A2(
							$dillonkearns$elm_ts_json$Internal$TypeReducer$intersect,
							$dillonkearns$elm_ts_json$Internal$TsJsonType$TypeObject(
								_List_fromArray(
									[
										_Utils_Tuple3(
										$dillonkearns$elm_ts_json$Internal$TsJsonType$Required,
										discriminantField,
										$dillonkearns$elm_ts_json$Internal$TsJsonType$Literal(
											$elm$json$Json$Encode$string(discriminantValue)))
									])),
							$dillonkearns$elm_ts_json$TsJson$Decode$tsType(variantDecoder));
					},
					decoders)));
	});
var $author$project$InteropDefinitions$ErrorEvent = function (a) {
	return {$: 'ErrorEvent', a: a};
};
var $dillonkearns$elm_ts_json$TsJson$Decode$oneOf = function (decoders) {
	return A2(
		$dillonkearns$elm_ts_json$TsJson$Internal$Decode$Decoder,
		$elm$json$Json$Decode$oneOf(
			A2(
				$elm$core$List$map,
				function (_v0) {
					var innerDecoder = _v0.a;
					return innerDecoder;
				},
				decoders)),
		$dillonkearns$elm_ts_json$Internal$TypeReducer$union(
			A2(
				$elm$core$List$map,
				function (_v1) {
					var innerType = _v1.b;
					return innerType;
				},
				decoders)));
};
var $dillonkearns$elm_ts_json$TsJson$Decode$maybe = function (interopDecoder) {
	return $dillonkearns$elm_ts_json$TsJson$Decode$oneOf(
		_List_fromArray(
			[
				A2($dillonkearns$elm_ts_json$TsJson$Decode$map, $elm$core$Maybe$Just, interopDecoder),
				$dillonkearns$elm_ts_json$TsJson$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $author$project$InteropDefinitions$errorEventDecoder = A2(
	$dillonkearns$elm_ts_json$TsJson$Decode$map,
	$author$project$InteropDefinitions$ErrorEvent,
	$dillonkearns$elm_ts_json$TsJson$Decode$maybe(
		A2(
			$dillonkearns$elm_ts_json$TsJson$Decode$at,
			_List_fromArray(
				['value', 'message']),
			$dillonkearns$elm_ts_json$TsJson$Decode$string)));
var $author$project$InteropDefinitions$InCallEvent = function (a) {
	return {$: 'InCallEvent', a: a};
};
var $author$project$InteropDefinitions$InCallEventData = F6(
	function (callUUID, callID, calleeData, ended, calleePhoneNumber, landline) {
		return {callID: callID, callUUID: callUUID, calleeData: calleeData, calleePhoneNumber: calleePhoneNumber, ended: ended, landline: landline};
	});
var $author$project$InteropDefinitions$callIDDecoder = A2($dillonkearns$elm_ts_json$TsJson$Decode$field, 'call_id', $dillonkearns$elm_ts_json$TsJson$Decode$string);
var $dillonkearns$elm_ts_json$Internal$TsJsonType$ObjectWithUniformValues = function (a) {
	return {$: 'ObjectWithUniformValues', a: a};
};
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $dillonkearns$elm_ts_json$TsJson$Decode$dict = function (_v0) {
	var innerDecoder = _v0.a;
	var innerType = _v0.b;
	return A2(
		$dillonkearns$elm_ts_json$TsJson$Internal$Decode$Decoder,
		$elm$json$Json$Decode$dict(innerDecoder),
		$dillonkearns$elm_ts_json$Internal$TsJsonType$ObjectWithUniformValues(innerType));
};
var $dillonkearns$elm_ts_json$Internal$TsJsonType$Number = {$: 'Number'};
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $dillonkearns$elm_ts_json$TsJson$Decode$float = A2($dillonkearns$elm_ts_json$TsJson$Internal$Decode$Decoder, $elm$json$Json$Decode$float, $dillonkearns$elm_ts_json$Internal$TsJsonType$Number);
var $elm$core$String$fromFloat = _String_fromNumber;
var $elm$json$Json$Decode$map6 = _Json_map6;
var $dillonkearns$elm_ts_json$TsJson$Decode$map6 = F7(
	function (mapFn, _v0, _v1, _v2, _v3, _v4, _v5) {
		var innerDecoder1 = _v0.a;
		var innerType1 = _v0.b;
		var innerDecoder2 = _v1.a;
		var innerType2 = _v1.b;
		var innerDecoder3 = _v2.a;
		var innerType3 = _v2.b;
		var innerDecoder4 = _v3.a;
		var innerType4 = _v3.b;
		var innerDecoder5 = _v4.a;
		var innerType5 = _v4.b;
		var innerDecoder6 = _v5.a;
		var innerType6 = _v5.b;
		return A2(
			$dillonkearns$elm_ts_json$TsJson$Internal$Decode$Decoder,
			A7($elm$json$Json$Decode$map6, mapFn, innerDecoder1, innerDecoder2, innerDecoder3, innerDecoder4, innerDecoder5, innerDecoder6),
			$dillonkearns$elm_ts_json$Internal$TsJsonType$Intersection(
				_List_fromArray(
					[innerType1, innerType2, innerType3, innerType4, innerType5, innerType6])));
	});
var $author$project$InteropDefinitions$stringFromBool = function (value) {
	return value ? 'true' : 'false';
};
var $author$project$InteropDefinitions$inCallEventDataDecoder = A7(
	$dillonkearns$elm_ts_json$TsJson$Decode$map6,
	$author$project$InteropDefinitions$InCallEventData,
	$author$project$InteropDefinitions$callUUIDDecoder,
	$author$project$InteropDefinitions$callIDDecoder,
	A2(
		$dillonkearns$elm_ts_json$TsJson$Decode$at,
		_List_fromArray(
			['callee', 'data']),
		$dillonkearns$elm_ts_json$TsJson$Decode$dict(
			$dillonkearns$elm_ts_json$TsJson$Decode$oneOf(
				_List_fromArray(
					[
						$dillonkearns$elm_ts_json$TsJson$Decode$string,
						A2($dillonkearns$elm_ts_json$TsJson$Decode$map, $author$project$InteropDefinitions$stringFromBool, $dillonkearns$elm_ts_json$TsJson$Decode$bool),
						A2($dillonkearns$elm_ts_json$TsJson$Decode$map, $elm$core$String$fromFloat, $dillonkearns$elm_ts_json$TsJson$Decode$float),
						$dillonkearns$elm_ts_json$TsJson$Decode$succeed('')
					])))),
	A2(
		$dillonkearns$elm_ts_json$TsJson$Decode$at,
		_List_fromArray(
			['value', 'ended']),
		$dillonkearns$elm_ts_json$TsJson$Decode$bool),
	A2(
		$dillonkearns$elm_ts_json$TsJson$Decode$at,
		_List_fromArray(
			['callee', 'phone_number']),
		$dillonkearns$elm_ts_json$TsJson$Decode$oneOf(
			_List_fromArray(
				[
					$dillonkearns$elm_ts_json$TsJson$Decode$string,
					$dillonkearns$elm_ts_json$TsJson$Decode$succeed('')
				]))),
	A2(
		$dillonkearns$elm_ts_json$TsJson$Decode$at,
		_List_fromArray(
			['callee', 'landline']),
		$dillonkearns$elm_ts_json$TsJson$Decode$bool));
var $author$project$InteropDefinitions$inCallEventDecoder = A2($dillonkearns$elm_ts_json$TsJson$Decode$map, $author$project$InteropDefinitions$InCallEvent, $author$project$InteropDefinitions$inCallEventDataDecoder);
var $author$project$InteropDefinitions$InQueueEvent = {$: 'InQueueEvent'};
var $author$project$InteropDefinitions$inQueueEventDecoder = $dillonkearns$elm_ts_json$TsJson$Decode$succeed($author$project$InteropDefinitions$InQueueEvent);
var $author$project$InteropDefinitions$NoAssetsEvent = {$: 'NoAssetsEvent'};
var $author$project$InteropDefinitions$noAssestsEventDecoder = $dillonkearns$elm_ts_json$TsJson$Decode$succeed($author$project$InteropDefinitions$NoAssetsEvent);
var $author$project$InteropDefinitions$SubscribedEvent = function (a) {
	return {$: 'SubscribedEvent', a: a};
};
var $author$project$InteropDefinitions$PusherConfig = F3(
	function (appId, cluster, key) {
		return {appId: appId, cluster: cluster, key: key};
	});
var $elm$json$Json$Decode$map3 = _Json_map3;
var $dillonkearns$elm_ts_json$TsJson$Decode$map3 = F4(
	function (mapFn, _v0, _v1, _v2) {
		var innerDecoder1 = _v0.a;
		var innerType1 = _v0.b;
		var innerDecoder2 = _v1.a;
		var innerType2 = _v1.b;
		var innerDecoder3 = _v2.a;
		var innerType3 = _v2.b;
		return A2(
			$dillonkearns$elm_ts_json$TsJson$Internal$Decode$Decoder,
			A4($elm$json$Json$Decode$map3, mapFn, innerDecoder1, innerDecoder2, innerDecoder3),
			A2(
				$dillonkearns$elm_ts_json$Internal$TypeReducer$intersect,
				innerType3,
				A2($dillonkearns$elm_ts_json$Internal$TypeReducer$intersect, innerType1, innerType2)));
	});
var $author$project$InteropDefinitions$subscribedEventDataDecoder = A4(
	$dillonkearns$elm_ts_json$TsJson$Decode$map3,
	$author$project$InteropDefinitions$PusherConfig,
	A2(
		$dillonkearns$elm_ts_json$TsJson$Decode$at,
		_List_fromArray(
			['value', 'appId']),
		$dillonkearns$elm_ts_json$TsJson$Decode$string),
	A2(
		$dillonkearns$elm_ts_json$TsJson$Decode$at,
		_List_fromArray(
			['value', 'cluster']),
		$dillonkearns$elm_ts_json$TsJson$Decode$string),
	A2(
		$dillonkearns$elm_ts_json$TsJson$Decode$at,
		_List_fromArray(
			['value', 'key']),
		$dillonkearns$elm_ts_json$TsJson$Decode$string));
var $author$project$InteropDefinitions$subscribedEventDecoder = A2($dillonkearns$elm_ts_json$TsJson$Decode$map, $author$project$InteropDefinitions$SubscribedEvent, $author$project$InteropDefinitions$subscribedEventDataDecoder);
var $author$project$InteropDefinitions$toElm = $dillonkearns$elm_ts_json$TsJson$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$dillonkearns$elm_ts_json$TsJson$Decode$discriminatedUnion,
			'name',
			_List_fromArray(
				[
					_Utils_Tuple2('subscribed', $author$project$InteropDefinitions$subscribedEventDecoder),
					_Utils_Tuple2('connected', $author$project$InteropDefinitions$connectedEventDecoder),
					_Utils_Tuple2('join', $author$project$InteropDefinitions$inQueueEventDecoder),
					_Utils_Tuple2('available', $author$project$InteropDefinitions$inQueueEventDecoder),
					_Utils_Tuple2('answered', $author$project$InteropDefinitions$inCallEventDecoder),
					_Utils_Tuple2('call_ended', $author$project$InteropDefinitions$inCallEventDecoder),
					_Utils_Tuple2('survey_submitted', $author$project$InteropDefinitions$inCallEventDecoder),
					_Utils_Tuple2('caller_complete', $author$project$InteropDefinitions$callerCompleteEventDecoder),
					_Utils_Tuple2('campaign_updated', $author$project$InteropDefinitions$campaignUpdatedEventDecoder),
					_Utils_Tuple2('campaign_alerts_updated', $author$project$InteropDefinitions$campaignAlertsUpdatedEventDecoder),
					_Utils_Tuple2('no_assets', $author$project$InteropDefinitions$noAssestsEventDecoder),
					_Utils_Tuple2('connection_lost', $author$project$InteropDefinitions$connectionLostEventDecoder),
					_Utils_Tuple2('error', $author$project$InteropDefinitions$errorEventDecoder)
				])),
			$author$project$InteropDefinitions$errorEventDecoder
		]));
var $author$project$InteropDefinitions$interop = {flags: $author$project$InteropDefinitions$flags, fromElm: $author$project$InteropDefinitions$fromElm, toElm: $author$project$InteropDefinitions$toElm};
var $author$project$InteropPorts$decodeFlags = function (flags) {
	return A2(
		$elm$json$Json$Decode$decodeValue,
		$dillonkearns$elm_ts_json$TsJson$Decode$decoder($author$project$InteropDefinitions$interop.flags),
		flags);
};
var $author$project$Main$ClickToCall = function (a) {
	return {$: 'ClickToCall', a: a};
};
var $author$project$Utils$Dev = {$: 'Dev'};
var $author$project$Utils$DevV2 = {$: 'DevV2'};
var $author$project$Main$Dialer = function (a) {
	return {$: 'Dialer', a: a};
};
var $author$project$Main$GotClickToCallMsg = function (a) {
	return {$: 'GotClickToCallMsg', a: a};
};
var $author$project$Main$GotDialerMsg = function (a) {
	return {$: 'GotDialerMsg', a: a};
};
var $author$project$Utils$Local = {$: 'Local'};
var $author$project$Utils$Production = {$: 'Production'};
var $author$project$Utils$ProductionV2 = {$: 'ProductionV2'};
var $author$project$Utils$Staging = {$: 'Staging'};
var $author$project$Utils$StagingV2 = {$: 'StagingV2'};
var $sporto$qs$QS$Config = function (a) {
	return {$: 'Config', a: a};
};
var $sporto$qs$QS$config = $sporto$qs$QS$Config(
	{addQuestionMark: true, encodeBrackets: true, parseBooleans: true, parseNumbers: true});
var $author$project$ClickToCall$Types$Model = F8(
	function (client, token, env, state, assigned, approved, batchId, campaignName) {
		return {approved: approved, assigned: assigned, batchId: batchId, campaignName: campaignName, client: client, env: env, state: state, token: token};
	});
var $author$project$ClickToCall$Types$ReadyToGetStarted = {$: 'ReadyToGetStarted'};
var $author$project$ClickToCall$Types$CampaignLoaded = function (a) {
	return {$: 'CampaignLoaded', a: a};
};
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument = F2(
	function (a, b) {
		return {$: 'Argument', a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required = F3(
	function (fieldName, raw, encode) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument,
			fieldName,
			encode(raw));
	});
var $dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet = F2(
	function (a, b) {
		return {$: 'SelectionSet', a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$RawField$Composite = F3(
	function (a, b, c) {
		return {$: 'Composite', a: a, b: b, c: c};
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite = F3(
	function (fieldName, args, fields) {
		return A3($dillonkearns$elm_graphql$Graphql$RawField$Composite, fieldName, args, fields);
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $dillonkearns$elm_graphql$Graphql$Document$Hash$HashData = F4(
	function (shift, seed, hash, charsProcessed) {
		return {charsProcessed: charsProcessed, hash: hash, seed: seed, shift: shift};
	});
var $dillonkearns$elm_graphql$Graphql$Document$Hash$c1 = 3432918353;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$c2 = 461845907;
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy = F2(
	function (b, a) {
		return ((a & 65535) * b) + ((((a >>> 16) * b) & 65535) << 16);
	});
var $elm$core$Bitwise$or = _Bitwise_or;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy = F2(
	function (b, a) {
		return (a << b) | (a >>> (32 - b));
	});
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$finalize = function (data) {
	var acc = (!(!data.hash)) ? (data.seed ^ A2(
		$dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy,
		$dillonkearns$elm_graphql$Graphql$Document$Hash$c2,
		A2(
			$dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy,
			15,
			A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, $dillonkearns$elm_graphql$Graphql$Document$Hash$c1, data.hash)))) : data.seed;
	var h0 = acc ^ data.charsProcessed;
	var h1 = A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, 2246822507, h0 ^ (h0 >>> 16));
	var h2 = A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, 3266489909, h1 ^ (h1 >>> 13));
	return (h2 ^ (h2 >>> 16)) >>> 0;
};
var $elm$core$String$foldl = _String_foldl;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$mix = F2(
	function (h1, k1) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy,
			5,
			A2(
				$dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy,
				13,
				h1 ^ A2(
					$dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy,
					$dillonkearns$elm_graphql$Graphql$Document$Hash$c2,
					A2(
						$dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy,
						15,
						A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, $dillonkearns$elm_graphql$Graphql$Document$Hash$c1, k1))))) + 3864292196;
	});
var $dillonkearns$elm_graphql$Graphql$Document$Hash$hashFold = F2(
	function (c, data) {
		var res = data.hash | ((255 & $elm$core$Char$toCode(c)) << data.shift);
		var _v0 = data.shift;
		if (_v0 === 24) {
			return {
				charsProcessed: data.charsProcessed + 1,
				hash: 0,
				seed: A2($dillonkearns$elm_graphql$Graphql$Document$Hash$mix, data.seed, res),
				shift: 0
			};
		} else {
			return {charsProcessed: data.charsProcessed + 1, hash: res, seed: data.seed, shift: data.shift + 8};
		}
	});
var $dillonkearns$elm_graphql$Graphql$Document$Hash$hashString = F2(
	function (seed, str) {
		return $dillonkearns$elm_graphql$Graphql$Document$Hash$finalize(
			A3(
				$elm$core$String$foldl,
				$dillonkearns$elm_graphql$Graphql$Document$Hash$hashFold,
				A4($dillonkearns$elm_graphql$Graphql$Document$Hash$HashData, 0, seed, 0, 0),
				str));
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize = function (value) {
	switch (value.$) {
		case 'EnumValue':
			var enumValue = value.a;
			return enumValue;
		case 'Json':
			var json = value.a;
			return A2($elm$json$Json$Encode$encode, 0, json);
		case 'List':
			var values = value.a;
			return '[' + (A2(
				$elm$core$String$join,
				', ',
				A2($elm$core$List$map, $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize, values)) + ']');
		default:
			var keyValuePairs = value.a;
			return '{' + (A2(
				$elm$core$String$join,
				', ',
				A2(
					$elm$core$List$map,
					function (_v1) {
						var key = _v1.a;
						var objectValue = _v1.b;
						return key + (': ' + $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize(objectValue));
					},
					keyValuePairs)) + '}');
	}
};
var $dillonkearns$elm_graphql$Graphql$Document$Argument$argToString = function (_v0) {
	var name = _v0.a;
	var value = _v0.b;
	return name + (': ' + $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize(value));
};
var $dillonkearns$elm_graphql$Graphql$Document$Argument$serialize = function (args) {
	if (!args.b) {
		return '';
	} else {
		var nonemptyArgs = args;
		return '(' + (A2(
			$elm$core$String$join,
			', ',
			A2($elm$core$List$map, $dillonkearns$elm_graphql$Graphql$Document$Argument$argToString, nonemptyArgs)) + ')');
	}
};
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$maybeAliasHash = function (field) {
	return A2(
		$elm$core$Maybe$map,
		$dillonkearns$elm_graphql$Graphql$Document$Hash$hashString(0),
		function () {
			if (field.$ === 'Composite') {
				var name = field.a;
				var _arguments = field.b;
				var children = field.c;
				return $elm$core$List$isEmpty(_arguments) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(_arguments));
			} else {
				var typeString = field.a.typeString;
				var fieldName = field.a.fieldName;
				var _arguments = field.b;
				return (fieldName === '__typename') ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
					$elm$core$String$concat(
						A2(
							$elm$core$List$append,
							_List_fromArray(
								[typeString]),
							$elm$core$List$singleton(
								$dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(_arguments)))));
			}
		}());
};
var $dillonkearns$elm_graphql$Graphql$RawField$name = function (field) {
	if (field.$ === 'Composite') {
		var fieldName = field.a;
		var argumentList = field.b;
		var fieldList = field.c;
		return fieldName;
	} else {
		var typeString = field.a.typeString;
		var fieldName = field.a.fieldName;
		var argumentList = field.b;
		return fieldName;
	}
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$alias = function (field) {
	return A2(
		$elm$core$Maybe$map,
		function (aliasHash) {
			return _Utils_ap(
				$dillonkearns$elm_graphql$Graphql$RawField$name(field),
				$elm$core$String$fromInt(aliasHash));
		},
		$dillonkearns$elm_graphql$Graphql$Document$Field$maybeAliasHash(field));
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName = function (field) {
	return A2(
		$elm$core$Maybe$withDefault,
		$dillonkearns$elm_graphql$Graphql$RawField$name(field),
		$dillonkearns$elm_graphql$Graphql$Document$Field$alias(field));
};
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField = F4(
	function (fieldName, args, _v0, decoderTransform) {
		var fields = _v0.a;
		var decoder = _v0.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite, fieldName, args, fields)
				]),
			A2(
				$elm$json$Json$Decode$field,
				$dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName(
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite, fieldName, args, fields)),
				decoderTransform(decoder)));
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json = function (a) {
	return {$: 'Json', a: a};
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$string = function (value) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json(
		$elm$json$Json$Encode$string(value));
};
var $author$project$Api$Query$campaign = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'campaign',
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'token', requiredArgs____.token, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)
				]),
			object____,
			$elm$core$Basics$identity);
	});
var $author$project$Utils$baseUrl = F2(
	function (env, client) {
		switch (env.$) {
			case 'Local':
				return 'http://localhost:8080';
			case 'Dev':
				if (client === 'dedicatedtest') {
					return 'https://dialer-api-dedicatedtest.dialer.scaletowin.io';
				} else {
					return 'https://dialer-api-dev.dialer.scaletowin.io';
				}
			case 'DevV2':
				return 'https://shared.dialer-api.dev.scaletowin.io';
			case 'Staging':
				if (client === 'qa') {
					return 'https://dialer-api-qa.dialer.scaletowin.io';
				} else {
					return 'https://dialer-api-staging.dialer.scaletowin.io';
				}
			case 'StagingV2':
				return 'https://shared.dialer-api.staging.scaletowin.io';
			case 'Production':
				switch (client) {
					case 'democrats':
						return 'https://dialer-api-democrats.dialer.scaletowin.io';
					case 'betofortexas':
						return 'https://dialer-api-betofortexas.dialer.scaletowin.io';
					case 'au':
						return 'https://dialer-api-au.dialer.scaletowin.io';
					case 'yes23':
						return 'https://dialer-api-au.dialer.scaletowin.io';
					default:
						return 'https://dialer-api-prod.dialer.scaletowin.io';
				}
			default:
				return 'https://shared.dialer-api.prod.scaletowin.io';
		}
	});
var $author$project$ClickToCall$Api$gqlBaseUrl = F2(
	function (env, client) {
		return A2($author$project$Utils$baseUrl, env, client) + '/caller/graphql';
	});
var $dillonkearns$elm_graphql$Graphql$Http$Query = F2(
	function (a, b) {
		return {$: 'Query', a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Http$Request = function (a) {
	return {$: 'Request', a: a};
};
var $dillonkearns$elm_graphql$Graphql$Document$decoder = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return A2($elm$json$Json$Decode$field, 'data', decoder_);
};
var $dillonkearns$elm_graphql$Graphql$Http$queryRequest = F2(
	function (baseUrl, query) {
		return $dillonkearns$elm_graphql$Graphql$Http$Request(
			{
				baseUrl: baseUrl,
				details: A2($dillonkearns$elm_graphql$Graphql$Http$Query, $elm$core$Maybe$Nothing, query),
				expect: $dillonkearns$elm_graphql$Graphql$Document$decoder(query),
				headers: _List_Nil,
				operationName: $elm$core$Maybe$Nothing,
				queryParams: _List_Nil,
				timeout: $elm$core$Maybe$Nothing,
				withCredentials: false
			});
	});
var $author$project$ClickToCall$Types$Campaign = F2(
	function (id, name) {
		return {id: id, name: name};
	});
var $author$project$Api$Scalar$Id = function (a) {
	return {$: 'Id', a: a};
};
var $author$project$Api$Scalar$Upload = function (a) {
	return {$: 'Upload', a: a};
};
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			$elm$json$Json$Decode$string,
			A2($elm$json$Json$Decode$map, $elm$core$String$fromFloat, $elm$json$Json$Decode$float),
			A2($elm$json$Json$Decode$map, $elm$core$String$fromInt, $elm$json$Json$Decode$int),
			A2(
			$elm$json$Json$Decode$map,
			function (bool) {
				if (bool) {
					return 'true';
				} else {
					return 'false';
				}
			},
			$elm$json$Json$Decode$bool)
		]));
var $author$project$Api$Scalar$defaultCodecs = {
	codecId: {
		decoder: A2($elm$json$Json$Decode$map, $author$project$Api$Scalar$Id, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		encoder: function (_v0) {
			var raw = _v0.a;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	codecUpload: {
		decoder: A2($elm$json$Json$Decode$map, $author$project$Api$Scalar$Upload, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		encoder: function (_v1) {
			var raw = _v1.a;
			return $elm$json$Json$Encode$string(raw);
		}
	}
};
var $author$project$Api$Scalar$Codecs = function (a) {
	return {$: 'Codecs', a: a};
};
var $author$project$Api$Scalar$defineCodecs = function (definitions) {
	return $author$project$Api$Scalar$Codecs(definitions);
};
var $author$project$Api$ScalarCodecs$codecs = $author$project$Api$Scalar$defineCodecs(
	{codecId: $author$project$Api$Scalar$defaultCodecs.codecId, codecUpload: $author$project$Api$Scalar$defaultCodecs.codecUpload});
var $dillonkearns$elm_graphql$Graphql$RawField$Leaf = F2(
	function (a, b) {
		return {$: 'Leaf', a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$leaf = F2(
	function (details, args) {
		return A2($dillonkearns$elm_graphql$Graphql$RawField$Leaf, details, args);
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField = F4(
	function (typeString, fieldName, args, decoder) {
		var newLeaf = A2(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$leaf,
			{fieldName: fieldName, typeString: typeString},
			args);
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			_List_fromArray(
				[newLeaf]),
			A2(
				$elm$json$Json$Decode$field,
				$dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName(newLeaf),
				decoder));
	});
var $author$project$Api$Scalar$unwrapCodecs = function (_v0) {
	var unwrappedCodecs = _v0.a;
	return unwrappedCodecs;
};
var $author$project$Api$Object$Campaign$id = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.Id',
	'id',
	_List_Nil,
	$author$project$Api$Scalar$unwrapCodecs($author$project$Api$ScalarCodecs$codecs).codecId.decoder);
var $author$project$Api$Object$Campaign$name = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'name', _List_Nil, $elm$json$Json$Decode$string);
var $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed = function (constructor) {
	return A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
		_List_Nil,
		$elm$json$Json$Decode$succeed(constructor));
};
var $dillonkearns$elm_graphql$Graphql$SelectionSet$with = F2(
	function (_v0, _v1) {
		var selectionFields1 = _v0.a;
		var selectionDecoder1 = _v0.b;
		var selectionFields2 = _v1.a;
		var selectionDecoder2 = _v1.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			_Utils_ap(selectionFields1, selectionFields2),
			A3($elm$json$Json$Decode$map2, $elm$core$Basics$apR, selectionDecoder1, selectionDecoder2));
	});
var $author$project$ClickToCall$Api$selectCampaignFields = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Api$Object$Campaign$name,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Api$Object$Campaign$id,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$ClickToCall$Types$Campaign)));
var $elm$http$Http$Request = function (a) {
	return {$: 'Request', a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {reqs: reqs, subs: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 'BadStatus_', a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 'BadUrl_', a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 'GoodStatus_', a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 'NetworkError_'};
var $elm$http$Http$Receiving = function (a) {
	return {$: 'Receiving', a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 'Sending', a: a};
};
var $elm$http$Http$Timeout_ = {$: 'Timeout_'};
var $elm$core$Maybe$isJust = function (maybe) {
	if (maybe.$ === 'Just') {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (cmd.$ === 'Cancel') {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 'Nothing') {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.tracker;
							if (_v4.$ === 'Nothing') {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.reqs));
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.subs)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 'Cancel', a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (cmd.$ === 'Cancel') {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					allowCookiesFromOtherDomains: r.allowCookiesFromOtherDomains,
					body: r.body,
					expect: A2(_Http_mapExpect, func, r.expect),
					headers: r.headers,
					method: r.method,
					timeout: r.timeout,
					tracker: r.tracker,
					url: r.url
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 'MySub', a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{allowCookiesFromOtherDomains: false, body: r.body, expect: r.expect, headers: r.headers, method: r.method, timeout: r.timeout, tracker: r.tracker, url: r.url}));
};
var $elm$http$Http$riskyRequest = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{allowCookiesFromOtherDomains: true, body: r.body, expect: r.expect, headers: r.headers, method: r.method, timeout: r.timeout, tracker: r.tracker, url: r.url}));
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError = F2(
	function (a, b) {
		return {$: 'GraphqlError', a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Http$HttpError = function (a) {
	return {$: 'HttpError', a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$convertResult = function (httpResult) {
	if (httpResult.$ === 'Ok') {
		var successOrError = httpResult.a;
		if (successOrError.$ === 'Ok') {
			var value = successOrError.a;
			return $elm$core$Result$Ok(value);
		} else {
			var _v2 = successOrError.a;
			var possiblyParsedData = _v2.a;
			var error = _v2.b;
			return $elm$core$Result$Err(
				A2($dillonkearns$elm_graphql$Graphql$Http$GraphqlError, possiblyParsedData, error));
		}
	} else {
		var httpError = httpResult.a;
		return $elm$core$Result$Err(
			$dillonkearns$elm_graphql$Graphql$Http$HttpError(httpError));
	}
};
var $dillonkearns$elm_graphql$Graphql$Http$BadPayload = function (a) {
	return {$: 'BadPayload', a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$BadStatus = F2(
	function (a, b) {
		return {$: 'BadStatus', a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Http$BadUrl = function (a) {
	return {$: 'BadUrl', a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$NetworkError = {$: 'NetworkError'};
var $dillonkearns$elm_graphql$Graphql$Http$Timeout = {$: 'Timeout'};
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $dillonkearns$elm_graphql$Graphql$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			function (response) {
				switch (response.$) {
					case 'BadUrl_':
						var url = response.a;
						return $elm$core$Result$Err(
							$dillonkearns$elm_graphql$Graphql$Http$BadUrl(url));
					case 'Timeout_':
						return $elm$core$Result$Err($dillonkearns$elm_graphql$Graphql$Http$Timeout);
					case 'NetworkError_':
						return $elm$core$Result$Err($dillonkearns$elm_graphql$Graphql$Http$NetworkError);
					case 'BadStatus_':
						var metadata = response.a;
						var body = response.b;
						return $elm$core$Result$Err(
							A2($dillonkearns$elm_graphql$Graphql$Http$BadStatus, metadata, body));
					default:
						var metadata = response.a;
						var body = response.b;
						var _v1 = A2($elm$json$Json$Decode$decodeString, decoder, body);
						if (_v1.$ === 'Ok') {
							var value = _v1.a;
							return $elm$core$Result$Ok(value);
						} else {
							var err = _v1.a;
							return $elm$core$Result$Err(
								$dillonkearns$elm_graphql$Graphql$Http$BadPayload(err));
						}
				}
			});
	});
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Get = {$: 'Get'};
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Post = {$: 'Post'};
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$core$Basics$ge = _Utils_ge;
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$maxLength = 2000;
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (_v0.$ === 'SubTree') {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $lukewestby$elm_string_interpolate$String$Interpolate$applyInterpolation = F2(
	function (replacements, _v0) {
		var match = _v0.match;
		var ordinalString = A2(
			$elm$core$Basics$composeL,
			$elm$core$String$dropLeft(1),
			$elm$core$String$dropRight(1))(match);
		return A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$andThen,
				function (value) {
					return A2($elm$core$Array$get, value, replacements);
				},
				$elm$core$String$toInt(ordinalString)));
	});
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{nodeList: nodeList, nodeListSize: nodeListSize, tail: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $lukewestby$elm_string_interpolate$String$Interpolate$interpolationRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('\\{\\d+\\}'));
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $lukewestby$elm_string_interpolate$String$Interpolate$interpolate = F2(
	function (string, args) {
		var asArray = $elm$core$Array$fromList(args);
		return A3(
			$elm$regex$Regex$replace,
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolationRegex,
			$lukewestby$elm_string_interpolate$String$Interpolate$applyInterpolation(asArray),
			string);
	});
var $dillonkearns$elm_graphql$Graphql$Document$Indent$spaces = function (n) {
	return (n > 0) ? (' ' + $dillonkearns$elm_graphql$Graphql$Document$Indent$spaces(n - 1)) : '';
};
var $dillonkearns$elm_graphql$Graphql$Document$Indent$generate = function (indentationLevel) {
	return $dillonkearns$elm_graphql$Graphql$Document$Indent$spaces(indentationLevel * 2);
};
var $dillonkearns$elm_graphql$Graphql$RawField$typename = A2(
	$dillonkearns$elm_graphql$Graphql$RawField$Leaf,
	{fieldName: '__typename', typeString: ''},
	_List_Nil);
var $dillonkearns$elm_graphql$Graphql$Document$Field$nonemptyChildren = function (children) {
	return $elm$core$List$isEmpty(children) ? A2($elm$core$List$cons, $dillonkearns$elm_graphql$Graphql$RawField$typename, children) : children;
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$serialize = F3(
	function (aliasName, mIndentationLevel, field) {
		var prefix = function () {
			if (aliasName.$ === 'Just') {
				var aliasName_ = aliasName.a;
				return _Utils_ap(
					aliasName_,
					function () {
						if (mIndentationLevel.$ === 'Just') {
							return ': ';
						} else {
							return ':';
						}
					}());
			} else {
				return '';
			}
		}();
		return A2(
			$elm$core$Maybe$map,
			function (string) {
				return _Utils_ap(
					$dillonkearns$elm_graphql$Graphql$Document$Indent$generate(
						A2($elm$core$Maybe$withDefault, 0, mIndentationLevel)),
					_Utils_ap(prefix, string));
			},
			function () {
				if (field.$ === 'Composite') {
					var fieldName = field.a;
					var args = field.b;
					var children = field.c;
					if (mIndentationLevel.$ === 'Nothing') {
						return $elm$core$Maybe$Just(
							(fieldName + ($dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(args) + ('{' + A2($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren, $elm$core$Maybe$Nothing, children)))) + '}');
					} else {
						var indentationLevel = mIndentationLevel.a;
						return $elm$core$Maybe$Just(
							(fieldName + ($dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(args) + (' {\n' + A2(
								$dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren,
								$elm$core$Maybe$Just(indentationLevel),
								children)))) + ('\n' + ($dillonkearns$elm_graphql$Graphql$Document$Indent$generate(indentationLevel) + '}')));
					}
				} else {
					var fieldName = field.a.fieldName;
					var args = field.b;
					return $elm$core$Maybe$Just(
						_Utils_ap(
							fieldName,
							$dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(args)));
				}
			}());
	});
var $dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren = F2(
	function (indentationLevel, children) {
		return A2(
			$elm$core$String$join,
			function () {
				if (indentationLevel.$ === 'Just') {
					return '\n';
				} else {
					return ' ';
				}
			}(),
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				A2(
					$elm$core$List$map,
					function (field) {
						return A3(
							$dillonkearns$elm_graphql$Graphql$Document$Field$serialize,
							$dillonkearns$elm_graphql$Graphql$Document$Field$alias(field),
							A2(
								$elm$core$Maybe$map,
								$elm$core$Basics$add(1),
								indentationLevel),
							field);
					},
					$dillonkearns$elm_graphql$Graphql$Document$Field$nonemptyChildren(children))));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serialize = F2(
	function (operationType, queries) {
		return A2(
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
			'{0} {\n{1}\n}',
			_List_fromArray(
				[
					operationType,
					A2(
					$dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren,
					$elm$core$Maybe$Just(0),
					queries)
				]));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serializeQuery = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return A2($dillonkearns$elm_graphql$Graphql$Document$serialize, 'query', fields);
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrl = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return '{' + (A2($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren, $elm$core$Maybe$Nothing, fields) + '}');
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrlWithOperationName = F2(
	function (operationName, _v0) {
		var fields = _v0.a;
		var decoder_ = _v0.b;
		return 'query ' + (operationName + (' {' + (A2($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren, $elm$core$Maybe$Nothing, fields) + '}')));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serializeWithOperationName = F3(
	function (operationType, operationName, queries) {
		return A2(
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
			'{0} {1} {\n{2}\n}',
			_List_fromArray(
				[
					operationType,
					operationName,
					A2(
					$dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren,
					$elm$core$Maybe$Just(0),
					queries)
				]));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serializeQueryWithOperationName = F2(
	function (operationName, _v0) {
		var fields = _v0.a;
		var decoder_ = _v0.b;
		return A3($dillonkearns$elm_graphql$Graphql$Document$serializeWithOperationName, 'query', operationName, fields);
	});
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$replace = F2(
	function (old, _new) {
		return A2(
			$elm$core$Basics$composeR,
			$elm$core$String$split(old),
			$elm$core$String$join(_new));
	});
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape = A2(
	$elm$core$Basics$composeR,
	$elm$url$Url$percentEncode,
	A2($dillonkearns$elm_graphql$Graphql$Http$QueryParams$replace, '%20', '+'));
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape(key) + ('=' + $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape(value));
};
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$joinUrlEncoded = function (args) {
	return A2(
		$elm$core$String$join,
		'&',
		A2($elm$core$List$map, $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryPair, args));
};
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams = F2(
	function (queryParams, url) {
		return $elm$core$List$isEmpty(queryParams) ? url : (url + ('?' + $dillonkearns$elm_graphql$Graphql$Http$QueryParams$joinUrlEncoded(queryParams)));
	});
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$build = F5(
	function (forceMethod, url, queryParams, maybeOperationName, queryDocument) {
		var _v0 = function () {
			if (maybeOperationName.$ === 'Just') {
				var operationName = maybeOperationName.a;
				return _Utils_Tuple2(
					A2($dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrlWithOperationName, operationName, queryDocument),
					_List_fromArray(
						[
							_Utils_Tuple2('operationName', operationName)
						]));
			} else {
				return _Utils_Tuple2(
					$dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrl(queryDocument),
					_List_Nil);
			}
		}();
		var serializedQueryForGetRequest = _v0.a;
		var operationNameParamForGetRequest = _v0.b;
		var urlForGetRequest = A2(
			$dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams,
			_Utils_ap(
				queryParams,
				_Utils_ap(
					_List_fromArray(
						[
							_Utils_Tuple2('query', serializedQueryForGetRequest)
						]),
					operationNameParamForGetRequest)),
			url);
		if (_Utils_eq(
			forceMethod,
			$elm$core$Maybe$Just($dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Post)) || ((_Utils_cmp(
			$elm$core$String$length(urlForGetRequest),
			$dillonkearns$elm_graphql$Graphql$Http$QueryHelper$maxLength) > -1) && (!_Utils_eq(
			forceMethod,
			$elm$core$Maybe$Just($dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Get))))) {
			var _v2 = function () {
				if (maybeOperationName.$ === 'Just') {
					var operationName = maybeOperationName.a;
					return _Utils_Tuple2(
						A2($dillonkearns$elm_graphql$Graphql$Document$serializeQueryWithOperationName, operationName, queryDocument),
						_List_fromArray(
							[
								_Utils_Tuple2(
								'operationName',
								$elm$json$Json$Encode$string(operationName))
							]));
				} else {
					return _Utils_Tuple2(
						$dillonkearns$elm_graphql$Graphql$Document$serializeQuery(queryDocument),
						_List_Nil);
				}
			}();
			var serializedQuery = _v2.a;
			var operationNameParamForPostRequest = _v2.b;
			return {
				body: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$object(
						_Utils_ap(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'query',
									$elm$json$Json$Encode$string(serializedQuery))
								]),
							operationNameParamForPostRequest))),
				method: $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Post,
				url: A2($dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams, _List_Nil, url)
			};
		} else {
			return {body: $elm$http$Http$emptyBody, method: $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Get, url: urlForGetRequest};
		}
	});
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$ParsedData = function (a) {
	return {$: 'ParsedData', a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData = function (a) {
	return {$: 'UnparsedData', a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$GraphqlError = F3(
	function (message, locations, details) {
		return {details: details, locations: locations, message: message};
	});
var $elm$json$Json$Decode$list = _Json_decodeList;
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$Location = F2(
	function (line, column) {
		return {column: column, line: line};
	});
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$locationDecoder = A3(
	$elm$json$Json$Decode$map2,
	$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$Location,
	A2($elm$json$Json$Decode$field, 'line', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'column', $elm$json$Json$Decode$int));
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$decoder = A2(
	$elm$json$Json$Decode$field,
	'errors',
	$elm$json$Json$Decode$list(
		A4(
			$elm$json$Json$Decode$map3,
			$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$GraphqlError,
			A2($elm$json$Json$Decode$field, 'message', $elm$json$Json$Decode$string),
			$elm$json$Json$Decode$maybe(
				A2(
					$elm$json$Json$Decode$field,
					'locations',
					$elm$json$Json$Decode$list($dillonkearns$elm_graphql$Graphql$Http$GraphqlError$locationDecoder))),
			A2(
				$elm$json$Json$Decode$map,
				$elm$core$Dict$remove('locations'),
				A2(
					$elm$json$Json$Decode$map,
					$elm$core$Dict$remove('message'),
					$elm$json$Json$Decode$dict($elm$json$Json$Decode$value))))));
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData = function (data) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Result$Err,
		A2(
			$elm$json$Json$Decode$map,
			$elm$core$Tuple$pair(data),
			$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$decoder));
};
var $dillonkearns$elm_graphql$Graphql$Http$nullJsonValue = function (a) {
	nullJsonValue:
	while (true) {
		var _v0 = A2($elm$json$Json$Decode$decodeString, $elm$json$Json$Decode$value, 'null');
		if (_v0.$ === 'Ok') {
			var value = _v0.a;
			return value;
		} else {
			var $temp$a = _Utils_Tuple0;
			a = $temp$a;
			continue nullJsonValue;
		}
	}
};
var $dillonkearns$elm_graphql$Graphql$Http$errorDecoder = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$andThen,
				$dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData,
				A2($elm$json$Json$Decode$map, $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$ParsedData, decoder)),
				A2(
				$elm$json$Json$Decode$andThen,
				$dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData,
				A2(
					$elm$json$Json$Decode$map,
					$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData,
					A2($elm$json$Json$Decode$field, 'data', $elm$json$Json$Decode$value))),
				A2(
				$elm$json$Json$Decode$andThen,
				$dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData,
				$elm$json$Json$Decode$succeed(
					$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData(
						$dillonkearns$elm_graphql$Graphql$Http$nullJsonValue(_Utils_Tuple0))))
			]));
};
var $dillonkearns$elm_graphql$Graphql$Http$decoderOrError = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$dillonkearns$elm_graphql$Graphql$Http$errorDecoder(decoder),
				A2($elm$json$Json$Decode$map, $elm$core$Result$Ok, decoder)
			]));
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeMutation = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return A2($dillonkearns$elm_graphql$Graphql$Document$serialize, 'mutation', fields);
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeMutationWithOperationName = F2(
	function (operationName, _v0) {
		var fields = _v0.a;
		var decoder_ = _v0.b;
		return A3($dillonkearns$elm_graphql$Graphql$Document$serializeWithOperationName, 'mutation', operationName, fields);
	});
var $dillonkearns$elm_graphql$Graphql$Http$toReadyRequest = function (_v0) {
	var request = _v0.a;
	var _v1 = request.details;
	if (_v1.$ === 'Query') {
		var forcedRequestMethod = _v1.a;
		var querySelectionSet = _v1.b;
		var queryRequestDetails = A5(
			$dillonkearns$elm_graphql$Graphql$Http$QueryHelper$build,
			function () {
				if (forcedRequestMethod.$ === 'Just') {
					if (forcedRequestMethod.a.$ === 'AlwaysGet') {
						var _v4 = forcedRequestMethod.a;
						return $elm$core$Maybe$Just($dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Get);
					} else {
						var _v5 = forcedRequestMethod.a;
						return $elm$core$Maybe$Nothing;
					}
				} else {
					return $elm$core$Maybe$Just($dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Post);
				}
			}(),
			request.baseUrl,
			request.queryParams,
			request.operationName,
			querySelectionSet);
		return {
			body: queryRequestDetails.body,
			decoder: $dillonkearns$elm_graphql$Graphql$Http$decoderOrError(request.expect),
			headers: request.headers,
			method: function () {
				var _v2 = queryRequestDetails.method;
				if (_v2.$ === 'Get') {
					return 'GET';
				} else {
					return 'Post';
				}
			}(),
			timeout: request.timeout,
			url: queryRequestDetails.url
		};
	} else {
		var mutationSelectionSet = _v1.a;
		var serializedMutation = function () {
			var _v6 = request.operationName;
			if (_v6.$ === 'Just') {
				var operationName = _v6.a;
				return A2($dillonkearns$elm_graphql$Graphql$Document$serializeMutationWithOperationName, operationName, mutationSelectionSet);
			} else {
				return $dillonkearns$elm_graphql$Graphql$Document$serializeMutation(mutationSelectionSet);
			}
		}();
		return {
			body: $elm$http$Http$jsonBody(
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'query',
							$elm$json$Json$Encode$string(serializedMutation))
						]))),
			decoder: $dillonkearns$elm_graphql$Graphql$Http$decoderOrError(request.expect),
			headers: request.headers,
			method: 'POST',
			timeout: request.timeout,
			url: A2($dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams, request.queryParams, request.baseUrl)
		};
	}
};
var $dillonkearns$elm_graphql$Graphql$Http$toHttpRequestRecord = F2(
	function (resultToMessage, fullRequest) {
		var request = fullRequest.a;
		return function (readyRequest) {
			return {
				body: readyRequest.body,
				expect: A2(
					$dillonkearns$elm_graphql$Graphql$Http$expectJson,
					A2($elm$core$Basics$composeR, $dillonkearns$elm_graphql$Graphql$Http$convertResult, resultToMessage),
					readyRequest.decoder),
				headers: readyRequest.headers,
				method: readyRequest.method,
				timeout: readyRequest.timeout,
				tracker: $elm$core$Maybe$Nothing,
				url: readyRequest.url
			};
		}(
			$dillonkearns$elm_graphql$Graphql$Http$toReadyRequest(fullRequest));
	});
var $dillonkearns$elm_graphql$Graphql$Http$send = F2(
	function (resultToMessage, fullRequest) {
		var request = fullRequest.a;
		return (request.withCredentials ? $elm$http$Http$riskyRequest : $elm$http$Http$request)(
			A2($dillonkearns$elm_graphql$Graphql$Http$toHttpRequestRecord, resultToMessage, fullRequest));
	});
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 'Header', a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $dillonkearns$elm_graphql$Graphql$Http$withHeader = F3(
	function (key, value, _v0) {
		var request = _v0.a;
		return $dillonkearns$elm_graphql$Graphql$Http$Request(
			_Utils_update(
				request,
				{
					headers: A2(
						$elm$core$List$cons,
						A2($elm$http$Http$header, key, value),
						request.headers)
				}));
	});
var $author$project$ClickToCall$Api$setTenantHeader = $dillonkearns$elm_graphql$Graphql$Http$withHeader('STW-Dialer-Tenant');
var $author$project$ClickToCall$Api$loadCampaign = F3(
	function (env, client, token) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Http$send,
			$author$project$ClickToCall$Types$CampaignLoaded,
			A2(
				$author$project$ClickToCall$Api$setTenantHeader,
				client,
				A2(
					$dillonkearns$elm_graphql$Graphql$Http$queryRequest,
					A2($author$project$ClickToCall$Api$gqlBaseUrl, env, client),
					A2(
						$author$project$Api$Query$campaign,
						{token: token},
						$author$project$ClickToCall$Api$selectCampaignFields))));
	});
var $author$project$ClickToCall$State$init = F3(
	function (client, token, env) {
		return _Utils_Tuple2(
			A8($author$project$ClickToCall$Types$Model, client, token, env, $author$project$ClickToCall$Types$ReadyToGetStarted, _List_Nil, _List_Nil, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing),
			A3($author$project$ClickToCall$Api$loadCampaign, env, client, token));
	});
var $author$project$InteropDefinitions$Caller = F4(
	function (phone, name, email, lastMedium) {
		return {email: email, lastMedium: lastMedium, name: name, phone: phone};
	});
var $author$project$Dialer$Types$GeneratedCallerChannelId = function (a) {
	return {$: 'GeneratedCallerChannelId', a: a};
};
var $author$project$Dialer$Types$LoadingCampaign = {$: 'LoadingCampaign'};
var $author$project$Dialer$Types$Model = function (client) {
	return function (token) {
		return function (env) {
			return function (caller) {
				return function (referralCode) {
					return function (state) {
						return function (shuffledJokes) {
							return function (jokeIndex) {
								return function (callerChannelId) {
									return function (campaignLastUpdate) {
										return function (currentTime) {
											return function (campaignAlerts) {
												return function (sidebarOpen) {
													return {caller: caller, callerChannelId: callerChannelId, campaignAlerts: campaignAlerts, campaignLastUpdate: campaignLastUpdate, client: client, currentTime: currentTime, env: env, jokeIndex: jokeIndex, referralCode: referralCode, shuffledJokes: shuffledJokes, sidebarOpen: sidebarOpen, state: state, token: token};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Dialer$Types$ShuffledJokes = function (a) {
	return {$: 'ShuffledJokes', a: a};
};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $elm$random$Random$Generator = function (a) {
	return {$: 'Generator', a: a};
};
var $elm$random$Random$andThen = F2(
	function (callback, _v0) {
		var genA = _v0.a;
		return $elm$random$Random$Generator(
			function (seed) {
				var _v1 = genA(seed);
				var result = _v1.a;
				var newSeed = _v1.b;
				var _v2 = callback(result);
				var genB = _v2.a;
				return genB(newSeed);
			});
	});
var $elm$random$Random$Seed = F2(
	function (a, b) {
		return {$: 'Seed', a: a, b: b};
	});
var $elm$random$Random$next = function (_v0) {
	var state0 = _v0.a;
	var incr = _v0.b;
	return A2($elm$random$Random$Seed, ((state0 * 1664525) + incr) >>> 0, incr);
};
var $elm$random$Random$peel = function (_v0) {
	var state = _v0.a;
	var word = (state ^ (state >>> ((state >>> 28) + 4))) * 277803737;
	return ((word >>> 22) ^ word) >>> 0;
};
var $elm$random$Random$float = F2(
	function (a, b) {
		return $elm$random$Random$Generator(
			function (seed0) {
				var seed1 = $elm$random$Random$next(seed0);
				var range = $elm$core$Basics$abs(b - a);
				var n1 = $elm$random$Random$peel(seed1);
				var n0 = $elm$random$Random$peel(seed0);
				var lo = (134217727 & n1) * 1.0;
				var hi = (67108863 & n0) * 1.0;
				var val = ((hi * 134217728.0) + lo) / 9007199254740992.0;
				var scaled = (val * range) + a;
				return _Utils_Tuple2(
					scaled,
					$elm$random$Random$next(seed1));
			});
	});
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $elm$core$List$sum = function (numbers) {
	return A3($elm$core$List$foldl, $elm$core$Basics$add, 0, numbers);
};
var $elm_community$random_extra$Random$Extra$frequency = F2(
	function (head, pairs) {
		var total = $elm$core$List$sum(
			A2(
				$elm$core$List$map,
				A2($elm$core$Basics$composeL, $elm$core$Basics$abs, $elm$core$Tuple$first),
				A2($elm$core$List$cons, head, pairs)));
		var pick = F2(
			function (someChoices, n) {
				pick:
				while (true) {
					if (someChoices.b) {
						var _v1 = someChoices.a;
						var k = _v1.a;
						var g = _v1.b;
						var rest = someChoices.b;
						if (_Utils_cmp(n, k) < 1) {
							return g;
						} else {
							var $temp$someChoices = rest,
								$temp$n = n - k;
							someChoices = $temp$someChoices;
							n = $temp$n;
							continue pick;
						}
					} else {
						return head.b;
					}
				}
			});
		return A2(
			$elm$random$Random$andThen,
			pick(
				A2($elm$core$List$cons, head, pairs)),
			A2($elm$random$Random$float, 0, total));
	});
var $elm_community$random_extra$Random$Extra$choices = F2(
	function (hd, gens) {
		return A2(
			$elm_community$random_extra$Random$Extra$frequency,
			_Utils_Tuple2(1, hd),
			A2(
				$elm$core$List$map,
				function (g) {
					return _Utils_Tuple2(1, g);
				},
				gens));
	});
var $elm$core$Char$fromCode = _Char_fromCode;
var $elm$random$Random$int = F2(
	function (a, b) {
		return $elm$random$Random$Generator(
			function (seed0) {
				var _v0 = (_Utils_cmp(a, b) < 0) ? _Utils_Tuple2(a, b) : _Utils_Tuple2(b, a);
				var lo = _v0.a;
				var hi = _v0.b;
				var range = (hi - lo) + 1;
				if (!((range - 1) & range)) {
					return _Utils_Tuple2(
						(((range - 1) & $elm$random$Random$peel(seed0)) >>> 0) + lo,
						$elm$random$Random$next(seed0));
				} else {
					var threshhold = (((-range) >>> 0) % range) >>> 0;
					var accountForBias = function (seed) {
						accountForBias:
						while (true) {
							var x = $elm$random$Random$peel(seed);
							var seedN = $elm$random$Random$next(seed);
							if (_Utils_cmp(x, threshhold) < 0) {
								var $temp$seed = seedN;
								seed = $temp$seed;
								continue accountForBias;
							} else {
								return _Utils_Tuple2((x % range) + lo, seedN);
							}
						}
					};
					return accountForBias(seed0);
				}
			});
	});
var $elm$random$Random$map = F2(
	function (func, _v0) {
		var genA = _v0.a;
		return $elm$random$Random$Generator(
			function (seed0) {
				var _v1 = genA(seed0);
				var a = _v1.a;
				var seed1 = _v1.b;
				return _Utils_Tuple2(
					func(a),
					seed1);
			});
	});
var $elm_community$random_extra$Random$Char$char = F2(
	function (start, end) {
		return A2(
			$elm$random$Random$map,
			$elm$core$Char$fromCode,
			A2($elm$random$Random$int, start, end));
	});
var $elm_community$random_extra$Random$Char$lowerCaseLatin = A2($elm_community$random_extra$Random$Char$char, 97, 122);
var $elm_community$random_extra$Random$Char$upperCaseLatin = A2($elm_community$random_extra$Random$Char$char, 65, 90);
var $elm_community$random_extra$Random$Char$latin = A2(
	$elm_community$random_extra$Random$Extra$choices,
	$elm_community$random_extra$Random$Char$lowerCaseLatin,
	_List_fromArray(
		[$elm_community$random_extra$Random$Char$upperCaseLatin]));
var $elm_community$random_extra$Random$Char$english = $elm_community$random_extra$Random$Char$latin;
var $elm$random$Random$Generate = function (a) {
	return {$: 'Generate', a: a};
};
var $elm$random$Random$initialSeed = function (x) {
	var _v0 = $elm$random$Random$next(
		A2($elm$random$Random$Seed, 0, 1013904223));
	var state1 = _v0.a;
	var incr = _v0.b;
	var state2 = (state1 + x) >>> 0;
	return $elm$random$Random$next(
		A2($elm$random$Random$Seed, state2, incr));
};
var $elm$time$Time$Name = function (a) {
	return {$: 'Name', a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 'Offset', a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 'Zone', a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$Posix = function (a) {
	return {$: 'Posix', a: a};
};
var $elm$time$Time$millisToPosix = $elm$time$Time$Posix;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0.a;
	return millis;
};
var $elm$random$Random$init = A2(
	$elm$core$Task$andThen,
	function (time) {
		return $elm$core$Task$succeed(
			$elm$random$Random$initialSeed(
				$elm$time$Time$posixToMillis(time)));
	},
	$elm$time$Time$now);
var $elm$random$Random$step = F2(
	function (_v0, seed) {
		var generator = _v0.a;
		return generator(seed);
	});
var $elm$random$Random$onEffects = F3(
	function (router, commands, seed) {
		if (!commands.b) {
			return $elm$core$Task$succeed(seed);
		} else {
			var generator = commands.a.a;
			var rest = commands.b;
			var _v1 = A2($elm$random$Random$step, generator, seed);
			var value = _v1.a;
			var newSeed = _v1.b;
			return A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$random$Random$onEffects, router, rest, newSeed);
				},
				A2($elm$core$Platform$sendToApp, router, value));
		}
	});
var $elm$random$Random$onSelfMsg = F3(
	function (_v0, _v1, seed) {
		return $elm$core$Task$succeed(seed);
	});
var $elm$random$Random$cmdMap = F2(
	function (func, _v0) {
		var generator = _v0.a;
		return $elm$random$Random$Generate(
			A2($elm$random$Random$map, func, generator));
	});
_Platform_effectManagers['Random'] = _Platform_createManager($elm$random$Random$init, $elm$random$Random$onEffects, $elm$random$Random$onSelfMsg, $elm$random$Random$cmdMap);
var $elm$random$Random$command = _Platform_leaf('Random');
var $elm$random$Random$generate = F2(
	function (tagger, generator) {
		return $elm$random$Random$command(
			$elm$random$Random$Generate(
				A2($elm$random$Random$map, tagger, generator)));
	});
var $author$project$Dialer$Types$Joke = F2(
	function (setup, punchline) {
		return {punchline: punchline, setup: setup};
	});
var $author$project$Dialer$States$InQueue$jokes = _List_fromArray(
	[
		A2($author$project$Dialer$Types$Joke, 'Why did the telephone go to school?', 'Because it wanted to be a smart phone!'),
		A2($author$project$Dialer$Types$Joke, 'Why was the telephone always invited to parties?', 'Because it knew how to \"dial\" up the fun!'),
		A2($author$project$Dialer$Types$Joke, 'Why was the smartphone always running late?', 'Because it had too many \"app\"ointments!'),
		A2($author$project$Dialer$Types$Joke, 'What do you call a phone that sings?', 'A mobile tone!'),
		A2($author$project$Dialer$Types$Joke, 'Why did the smartphone go to the wedding?', 'It heard there would be a great \"reception\"!'),
		A2($author$project$Dialer$Types$Joke, 'Why did the smartphone go to the orchestra concert?', 'To listen to the \"cell\"ists!'),
		A2($author$project$Dialer$Types$Joke, 'How did the smartphone get an A+ on the test?', 'They read the \"text\"-book cover-to-cover!'),
		A2($author$project$Dialer$Types$Joke, 'How did the smartphone become a millionaire?', 'It was a \"cell\"-out author!'),
		A2($author$project$Dialer$Types$Joke, 'Why was the smartphone always so popular?', 'Because it had the best \"connections\"!'),
		A2($author$project$Dialer$Types$Joke, 'What\'s a smartphone\'s favorite party snack?', 'Microchips and guac!'),
		A2($author$project$Dialer$Types$Joke, 'Why did the smartphone break up with its wired earbuds?', 'It couldn\'t stand the \"attachment\"!'),
		A2($author$project$Dialer$Types$Joke, 'Why was the smartphone always so confident?', 'Because it had great \"networking\" skills!'),
		A2($author$project$Dialer$Types$Joke, 'How does a phone propose?', 'With a ring!'),
		A2($author$project$Dialer$Types$Joke, 'What noise does a bird\'s phone make?', '\"Wing wing\"!')
	]);
var $author$project$Dialer$Types$CampaignAlertsLoaded = function (a) {
	return {$: 'CampaignAlertsLoaded', a: a};
};
var $author$project$Dialer$Api$gqlBaseUrl = F2(
	function (env, client) {
		return A2($author$project$Utils$baseUrl, env, client) + '/caller/graphql';
	});
var $author$project$Api$Query$campaignAlerts = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'campaignAlerts',
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'token', requiredArgs____.token, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)
				]),
			object____,
			$elm$core$Basics$identity);
	});
var $author$project$Dialer$Types$CampaignAlerts = F4(
	function (onCompleteMessage, onPausedMessage, outOfHoursMessage, callerAlertMessage) {
		return {callerAlertMessage: callerAlertMessage, onCompleteMessage: onCompleteMessage, onPausedMessage: onPausedMessage, outOfHoursMessage: outOfHoursMessage};
	});
var $author$project$Api$Object$CampaignAlerts$customCallerAlert = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'customCallerAlert', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Api$Object$CampaignAlerts$onCompleteMessage = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'onCompleteMessage', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Api$Object$CampaignAlerts$onPausedMessage = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'onPausedMessage', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Api$Object$CampaignAlerts$outOfHoursMessage = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'outOfHoursMessage', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Dialer$Types$CampaignCallerAlertMessage = F2(
	function (message, expiresAt) {
		return {expiresAt: expiresAt, message: message};
	});
var $author$project$Api$Object$CustomCallerAlert$expiredAt = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'expiredAt',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $dillonkearns$elm_graphql$Graphql$SelectionSet$map2 = F3(
	function (combine, _v0, _v1) {
		var selectionFields1 = _v0.a;
		var selectionDecoder1 = _v0.b;
		var selectionFields2 = _v1.a;
		var selectionDecoder2 = _v1.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			_Utils_ap(selectionFields1, selectionFields2),
			A3($elm$json$Json$Decode$map2, combine, selectionDecoder1, selectionDecoder2));
	});
var $author$project$Api$Object$CustomCallerAlert$message = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'message',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Dialer$Api$selectCustomCallerAlertField = A3($dillonkearns$elm_graphql$Graphql$SelectionSet$map2, $author$project$Dialer$Types$CampaignCallerAlertMessage, $author$project$Api$Object$CustomCallerAlert$message, $author$project$Api$Object$CustomCallerAlert$expiredAt);
var $author$project$Dialer$Api$selectCampaignAlerts = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Api$Object$CampaignAlerts$customCallerAlert($author$project$Dialer$Api$selectCustomCallerAlertField),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Api$Object$CampaignAlerts$outOfHoursMessage,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Api$Object$CampaignAlerts$onPausedMessage,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Api$Object$CampaignAlerts$onCompleteMessage,
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Dialer$Types$CampaignAlerts)))));
var $author$project$Dialer$Api$queryAlerts = function (token) {
	return A2(
		$author$project$Api$Query$campaignAlerts,
		{token: token},
		$author$project$Dialer$Api$selectCampaignAlerts);
};
var $author$project$Dialer$Api$setTenantHeader = $dillonkearns$elm_graphql$Graphql$Http$withHeader('STW-Dialer-Tenant');
var $author$project$Dialer$Api$requestCampaignAlertsFromApi = F3(
	function (env, client, token) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Http$send,
			$author$project$Dialer$Types$CampaignAlertsLoaded,
			A2(
				$author$project$Dialer$Api$setTenantHeader,
				client,
				A2(
					$dillonkearns$elm_graphql$Graphql$Http$queryRequest,
					A2($author$project$Dialer$Api$gqlBaseUrl, env, client),
					$author$project$Dialer$Api$queryAlerts(token))));
	});
var $author$project$Dialer$Types$CampaignLoaded = function (a) {
	return {$: 'CampaignLoaded', a: a};
};
var $author$project$Dialer$Types$Campaign = function (name) {
	return function (status) {
		return function (ivr) {
			return function (phone) {
				return function (web_phone) {
					return function (endpoint_login) {
						return function (endpoint_password) {
							return function (questions) {
								return function (pusherConfigs) {
									return function (provider) {
										return function (twilio_token) {
											return function (callee_fields) {
												return function (country_iso) {
													return function (collect_caller_email) {
														return function (updated_at) {
															return function (enableTalkingPoints) {
																return function (talkingPoints) {
																	return function (enableCalleeAdditionalInfo) {
																		return function (calleeAdditionalInfo) {
																			return {calleeAdditionalInfo: calleeAdditionalInfo, callee_fields: callee_fields, collect_caller_email: collect_caller_email, country_iso: country_iso, enableCalleeAdditionalInfo: enableCalleeAdditionalInfo, enableTalkingPoints: enableTalkingPoints, endpoint_login: endpoint_login, endpoint_password: endpoint_password, ivr: ivr, name: name, phone: phone, provider: provider, pusherConfigs: pusherConfigs, questions: questions, status: status, talkingPoints: talkingPoints, twilio_token: twilio_token, updated_at: updated_at, web_phone: web_phone};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Api$Object$Campaign$calleeAdditionalInfo = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'calleeAdditionalInfo', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Api$Object$Campaign$calleeFields = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'calleeFields',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Api$Object$Campaign$collect_caller_email = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'collect_caller_email', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Api$Object$Campaign$country_iso = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'country_iso', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Api$Object$Campaign$enableCalleeAdditionalInfo = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'enableCalleeAdditionalInfo', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Api$Object$Campaign$enableTalkingPoints = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'enableTalkingPoints', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Api$Object$Campaign$endpoint_login = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'endpoint_login',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Api$Object$Campaign$endpoint_password = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'endpoint_password',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Api$Object$Campaign$ivr = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'ivr', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Api$Object$Campaign$ivr_phone_number = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'ivr_phone_number',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Api$Object$Campaign$provider = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'provider',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Api$Object$Campaign$pusherConfigs = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'pusherConfigs',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Api$Object$Campaign$questions = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'questions',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Dialer$Types$CalleeAdditionalInfo = F4(
	function (name, phoneNumber, homeAddress, pollingPlace) {
		return {homeAddress: homeAddress, name: name, phoneNumber: phoneNumber, pollingPlace: pollingPlace};
	});
var $author$project$Api$Object$CalleeAdditionalInfo$homeAddress = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'homeAddress', _List_Nil, $elm$json$Json$Decode$bool);
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$json$Json$Decode$map4 = _Json_map4;
var $dillonkearns$elm_graphql$Graphql$SelectionSet$map4 = F5(
	function (combine, _v0, _v1, _v2, _v3) {
		var selectionFields1 = _v0.a;
		var selectionDecoder1 = _v0.b;
		var selectionFields2 = _v1.a;
		var selectionDecoder2 = _v1.b;
		var selectionFields3 = _v2.a;
		var selectionDecoder3 = _v2.b;
		var selectionFields4 = _v3.a;
		var selectionDecoder4 = _v3.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			$elm$core$List$concat(
				_List_fromArray(
					[selectionFields1, selectionFields2, selectionFields3, selectionFields4])),
			A5($elm$json$Json$Decode$map4, combine, selectionDecoder1, selectionDecoder2, selectionDecoder3, selectionDecoder4));
	});
var $author$project$Api$Object$CalleeAdditionalInfo$name = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'name', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Api$Object$CalleeAdditionalInfo$phoneNumber = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'phoneNumber', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Api$Object$CalleeAdditionalInfo$pollingPlace = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'pollingPlace', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Dialer$Api$selectCalleeAdditionalInfo = A5($dillonkearns$elm_graphql$Graphql$SelectionSet$map4, $author$project$Dialer$Types$CalleeAdditionalInfo, $author$project$Api$Object$CalleeAdditionalInfo$name, $author$project$Api$Object$CalleeAdditionalInfo$phoneNumber, $author$project$Api$Object$CalleeAdditionalInfo$homeAddress, $author$project$Api$Object$CalleeAdditionalInfo$pollingPlace);
var $author$project$Dialer$Types$CampaignCalleeField = F5(
	function (id, name, dataSourceFieldName, builtInField, sidebarOrder) {
		return {builtInField: builtInField, dataSourceFieldName: dataSourceFieldName, id: id, name: name, sidebarOrder: sidebarOrder};
	});
var $author$project$Api$Enum$BuiltinCalleeField$AddressLine1 = {$: 'AddressLine1'};
var $author$project$Api$Enum$BuiltinCalleeField$AddressLine2 = {$: 'AddressLine2'};
var $author$project$Api$Enum$BuiltinCalleeField$City = {$: 'City'};
var $author$project$Api$Enum$BuiltinCalleeField$Email = {$: 'Email'};
var $author$project$Api$Enum$BuiltinCalleeField$FirstName = {$: 'FirstName'};
var $author$project$Api$Enum$BuiltinCalleeField$LastName = {$: 'LastName'};
var $author$project$Api$Enum$BuiltinCalleeField$PollingPlaceAddressLine1 = {$: 'PollingPlaceAddressLine1'};
var $author$project$Api$Enum$BuiltinCalleeField$PollingPlaceCity = {$: 'PollingPlaceCity'};
var $author$project$Api$Enum$BuiltinCalleeField$PollingPlaceName = {$: 'PollingPlaceName'};
var $author$project$Api$Enum$BuiltinCalleeField$PollingPlaceState = {$: 'PollingPlaceState'};
var $author$project$Api$Enum$BuiltinCalleeField$State = {$: 'State'};
var $author$project$Api$Enum$BuiltinCalleeField$Zip = {$: 'Zip'};
var $author$project$Api$Enum$BuiltinCalleeField$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'FIRST_NAME':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$BuiltinCalleeField$FirstName);
			case 'LAST_NAME':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$BuiltinCalleeField$LastName);
			case 'EMAIL':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$BuiltinCalleeField$Email);
			case 'ADDRESS_LINE_1':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$BuiltinCalleeField$AddressLine1);
			case 'ADDRESS_LINE_2':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$BuiltinCalleeField$AddressLine2);
			case 'CITY':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$BuiltinCalleeField$City);
			case 'STATE':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$BuiltinCalleeField$State);
			case 'ZIP':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$BuiltinCalleeField$Zip);
			case 'POLLING_PLACE_NAME':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$BuiltinCalleeField$PollingPlaceName);
			case 'POLLING_PLACE_ADDRESS_LINE_1':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$BuiltinCalleeField$PollingPlaceAddressLine1);
			case 'POLLING_PLACE_CITY':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$BuiltinCalleeField$PollingPlaceCity);
			case 'POLLING_PLACE_STATE':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$BuiltinCalleeField$PollingPlaceState);
			default:
				return $elm$json$Json$Decode$fail('Invalid BuiltinCalleeField type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Api$Object$CampaignCalleeField$builtInField = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Enum.BuiltinCalleeField.BuiltinCalleeField)',
	'builtInField',
	_List_Nil,
	$elm$json$Json$Decode$nullable($author$project$Api$Enum$BuiltinCalleeField$decoder));
var $author$project$Api$Object$CampaignCalleeField$dataSourceFieldName = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'dataSourceFieldName',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Api$Object$CampaignCalleeField$id = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe ScalarCodecs.Id)',
	'id',
	_List_Nil,
	$elm$json$Json$Decode$nullable(
		$author$project$Api$Scalar$unwrapCodecs($author$project$Api$ScalarCodecs$codecs).codecId.decoder));
var $elm$json$Json$Decode$map5 = _Json_map5;
var $dillonkearns$elm_graphql$Graphql$SelectionSet$map5 = F6(
	function (combine, _v0, _v1, _v2, _v3, _v4) {
		var selectionFields1 = _v0.a;
		var selectionDecoder1 = _v0.b;
		var selectionFields2 = _v1.a;
		var selectionDecoder2 = _v1.b;
		var selectionFields3 = _v2.a;
		var selectionDecoder3 = _v2.b;
		var selectionFields4 = _v3.a;
		var selectionDecoder4 = _v3.b;
		var selectionFields5 = _v4.a;
		var selectionDecoder5 = _v4.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			$elm$core$List$concat(
				_List_fromArray(
					[selectionFields1, selectionFields2, selectionFields3, selectionFields4, selectionFields5])),
			A6($elm$json$Json$Decode$map5, combine, selectionDecoder1, selectionDecoder2, selectionDecoder3, selectionDecoder4, selectionDecoder5));
	});
var $author$project$Api$Object$CampaignCalleeField$name = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'name', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Api$Object$CampaignCalleeField$sidebarOrder = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Int)',
	'sidebarOrder',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int));
var $author$project$Dialer$Api$selectCalleeField = A6($dillonkearns$elm_graphql$Graphql$SelectionSet$map5, $author$project$Dialer$Types$CampaignCalleeField, $author$project$Api$Object$CampaignCalleeField$id, $author$project$Api$Object$CampaignCalleeField$name, $author$project$Api$Object$CampaignCalleeField$dataSourceFieldName, $author$project$Api$Object$CampaignCalleeField$builtInField, $author$project$Api$Object$CampaignCalleeField$sidebarOrder);
var $author$project$Api$Object$PusherConfig$appId = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'appId', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Api$Object$PusherConfig$cluster = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'cluster', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Api$Object$PusherConfig$key = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'key', _List_Nil, $elm$json$Json$Decode$string);
var $dillonkearns$elm_graphql$Graphql$SelectionSet$map3 = F4(
	function (combine, _v0, _v1, _v2) {
		var selectionFields1 = _v0.a;
		var selectionDecoder1 = _v0.b;
		var selectionFields2 = _v1.a;
		var selectionDecoder2 = _v1.b;
		var selectionFields3 = _v2.a;
		var selectionDecoder3 = _v2.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			$elm$core$List$concat(
				_List_fromArray(
					[selectionFields1, selectionFields2, selectionFields3])),
			A4($elm$json$Json$Decode$map3, combine, selectionDecoder1, selectionDecoder2, selectionDecoder3));
	});
var $author$project$Dialer$Api$selectPusherConfig = A4($dillonkearns$elm_graphql$Graphql$SelectionSet$map3, $author$project$InteropDefinitions$PusherConfig, $author$project$Api$Object$PusherConfig$appId, $author$project$Api$Object$PusherConfig$cluster, $author$project$Api$Object$PusherConfig$key);
var $author$project$Dialer$Types$Question = function (key) {
	return function (name) {
		return function (script) {
			return function (isRichText) {
				return function (answers) {
					return function (questionType) {
						return function (next) {
							return function (mobilizeAmericaRsvpSettings) {
								return function (sendMessageSettings) {
									return function (pollingPlaceLookupSettings) {
										return {answers: answers, isRichText: isRichText, key: key, mobilizeAmericaRsvpSettings: mobilizeAmericaRsvpSettings, name: name, next: next, pollingPlaceLookupSettings: pollingPlaceLookupSettings, questionType: questionType, script: script, sendMessageSettings: sendMessageSettings};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Api$Object$Question$answers = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'answers',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Api$Object$Question$isRichText = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Bool)',
	'isRichText',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$bool));
var $author$project$Api$Object$Question$key = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'key', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Api$Object$Question$mobilizeAmericaRsvpSettings = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'mobilizeAmericaRsvpSettings',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Api$Object$Question$name = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'name', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Api$Object$Question$next = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'next',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Api$Object$Question$pollingPlaceLookupSettings = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'pollingPlaceLookupSettings',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Api$Enum$QuestionType$Disposition = {$: 'Disposition'};
var $author$project$Api$Enum$QuestionType$MobilizeamericaRsvp = {$: 'MobilizeamericaRsvp'};
var $author$project$Api$Enum$QuestionType$MultipleChoice = {$: 'MultipleChoice'};
var $author$project$Api$Enum$QuestionType$PatchThru = {$: 'PatchThru'};
var $author$project$Api$Enum$QuestionType$PollingPlaceLookup = {$: 'PollingPlaceLookup'};
var $author$project$Api$Enum$QuestionType$SendMessage = {$: 'SendMessage'};
var $author$project$Api$Enum$QuestionType$SingleChoice = {$: 'SingleChoice'};
var $author$project$Api$Enum$QuestionType$TextField = {$: 'TextField'};
var $author$project$Api$Enum$QuestionType$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'DISPOSITION':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$QuestionType$Disposition);
			case 'SINGLE_CHOICE':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$QuestionType$SingleChoice);
			case 'MULTIPLE_CHOICE':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$QuestionType$MultipleChoice);
			case 'TEXT_FIELD':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$QuestionType$TextField);
			case 'PATCH_THRU':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$QuestionType$PatchThru);
			case 'MOBILIZEAMERICA_RSVP':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$QuestionType$MobilizeamericaRsvp);
			case 'SEND_MESSAGE':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$QuestionType$SendMessage);
			case 'POLLING_PLACE_LOOKUP':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$QuestionType$PollingPlaceLookup);
			default:
				return $elm$json$Json$Decode$fail('Invalid QuestionType type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Api$Object$Question$questionType = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.QuestionType.QuestionType', 'questionType', _List_Nil, $author$project$Api$Enum$QuestionType$decoder);
var $author$project$Api$Object$Question$script = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'script',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Dialer$Types$Answer = F5(
	function (key, value, next, disposition, patchThruNumber) {
		return {disposition: disposition, key: key, next: next, patchThruNumber: patchThruNumber, value: value};
	});
var $author$project$Api$Enum$DispositionType$AnsweringMachine = {$: 'AnsweringMachine'};
var $author$project$Api$Enum$DispositionType$Callback = {$: 'Callback'};
var $author$project$Api$Enum$DispositionType$Canvassed = {$: 'Canvassed'};
var $author$project$Api$Enum$DispositionType$NoCallback = {$: 'NoCallback'};
var $author$project$Api$Enum$DispositionType$OptOut = {$: 'OptOut'};
var $author$project$Api$Enum$DispositionType$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'CANVASSED':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$DispositionType$Canvassed);
			case 'CALLBACK':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$DispositionType$Callback);
			case 'NO_CALLBACK':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$DispositionType$NoCallback);
			case 'ANSWERING_MACHINE':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$DispositionType$AnsweringMachine);
			case 'OPT_OUT':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$DispositionType$OptOut);
			default:
				return $elm$json$Json$Decode$fail('Invalid DispositionType type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Api$Object$Answer$disposition = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Enum.DispositionType.DispositionType)',
	'disposition',
	_List_Nil,
	$elm$json$Json$Decode$nullable($author$project$Api$Enum$DispositionType$decoder));
var $author$project$Api$Object$Answer$key = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'key', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Api$Object$Answer$next = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'next',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Api$Object$Answer$patchThruNumber = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'patchThruNumber',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Api$Object$Answer$value = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'value', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Dialer$Api$selectAnswer = A6($dillonkearns$elm_graphql$Graphql$SelectionSet$map5, $author$project$Dialer$Types$Answer, $author$project$Api$Object$Answer$key, $author$project$Api$Object$Answer$value, $author$project$Api$Object$Answer$next, $author$project$Api$Object$Answer$disposition, $author$project$Api$Object$Answer$patchThruNumber);
var $author$project$Dialer$Types$MobilizeAmericaRsvpSettings = F2(
	function (eventId, mobilizeTenantSlug) {
		return {eventId: eventId, mobilizeTenantSlug: mobilizeTenantSlug};
	});
var $author$project$Api$Object$CampaignQuestionMobilizeAmericaRsvpSettings$eventId = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Int', 'eventId', _List_Nil, $elm$json$Json$Decode$int);
var $author$project$Api$Object$CampaignQuestionMobilizeAmericaRsvpSettings$mobilizeTenantSlug = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'mobilizeTenantSlug', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Dialer$Api$selectMobilizeAmericaRsvpSettings = A3($dillonkearns$elm_graphql$Graphql$SelectionSet$map2, $author$project$Dialer$Types$MobilizeAmericaRsvpSettings, $author$project$Api$Object$CampaignQuestionMobilizeAmericaRsvpSettings$eventId, $author$project$Api$Object$CampaignQuestionMobilizeAmericaRsvpSettings$mobilizeTenantSlug);
var $author$project$Dialer$Types$PollingPlaceLookupSettings = function (lookupTool) {
	return {lookupTool: lookupTool};
};
var $author$project$Api$Enum$PollingPlaceLookupTool$Iwillvote = {$: 'Iwillvote'};
var $author$project$Api$Enum$PollingPlaceLookupTool$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		if (string === 'IWILLVOTE') {
			return $elm$json$Json$Decode$succeed($author$project$Api$Enum$PollingPlaceLookupTool$Iwillvote);
		} else {
			return $elm$json$Json$Decode$fail('Invalid PollingPlaceLookupTool type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Api$Object$CampaignQuestionPollingPlaceLookupSettings$lookupTool = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.PollingPlaceLookupTool.PollingPlaceLookupTool', 'lookupTool', _List_Nil, $author$project$Api$Enum$PollingPlaceLookupTool$decoder);
var $dillonkearns$elm_graphql$Graphql$SelectionSet$map = F2(
	function (mapFunction, _v0) {
		var selectionFields = _v0.a;
		var selectionDecoder = _v0.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			selectionFields,
			A2($elm$json$Json$Decode$map, mapFunction, selectionDecoder));
	});
var $author$project$Dialer$Api$selectPollingPlaceLookupSettings = A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Dialer$Types$PollingPlaceLookupSettings, $author$project$Api$Object$CampaignQuestionPollingPlaceLookupSettings$lookupTool);
var $author$project$Dialer$Types$SendMessageSettings = function (text) {
	return {text: text};
};
var $author$project$Api$Object$CampaignQuestionSendMessageSettings$text = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'text', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Dialer$Api$selectSendMessageSettings = A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Dialer$Types$SendMessageSettings, $author$project$Api$Object$CampaignQuestionSendMessageSettings$text);
var $author$project$Api$Object$Question$sendMessageSettings = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'sendMessageSettings',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Dialer$Api$selectQuestion = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Api$Object$Question$pollingPlaceLookupSettings($author$project$Dialer$Api$selectPollingPlaceLookupSettings),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Api$Object$Question$sendMessageSettings($author$project$Dialer$Api$selectSendMessageSettings),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Api$Object$Question$mobilizeAmericaRsvpSettings($author$project$Dialer$Api$selectMobilizeAmericaRsvpSettings),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Api$Object$Question$next,
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Api$Object$Question$questionType,
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						$author$project$Api$Object$Question$answers($author$project$Dialer$Api$selectAnswer),
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							$author$project$Api$Object$Question$isRichText,
							A2(
								$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
								$author$project$Api$Object$Question$script,
								A2(
									$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
									$author$project$Api$Object$Question$name,
									A2(
										$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
										$author$project$Api$Object$Question$key,
										$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Dialer$Types$Question)))))))))));
var $author$project$Dialer$Types$TalkingPoints = F2(
	function (heading, text) {
		return {heading: heading, text: text};
	});
var $author$project$Api$Object$TalkingPoints$heading = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'heading', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Api$Object$TalkingPoints$text = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'text', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Dialer$Api$selectTalkingPoints = A3($dillonkearns$elm_graphql$Graphql$SelectionSet$map2, $author$project$Dialer$Types$TalkingPoints, $author$project$Api$Object$TalkingPoints$heading, $author$project$Api$Object$TalkingPoints$text);
var $author$project$Api$Object$Campaign$status = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'status', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Api$Object$Campaign$talkingPoints = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'talkingPoints',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Api$Object$Campaign$twilio_token = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'twilio_token',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Api$Object$Campaign$updated_at = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'updated_at', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Api$Object$Campaign$web_phone_number = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'web_phone_number',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Dialer$Api$selectCampaign = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Api$Object$Campaign$calleeAdditionalInfo($author$project$Dialer$Api$selectCalleeAdditionalInfo),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Api$Object$Campaign$enableCalleeAdditionalInfo,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Api$Object$Campaign$talkingPoints($author$project$Dialer$Api$selectTalkingPoints),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Api$Object$Campaign$enableTalkingPoints,
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Api$Object$Campaign$updated_at,
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						$author$project$Api$Object$Campaign$collect_caller_email,
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							$author$project$Api$Object$Campaign$country_iso,
							A2(
								$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
								$author$project$Api$Object$Campaign$calleeFields($author$project$Dialer$Api$selectCalleeField),
								A2(
									$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
									$author$project$Api$Object$Campaign$twilio_token,
									A2(
										$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
										$author$project$Api$Object$Campaign$provider,
										A2(
											$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
											$author$project$Api$Object$Campaign$pusherConfigs($author$project$Dialer$Api$selectPusherConfig),
											A2(
												$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
												$author$project$Api$Object$Campaign$questions($author$project$Dialer$Api$selectQuestion),
												A2(
													$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
													$author$project$Api$Object$Campaign$endpoint_password,
													A2(
														$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
														$author$project$Api$Object$Campaign$endpoint_login,
														A2(
															$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
															$author$project$Api$Object$Campaign$web_phone_number,
															A2(
																$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																$author$project$Api$Object$Campaign$ivr_phone_number,
																A2(
																	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																	$author$project$Api$Object$Campaign$ivr,
																	A2(
																		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																		$author$project$Api$Object$Campaign$status,
																		A2(
																			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																			$author$project$Api$Object$Campaign$name,
																			$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Dialer$Types$Campaign))))))))))))))))))));
var $author$project$Dialer$Api$query = function (token) {
	return A2(
		$author$project$Api$Query$campaign,
		{token: token},
		$author$project$Dialer$Api$selectCampaign);
};
var $author$project$Dialer$Api$requestCampaignFromApi = F3(
	function (env, client, token) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Http$send,
			$author$project$Dialer$Types$CampaignLoaded,
			A2(
				$author$project$Dialer$Api$setTenantHeader,
				client,
				A2(
					$dillonkearns$elm_graphql$Graphql$Http$queryRequest,
					A2($author$project$Dialer$Api$gqlBaseUrl, env, client),
					$author$project$Dialer$Api$query(token))));
	});
var $elm$random$Random$maxInt = 2147483647;
var $elm$random$Random$minInt = -2147483648;
var $elm_community$random_extra$Random$List$anyInt = A2($elm$random$Random$int, $elm$random$Random$minInt, $elm$random$Random$maxInt);
var $elm$random$Random$map3 = F4(
	function (func, _v0, _v1, _v2) {
		var genA = _v0.a;
		var genB = _v1.a;
		var genC = _v2.a;
		return $elm$random$Random$Generator(
			function (seed0) {
				var _v3 = genA(seed0);
				var a = _v3.a;
				var seed1 = _v3.b;
				var _v4 = genB(seed1);
				var b = _v4.a;
				var seed2 = _v4.b;
				var _v5 = genC(seed2);
				var c = _v5.a;
				var seed3 = _v5.b;
				return _Utils_Tuple2(
					A3(func, a, b, c),
					seed3);
			});
	});
var $elm$random$Random$independentSeed = $elm$random$Random$Generator(
	function (seed0) {
		var makeIndependentSeed = F3(
			function (state, b, c) {
				return $elm$random$Random$next(
					A2($elm$random$Random$Seed, state, (1 | (b ^ c)) >>> 0));
			});
		var gen = A2($elm$random$Random$int, 0, 4294967295);
		return A2(
			$elm$random$Random$step,
			A4($elm$random$Random$map3, makeIndependentSeed, gen, gen, gen),
			seed0);
	});
var $elm_community$random_extra$Random$List$shuffle = function (list) {
	return A2(
		$elm$random$Random$map,
		function (independentSeed) {
			return A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2(
					$elm$core$List$sortBy,
					$elm$core$Tuple$second,
					A3(
						$elm$core$List$foldl,
						F2(
							function (item, _v0) {
								var acc = _v0.a;
								var seed = _v0.b;
								var _v1 = A2($elm$random$Random$step, $elm_community$random_extra$Random$List$anyInt, seed);
								var tag = _v1.a;
								var nextSeed = _v1.b;
								return _Utils_Tuple2(
									A2(
										$elm$core$List$cons,
										_Utils_Tuple2(item, tag),
										acc),
									nextSeed);
							}),
						_Utils_Tuple2(_List_Nil, independentSeed),
						list).a));
		},
		$elm$random$Random$independentSeed);
};
var $elm$core$String$fromList = _String_fromList;
var $elm$random$Random$listHelp = F4(
	function (revList, n, gen, seed) {
		listHelp:
		while (true) {
			if (n < 1) {
				return _Utils_Tuple2(revList, seed);
			} else {
				var _v0 = gen(seed);
				var value = _v0.a;
				var newSeed = _v0.b;
				var $temp$revList = A2($elm$core$List$cons, value, revList),
					$temp$n = n - 1,
					$temp$gen = gen,
					$temp$seed = newSeed;
				revList = $temp$revList;
				n = $temp$n;
				gen = $temp$gen;
				seed = $temp$seed;
				continue listHelp;
			}
		}
	});
var $elm$random$Random$list = F2(
	function (n, _v0) {
		var gen = _v0.a;
		return $elm$random$Random$Generator(
			function (seed) {
				return A4($elm$random$Random$listHelp, _List_Nil, n, gen, seed);
			});
	});
var $elm_community$random_extra$Random$String$string = F2(
	function (stringLength, charGenerator) {
		return A2(
			$elm$random$Random$map,
			$elm$core$String$fromList,
			A2($elm$random$Random$list, stringLength, charGenerator));
	});
var $author$project$Dialer$State$init = function (client) {
	return function (token) {
		return function (env) {
			return function (phone) {
				return function (name) {
					return function (email) {
						return function (referralCode) {
							return function (lastMedium) {
								return function (currentTimeMillis) {
									return function (_v0) {
										return _Utils_Tuple2(
											$author$project$Dialer$Types$Model(client)(token)(env)(
												A4($author$project$InteropDefinitions$Caller, phone, name, email, lastMedium))(referralCode)($author$project$Dialer$Types$LoadingCampaign)($author$project$Dialer$States$InQueue$jokes)(-1)('')(
												{reloadRequired: false, time: ''})(
												$elm$time$Time$millisToPosix(currentTimeMillis))($elm$core$Maybe$Nothing)(true),
											$elm$core$Platform$Cmd$batch(
												_List_fromArray(
													[
														A3($author$project$Dialer$Api$requestCampaignFromApi, env, client, token),
														A3($author$project$Dialer$Api$requestCampaignAlertsFromApi, env, client, token),
														A2(
														$elm$random$Random$generate,
														$author$project$Dialer$Types$ShuffledJokes,
														$elm_community$random_extra$Random$List$shuffle($author$project$Dialer$States$InQueue$jokes)),
														A2(
														$elm$random$Random$generate,
														$author$project$Dialer$Types$GeneratedCallerChannelId,
														A2($elm_community$random_extra$Random$String$string, 64, $elm_community$random_extra$Random$Char$english))
													])));
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $sporto$qs$QS$Many = function (a) {
	return {$: 'Many', a: a};
};
var $sporto$qs$QS$get = F2(
	function (key, query) {
		return A2($elm$core$Dict$get, key, query);
	});
var $sporto$qs$QS$Boolean = function (a) {
	return {$: 'Boolean', a: a};
};
var $sporto$qs$QS$Number = function (a) {
	return {$: 'Number', a: a};
};
var $sporto$qs$QS$Str = function (a) {
	return {$: 'Str', a: a};
};
var $elm$core$String$toFloat = _String_toFloat;
var $elm$core$String$trim = _String_trim;
var $sporto$qs$QS$rawValueToValue = F2(
	function (cfg, val) {
		var _true = 'true';
		var trimmed = $elm$core$String$trim(val);
		var maybeFloat = $elm$core$String$toFloat(trimmed);
		var isNum = !_Utils_eq(maybeFloat, $elm$core$Maybe$Nothing);
		var isEmpty = trimmed === '';
		var _false = 'false';
		var isBool = _Utils_eq(trimmed, _true) || _Utils_eq(trimmed, _false);
		if (isEmpty) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (isBool && cfg.parseBooleans) {
				switch (trimmed) {
					case 'true':
						return $elm$core$Maybe$Just(
							$sporto$qs$QS$Boolean(true));
					case 'false':
						return $elm$core$Maybe$Just(
							$sporto$qs$QS$Boolean(false));
					default:
						return $elm$core$Maybe$Just(
							$sporto$qs$QS$Str(trimmed));
				}
			} else {
				if (isNum && cfg.parseNumbers) {
					if (maybeFloat.$ === 'Just') {
						var n = maybeFloat.a;
						return $elm$core$Maybe$Just(
							$sporto$qs$QS$Number(n));
					} else {
						return $elm$core$Maybe$Just(
							$sporto$qs$QS$Str(trimmed));
					}
				} else {
					return $elm$core$Maybe$Just(
						$sporto$qs$QS$Str(trimmed));
				}
			}
		}
	});
var $sporto$qs$QS$set = F3(
	function (key, value, query) {
		return A3($elm$core$Dict$insert, key, value, query);
	});
var $sporto$qs$QS$addListValToQuery = F4(
	function (cfg, key, rawValue, query) {
		var currentVals = A2($sporto$qs$QS$get, key, query);
		var pushValue = function (value) {
			if (currentVals.$ === 'Just') {
				if (currentVals.a.$ === 'Many') {
					var vals = currentVals.a.a;
					return $sporto$qs$QS$Many(
						A2(
							$elm$core$List$append,
							vals,
							_List_fromArray(
								[value])));
				} else {
					var preValue = currentVals.a.a;
					return $sporto$qs$QS$Many(
						_List_fromArray(
							[preValue, value]));
				}
			} else {
				return $sporto$qs$QS$Many(
					_List_fromArray(
						[value]));
			}
		};
		var _v0 = A2($sporto$qs$QS$rawValueToValue, cfg, rawValue);
		if (_v0.$ === 'Nothing') {
			return query;
		} else {
			var value = _v0.a;
			return A3(
				$sporto$qs$QS$set,
				key,
				pushValue(value),
				query);
		}
	});
var $sporto$qs$QS$One = function (a) {
	return {$: 'One', a: a};
};
var $sporto$qs$QS$addUniqueValToQuery = F4(
	function (cfg, key, val, query) {
		var _v0 = A2($sporto$qs$QS$rawValueToValue, cfg, val);
		if (_v0.$ === 'Nothing') {
			return query;
		} else {
			var value = _v0.a;
			return A3(
				$sporto$qs$QS$set,
				key,
				$sporto$qs$QS$One(value),
				query);
		}
	});
var $elm$core$String$endsWith = _String_endsWith;
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $sporto$qs$QS$querySegmentToTuple = function (element) {
	var splitted = A2($elm$core$String$split, '=', element);
	var second = A2(
		$elm$core$Maybe$withDefault,
		'',
		$elm$core$List$head(
			A2($elm$core$List$drop, 1, splitted)));
	var secondDecoded = A2(
		$elm$core$Maybe$withDefault,
		'',
		$elm$url$Url$percentDecode(second));
	var first = A2(
		$elm$core$Maybe$withDefault,
		'',
		$elm$core$List$head(splitted));
	var firstDecoded = A2(
		$elm$core$Maybe$withDefault,
		'',
		$elm$url$Url$percentDecode(first));
	return _Utils_Tuple2(firstDecoded, secondDecoded);
};
var $sporto$qs$QS$addSegmentToQuery = F3(
	function (cfg, segment, query) {
		var _v0 = $sporto$qs$QS$querySegmentToTuple(segment);
		var key = _v0.a;
		var val = _v0.b;
		if (A2($elm$core$String$endsWith, '[]', key)) {
			var newKey = A2($elm$core$String$dropRight, 2, key);
			return A4($sporto$qs$QS$addListValToQuery, cfg, newKey, val, query);
		} else {
			return A4($sporto$qs$QS$addUniqueValToQuery, cfg, key, val, query);
		}
	});
var $sporto$qs$QS$empty = $elm$core$Dict$empty;
var $sporto$qs$QS$parse = F2(
	function (_v0, queryString) {
		var cfg = _v0.a;
		var trimmed = A2(
			$elm$core$String$join,
			'',
			A2($elm$core$String$split, '?', queryString));
		return $elm$core$String$isEmpty(trimmed) ? $sporto$qs$QS$empty : A3(
			$elm$core$List$foldl,
			$sporto$qs$QS$addSegmentToQuery(cfg),
			$sporto$qs$QS$empty,
			A2($elm$core$String$split, '&', trimmed));
	});
var $elm$core$Platform$Cmd$map = _Platform_map;
var $author$project$Main$updateWith = F3(
	function (toModel, toMsg, _v0) {
		var subModel = _v0.a;
		var subCmd = _v0.b;
		return _Utils_Tuple2(
			toModel(subModel),
			A2($elm$core$Platform$Cmd$map, toMsg, subCmd));
	});
var $author$project$Main$initWithFlags = function (flags) {
	var parseDomain = function () {
		var _v4 = A2($elm$core$String$split, '.', flags.domain);
		_v4$6:
		while (true) {
			if (_v4.b) {
				if (_v4.b.b) {
					switch (_v4.b.a) {
						case 'localhost':
							var client = _v4.a;
							var _v5 = _v4.b;
							return $elm$core$Maybe$Just(
								_Utils_Tuple2(client, $author$project$Utils$Local));
						case 'dev':
							var client = _v4.a;
							var _v6 = _v4.b;
							return $elm$core$Maybe$Just(
								_Utils_Tuple2(client, $author$project$Utils$Dev));
						case 'staging':
							var client = _v4.a;
							var _v9 = _v4.b;
							return $elm$core$Maybe$Just(
								_Utils_Tuple2(client, $author$project$Utils$Staging));
						case 'call':
							if (_v4.b.b.b) {
								switch (_v4.b.b.a) {
									case 'dev':
										var client = _v4.a;
										var _v7 = _v4.b;
										var _v8 = _v7.b;
										return $elm$core$Maybe$Just(
											_Utils_Tuple2(client, $author$project$Utils$DevV2));
									case 'staging':
										var client = _v4.a;
										var _v10 = _v4.b;
										var _v11 = _v10.b;
										return $elm$core$Maybe$Just(
											_Utils_Tuple2(client, $author$project$Utils$StagingV2));
									case 'prod':
										var client = _v4.a;
										var _v12 = _v4.b;
										var _v13 = _v12.b;
										return $elm$core$Maybe$Just(
											_Utils_Tuple2(client, $author$project$Utils$ProductionV2));
									default:
										break _v4$6;
								}
							} else {
								break _v4$6;
							}
						default:
							break _v4$6;
					}
				} else {
					break _v4$6;
				}
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}
		var client = _v4.a;
		return $elm$core$Maybe$Just(
			_Utils_Tuple2(client, $author$project$Utils$Production));
	}();
	var params = A2($sporto$qs$QS$parse, $sporto$qs$QS$config, flags.query);
	var referralCode = function () {
		var _v3 = A2($elm$core$Dict$get, 'ref', params);
		if (((_v3.$ === 'Just') && (_v3.a.$ === 'One')) && (_v3.a.a.$ === 'Str')) {
			var code = _v3.a.a.a;
			return $elm$core$Maybe$Just(code);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	}();
	var _v0 = _Utils_Tuple3(
		parseDomain,
		A2($elm$core$Dict$get, 't', params),
		A2($elm$core$Dict$get, 'a', params));
	if ((((_v0.a.$ === 'Just') && (_v0.b.$ === 'Just')) && (_v0.b.a.$ === 'One')) && (_v0.b.a.a.$ === 'Str')) {
		var _v1 = _v0.a.a;
		var client = _v1.a;
		var devFlag = _v1.b;
		var token = _v0.b.a.a.a;
		var appParam = _v0.c;
		if ((((appParam.$ === 'Just') && (appParam.a.$ === 'One')) && (appParam.a.a.$ === 'Str')) && (appParam.a.a.a === 'approve')) {
			return A3(
				$author$project$Main$updateWith,
				$author$project$Main$ClickToCall,
				$author$project$Main$GotClickToCallMsg,
				A3($author$project$ClickToCall$State$init, client, token, devFlag));
		} else {
			return A3(
				$author$project$Main$updateWith,
				$author$project$Main$Dialer,
				$author$project$Main$GotDialerMsg,
				$author$project$Dialer$State$init(client)(token)(devFlag)(flags.phone)(flags.name)(flags.email)(referralCode)(flags.lastMedium)(flags.currentTimeMillis)(''));
		}
	} else {
		return _Utils_Tuple2($author$project$Main$IncorrectParameters, $elm$core$Platform$Cmd$none);
	}
};
var $author$project$Main$init = function (flags) {
	var _v0 = $author$project$InteropPorts$decodeFlags(flags);
	if (_v0.$ === 'Err') {
		return _Utils_Tuple2($author$project$Main$IncorrectParameters, $elm$core$Platform$Cmd$none);
	} else {
		var decodedFlags = _v0.a;
		return $author$project$Main$initWithFlags(decodedFlags);
	}
};
var $elm$core$Platform$Sub$map = _Platform_map;
var $author$project$Dialer$Types$MinuteTick = function (a) {
	return {$: 'MinuteTick', a: a};
};
var $author$project$Dialer$Types$ReceiveEventFailed = function (a) {
	return {$: 'ReceiveEventFailed', a: a};
};
var $author$project$Dialer$Types$ReceivedEvent = function (a) {
	return {$: 'ReceivedEvent', a: a};
};
var $author$project$Dialer$Types$Tick = function (a) {
	return {$: 'Tick', a: a};
};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 'Every', a: a, b: b};
	});
var $elm$time$Time$State = F2(
	function (taggers, processes) {
		return {processes: processes, taggers: taggers};
	});
var $elm$time$Time$init = $elm$core$Task$succeed(
	A2($elm$time$Time$State, $elm$core$Dict$empty, $elm$core$Dict$empty));
var $elm$time$Time$addMySub = F2(
	function (_v0, state) {
		var interval = _v0.a;
		var tagger = _v0.b;
		var _v1 = A2($elm$core$Dict$get, interval, state);
		if (_v1.$ === 'Nothing') {
			return A3(
				$elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _v1.a;
			return A3(
				$elm$core$Dict$insert,
				interval,
				A2($elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === 'RBEmpty_elm_builtin') {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$time$Time$setInterval = _Time_setInterval;
var $elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return $elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = $elm$core$Process$spawn(
				A2(
					$elm$time$Time$setInterval,
					interval,
					A2($elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					$elm$time$Time$spawnHelp,
					router,
					rest,
					A3($elm$core$Dict$insert, interval, id, processes));
			};
			return A2($elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var $elm$time$Time$onEffects = F3(
	function (router, subs, _v0) {
		var processes = _v0.processes;
		var rightStep = F3(
			function (_v6, id, _v7) {
				var spawns = _v7.a;
				var existing = _v7.b;
				var kills = _v7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						$elm$core$Task$andThen,
						function (_v5) {
							return kills;
						},
						$elm$core$Process$kill(id)));
			});
		var newTaggers = A3($elm$core$List$foldl, $elm$time$Time$addMySub, $elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _v4) {
				var spawns = _v4.a;
				var existing = _v4.b;
				var kills = _v4.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _v3) {
				var spawns = _v3.a;
				var existing = _v3.b;
				var kills = _v3.c;
				return _Utils_Tuple3(
					spawns,
					A3($elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _v1 = A6(
			$elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				$elm$core$Dict$empty,
				$elm$core$Task$succeed(_Utils_Tuple0)));
		var spawnList = _v1.a;
		var existingDict = _v1.b;
		var killTask = _v1.c;
		return A2(
			$elm$core$Task$andThen,
			function (newProcesses) {
				return $elm$core$Task$succeed(
					A2($elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var $elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _v0 = A2($elm$core$Dict$get, interval, state.taggers);
		if (_v0.$ === 'Nothing') {
			return $elm$core$Task$succeed(state);
		} else {
			var taggers = _v0.a;
			var tellTaggers = function (time) {
				return $elm$core$Task$sequence(
					A2(
						$elm$core$List$map,
						function (tagger) {
							return A2(
								$elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$succeed(state);
				},
				A2($elm$core$Task$andThen, tellTaggers, $elm$time$Time$now));
		}
	});
var $elm$time$Time$subMap = F2(
	function (f, _v0) {
		var interval = _v0.a;
		var tagger = _v0.b;
		return A2(
			$elm$time$Time$Every,
			interval,
			A2($elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager($elm$time$Time$init, $elm$time$Time$onEffects, $elm$time$Time$onSelfMsg, 0, $elm$time$Time$subMap);
var $elm$time$Time$subscription = _Platform_leaf('Time');
var $elm$time$Time$every = F2(
	function (interval, tagger) {
		return $elm$time$Time$subscription(
			A2($elm$time$Time$Every, interval, tagger));
	});
var $author$project$InteropPorts$interopToElm = _Platform_incomingPort('interopToElm', $elm$json$Json$Decode$value);
var $author$project$InteropPorts$toElm = $author$project$InteropPorts$interopToElm(
	$elm$json$Json$Decode$decodeValue(
		$dillonkearns$elm_ts_json$TsJson$Decode$decoder($author$project$InteropDefinitions$interop.toElm)));
var $author$project$Dialer$Ports$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			A2(
			$elm$core$Platform$Sub$map,
			function (result) {
				if (result.$ === 'Ok') {
					var toElm = result.a;
					return $author$project$Dialer$Types$ReceivedEvent(toElm);
				} else {
					var err = result.a;
					return $author$project$Dialer$Types$ReceiveEventFailed(
						$elm$json$Json$Decode$errorToString(err));
				}
			},
			$author$project$InteropPorts$toElm),
			A2($elm$time$Time$every, 15000, $author$project$Dialer$Types$Tick),
			A2($elm$time$Time$every, 60000, $author$project$Dialer$Types$MinuteTick)
		]));
var $author$project$Dialer$State$subscriptions = $author$project$Dialer$Ports$subscriptions;
var $author$project$Main$subscriptions = function (_v0) {
	return A2($elm$core$Platform$Sub$map, $author$project$Main$GotDialerMsg, $author$project$Dialer$State$subscriptions);
};
var $author$project$ClickToCall$Types$ApprovalsLoaded = {$: 'ApprovalsLoaded'};
var $author$project$ClickToCall$Types$ApprovalsNetworkError = {$: 'ApprovalsNetworkError'};
var $author$project$ClickToCall$Types$AssignmentsLoaded = {$: 'AssignmentsLoaded'};
var $author$project$ClickToCall$Types$AssignmentsNetworkError = {$: 'AssignmentsNetworkError'};
var $author$project$ClickToCall$Types$LoadingAssignments = {$: 'LoadingAssignments'};
var $author$project$ClickToCall$Types$NoMatchingCampaignForToken = {$: 'NoMatchingCampaignForToken'};
var $elm$core$Result$toMaybe = function (result) {
	if (result.$ === 'Ok') {
		var v = result.a;
		return $elm$core$Maybe$Just(v);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Utils$graphqlErrorCodes = function (errors) {
	var errorCodeFromExtensions = function (extensions) {
		return $elm$core$Result$toMaybe(
			A2(
				$elm$json$Json$Decode$decodeValue,
				A2($elm$json$Json$Decode$field, 'code', $elm$json$Json$Decode$string),
				extensions));
	};
	var errorCode = function (error) {
		return A2(
			$elm$core$Maybe$andThen,
			errorCodeFromExtensions,
			A2($elm$core$Dict$get, 'extensions', error.details));
	};
	return A2($elm$core$List$filterMap, errorCode, errors);
};
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $author$project$Utils$graphqlErrorHasCode = F2(
	function (code, errors) {
		return A2(
			$elm$core$List$member,
			code,
			$author$project$Utils$graphqlErrorCodes(errors));
	});
var $author$project$ClickToCall$Types$AssignmentAPIResponse = function (a) {
	return {$: 'AssignmentAPIResponse', a: a};
};
var $author$project$Api$Mutation$getApprovalAssignment = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'getApprovalAssignment',
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'token', requiredArgs____.token, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)
				]),
			object____,
			$elm$core$Basics$identity);
	});
var $dillonkearns$elm_graphql$Graphql$Http$Mutation = function (a) {
	return {$: 'Mutation', a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$mutationRequest = F2(
	function (baseUrl, mutationSelectionSet) {
		return $dillonkearns$elm_graphql$Graphql$Http$Request(
			{
				baseUrl: baseUrl,
				details: $dillonkearns$elm_graphql$Graphql$Http$Mutation(mutationSelectionSet),
				expect: $dillonkearns$elm_graphql$Graphql$Document$decoder(mutationSelectionSet),
				headers: _List_Nil,
				operationName: $elm$core$Maybe$Nothing,
				queryParams: _List_Nil,
				timeout: $elm$core$Maybe$Nothing,
				withCredentials: false
			});
	});
var $author$project$ClickToCall$Types$CalleesMutationResult = F2(
	function (batchId, calleeIds) {
		return {batchId: batchId, calleeIds: calleeIds};
	});
var $author$project$Api$Object$ApprovalAssignment$batchId = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'batchId',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Api$Object$ApprovalAssignment$calleeIds = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(List String)',
	'calleeIds',
	_List_Nil,
	$elm$json$Json$Decode$list($elm$json$Json$Decode$string));
var $author$project$ClickToCall$Api$selectApprovalAssignment = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Api$Object$ApprovalAssignment$calleeIds,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Api$Object$ApprovalAssignment$batchId,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$ClickToCall$Types$CalleesMutationResult)));
var $author$project$ClickToCall$Api$loadCalleesToApprove = F3(
	function (env, client, token) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Http$send,
			$author$project$ClickToCall$Types$AssignmentAPIResponse,
			A2(
				$author$project$ClickToCall$Api$setTenantHeader,
				client,
				A2(
					$dillonkearns$elm_graphql$Graphql$Http$mutationRequest,
					A2($author$project$ClickToCall$Api$gqlBaseUrl, env, client),
					A2(
						$author$project$Api$Mutation$getApprovalAssignment,
						{token: token},
						$author$project$ClickToCall$Api$selectApprovalAssignment))));
	});
var $author$project$ClickToCall$Types$UploadingApprovals = {$: 'UploadingApprovals'};
var $author$project$ClickToCall$Types$ApprovalAPIResponse = function (a) {
	return {$: 'ApprovalAPIResponse', a: a};
};
var $author$project$Api$Mutation$approveCallees = function (requiredArgs____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
		'Bool',
		'approveCallees',
		_List_fromArray(
			[
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'token', requiredArgs____.token, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string),
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'batchId', requiredArgs____.batchId, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)
			]),
		$elm$json$Json$Decode$bool);
};
var $author$project$ClickToCall$Api$uploadApprovalsPost = F4(
	function (env, client, token, batchId) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Http$send,
			$author$project$ClickToCall$Types$ApprovalAPIResponse,
			A2(
				$author$project$ClickToCall$Api$setTenantHeader,
				client,
				A2(
					$dillonkearns$elm_graphql$Graphql$Http$mutationRequest,
					A2($author$project$ClickToCall$Api$gqlBaseUrl, env, client),
					$author$project$Api$Mutation$approveCallees(
						{batchId: batchId, token: token}))));
	});
var $author$project$ClickToCall$State$uploadApprovals = F2(
	function (model, batchId) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{state: $author$project$ClickToCall$Types$UploadingApprovals}),
			A4($author$project$ClickToCall$Api$uploadApprovalsPost, model.env, model.client, model.token, batchId));
	});
var $author$project$ClickToCall$State$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 'CheckForCalleesNeedingApproval':
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{state: $author$project$ClickToCall$Types$LoadingAssignments}),
					A3($author$project$ClickToCall$Api$loadCalleesToApprove, model.env, model.client, model.token));
			case 'AssignmentAPIResponse':
				var result = msg.a;
				if (result.$ === 'Ok') {
					var data = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{approved: _List_Nil, assigned: data.calleeIds, batchId: data.batchId, state: $author$project$ClickToCall$Types$AssignmentsLoaded}),
						$elm$core$Platform$Cmd$none);
				} else {
					if (result.a.$ === 'GraphqlError') {
						var _v2 = result.a;
						var errors = _v2.b;
						return A2($author$project$Utils$graphqlErrorHasCode, 'NOT_FOUND', errors) ? _Utils_Tuple2(
							_Utils_update(
								model,
								{state: $author$project$ClickToCall$Types$NoMatchingCampaignForToken}),
							$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
							_Utils_update(
								model,
								{state: $author$project$ClickToCall$Types$AssignmentsNetworkError}),
							$elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{state: $author$project$ClickToCall$Types$AssignmentsNetworkError}),
							$elm$core$Platform$Cmd$none);
					}
				}
			case 'ApproveNextCallee':
				var _v3 = _Utils_Tuple2(model.assigned, model.batchId);
				if (_v3.a.b && (_v3.b.$ === 'Just')) {
					if (!_v3.a.b.b) {
						var _v4 = _v3.a;
						var approvedCallee = _v4.a;
						var batchId = _v3.b.a;
						return A2(
							$author$project$ClickToCall$State$uploadApprovals,
							_Utils_update(
								model,
								{
									approved: A2(
										$elm$core$List$append,
										model.approved,
										_List_fromArray(
											[approvedCallee])),
									assigned: _List_Nil
								}),
							batchId);
					} else {
						var _v5 = _v3.a;
						var approvedCallee = _v5.a;
						var rest = _v5.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									approved: A2(
										$elm$core$List$append,
										model.approved,
										_List_fromArray(
											[approvedCallee])),
									assigned: rest
								}),
							$elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'UploadApprovals':
				var _v6 = model.batchId;
				if (_v6.$ === 'Just') {
					var id = _v6.a;
					return A2($author$project$ClickToCall$State$uploadApprovals, model, id);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'ApprovalAPIResponse':
				var result = msg.a;
				if (result.$ === 'Ok') {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{approved: _List_Nil, assigned: _List_Nil, state: $author$project$ClickToCall$Types$ApprovalsLoaded}),
						$elm$core$Platform$Cmd$none);
				} else {
					if (result.a.$ === 'GraphqlError') {
						var _v8 = result.a;
						var errors = _v8.b;
						return A2($author$project$Utils$graphqlErrorHasCode, 'NOT_FOUND', errors) ? _Utils_Tuple2(
							_Utils_update(
								model,
								{state: $author$project$ClickToCall$Types$NoMatchingCampaignForToken}),
							$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
							_Utils_update(
								model,
								{state: $author$project$ClickToCall$Types$AssignmentsNetworkError}),
							$elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{state: $author$project$ClickToCall$Types$ApprovalsNetworkError}),
							$elm$core$Platform$Cmd$none);
					}
				}
			default:
				var result = msg.a;
				if (result.$ === 'Ok') {
					var campaign = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								campaignName: $elm$core$Maybe$Just(campaign.name)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$InteropDefinitions$BreadcrumbPortMessage = function (a) {
	return {$: 'BreadcrumbPortMessage', a: a};
};
var $dillonkearns$elm_ts_json$TsJson$Encode$encoder = F2(
	function (_v0, input) {
		var encodeFn = _v0.a;
		return encodeFn(input);
	});
var $author$project$InteropPorts$interopFromElm = _Platform_outgoingPort('interopFromElm', $elm$core$Basics$identity);
var $author$project$InteropPorts$fromElm = function (value) {
	return $author$project$InteropPorts$interopFromElm(
		A3($elm$core$Basics$apR, $author$project$InteropDefinitions$interop.fromElm, $dillonkearns$elm_ts_json$TsJson$Encode$encoder, value));
};
var $author$project$Utils$gqlErrorMessage = function (error) {
	if (error.$ === 'GraphqlError') {
		var gqlErrors = error.b;
		var _v1 = $elm$core$List$head(gqlErrors);
		if (_v1.$ === 'Just') {
			var gqlError = _v1.a;
			return gqlError.message;
		} else {
			return 'Connection error: Unknown GQL error';
		}
	} else {
		switch (error.a.$) {
			case 'BadUrl':
				var badUrl = error.a.a;
				return 'Connection error: invalid url ' + badUrl;
			case 'Timeout':
				var _v2 = error.a;
				return 'Timeout while connecting';
			case 'NetworkError':
				var _v3 = error.a;
				return 'Network connection lost';
			case 'BadStatus':
				var _v4 = error.a;
				var status = _v4.b;
				return 'Connection Error: unexpected response code from server ' + status;
			default:
				return 'Connection Error: unable to parse response from the server';
		}
	}
};
var $elm$core$Debug$toString = _Debug_toString;
var $author$project$Dialer$Ports$summarizeCallStateForDebugging = function (callState) {
	var summarizeSurveyResponse = function (response) {
		switch (response.$) {
			case 'SingleChoiceQuestionResponse':
				var question = response.a;
				var answer = response.b;
				return _Utils_Tuple2(question.name, answer.value);
			case 'MultipleChoiceQuestionResponse':
				var question = response.a;
				var answers = response.b;
				return _Utils_Tuple2(
					question.name,
					A2(
						$elm$core$String$join,
						', ',
						A2(
							$elm$core$List$map,
							function (a) {
								return a.value;
							},
							answers)));
			case 'TextFieldQuestionResponse':
				var question = response.a;
				var answer = response.b;
				return _Utils_Tuple2(question.name, answer);
			case 'PollingPlaceLookupQuestionResponse':
				var question = response.a;
				var maybeAddress = response.b;
				return _Utils_Tuple2(
					question.name,
					function () {
						if (maybeAddress.$ === 'Just') {
							var address = maybeAddress.a;
							return $elm$core$String$concat(
								_List_fromArray(
									['address: ', address.address, ', zip: ', address.zip]));
						} else {
							return 'No address given';
						}
					}());
			default:
				var question = response.a;
				return _Utils_Tuple2(question.name, '(null)');
		}
	};
	return $elm$core$Debug$toString(
		_Utils_Tuple3(
			_Utils_Tuple2(callState.callUUID, callState.callID),
			_Utils_Tuple3(
				callState.ended,
				callState.nextQuestion,
				A2($elm$core$List$map, summarizeSurveyResponse, callState.responses)),
			callState.surveyEndedEarlyByUser));
};
var $author$project$Dialer$Ports$summarizeModelForDebugging = function (model) {
	var _v0 = model.state;
	switch (_v0.$) {
		case 'LoadingCampaign':
			return 'LoadingCampaign';
		case 'RefreshCampaign':
			return 'RefreshCampaign';
		case 'NoMatchingCampaignForToken':
			return 'NoMatchingCampaignForToken';
		case 'PromptForCallersPhoneNumberToConnect':
			var callersPhoneNumber = _v0.a.callersPhoneNumber;
			return 'PromptForCallersPhoneNumberToConnect: ' + $elm$core$Debug$toString(callersPhoneNumber);
		case 'PromptToPhoneIn':
			return 'PromptToPhoneIn';
		case 'SubmittingPhoneNumber':
			var callersPhoneNumber = _v0.a.callersPhoneNumber;
			return 'SubmittingPhoneNumber: ' + $elm$core$Debug$toString(callersPhoneNumber);
		case 'ReadyToPhoneIn':
			var callersPhoneNumber = _v0.a.callersPhoneNumber;
			return 'ReadyToPhoneIn: ' + $elm$core$Debug$toString(callersPhoneNumber);
		case 'CampaignReady':
			return 'CampaignReady';
		case 'ConfirmLastCallInMethod':
			var lastMedium = _v0.a.lastMedium;
			return 'ConfirmLastCallInMethod: ' + lastMedium;
		case 'ScriptPreview':
			return 'ScriptPreview';
		case 'DialerInterfaceLoaded':
			return 'DialerInterfaceLoaded';
		case 'DialerInterfaceConnected':
			return 'DialerInterfaceConnected';
		case 'PhoneDialerInterfaceConnected':
			return 'PhoneDialerInterfaceConnected';
		case 'EnteringQueue':
			var callUUID = _v0.a.callUUID;
			return 'EnteringQueue: ' + $elm$core$Debug$toString(callUUID);
		case 'InQueue':
			var callUUID = _v0.a.callUUID;
			return 'InQueue: ' + $elm$core$Debug$toString(
				_Utils_Tuple2(callUUID, model.jokeIndex));
		case 'EndingConference':
			var callState = _v0.a;
			return 'EndingConference: ' + $author$project$Dialer$Ports$summarizeCallStateForDebugging(callState);
		case 'SubmittingSurveyResponse':
			var callState = _v0.a;
			return 'SubmittingSurveyResponse: ' + $author$project$Dialer$Ports$summarizeCallStateForDebugging(callState);
		case 'InCall':
			var callState = _v0.a;
			return 'InCall: ' + $author$project$Dialer$Ports$summarizeCallStateForDebugging(callState);
		case 'CallCompleteAndSurveyComplete':
			var callState = _v0.a;
			return 'CallCompleteAndSurveyComplete: ' + $author$project$Dialer$Ports$summarizeCallStateForDebugging(callState);
		case 'SessionComplete':
			return 'SessionComplete';
		case 'CampaignLoadError':
			var networkError = _v0.a;
			return 'Campaign load network error:' + $author$project$Utils$gqlErrorMessage(networkError);
		case 'MutationError':
			var networkError = _v0.a;
			return 'Mutation network error:' + $author$project$Utils$gqlErrorMessage(networkError);
		case 'ErrorWithFeedback':
			var feedback = _v0.a;
			return 'ErrorWithFeedback:' + feedback;
		default:
			return 'ErrorOccurred';
	}
};
var $author$project$Dialer$Ports$sendBreadCrumbMessage = F3(
	function (msg, model, updatedModel) {
		return $author$project$InteropPorts$fromElm(
			$author$project$InteropDefinitions$BreadcrumbPortMessage(
				{
					currentModel: $author$project$Dialer$Ports$summarizeModelForDebugging(model),
					newModel: $author$project$Dialer$Ports$summarizeModelForDebugging(updatedModel),
					receivedMsg: $elm$core$Debug$toString(msg)
				}));
	});
var $author$project$Dialer$Types$CallWithPhoneClicked = {$: 'CallWithPhoneClicked'};
var $author$project$Dialer$Types$CampaignLoadError = function (a) {
	return {$: 'CampaignLoadError', a: a};
};
var $author$project$Dialer$Types$CampaignReady = function (a) {
	return {$: 'CampaignReady', a: a};
};
var $author$project$Dialer$Types$ConfirmLastCallInMethod = function (a) {
	return {$: 'ConfirmLastCallInMethod', a: a};
};
var $author$project$Dialer$Types$DialerInterfaceConnected = function (a) {
	return {$: 'DialerInterfaceConnected', a: a};
};
var $author$project$Dialer$Types$DialerInterfaceLoaded = function (a) {
	return {$: 'DialerInterfaceLoaded', a: a};
};
var $author$project$Dialer$Types$EndingConference = function (a) {
	return {$: 'EndingConference', a: a};
};
var $author$project$Dialer$Types$EnteringQueue = function (a) {
	return {$: 'EnteringQueue', a: a};
};
var $author$project$Dialer$Types$ErrorOccurred = {$: 'ErrorOccurred'};
var $author$project$Dialer$Types$ErrorWithFeedback = function (a) {
	return {$: 'ErrorWithFeedback', a: a};
};
var $author$project$Dialer$Types$InCall = function (a) {
	return {$: 'InCall', a: a};
};
var $author$project$Dialer$Types$InQueue = function (a) {
	return {$: 'InQueue', a: a};
};
var $author$project$InteropDefinitions$IssuePortMessage = function (a) {
	return {$: 'IssuePortMessage', a: a};
};
var $author$project$Dialer$Types$MutationError = function (a) {
	return {$: 'MutationError', a: a};
};
var $author$project$Dialer$Types$NoMatchingCampaignForToken = {$: 'NoMatchingCampaignForToken'};
var $author$project$Dialer$Types$PollingPlaceLookupAddress = F2(
	function (address, zip) {
		return {address: address, zip: zip};
	});
var $author$project$Dialer$Types$PromptForCallersPhoneNumberToConnect = function (a) {
	return {$: 'PromptForCallersPhoneNumberToConnect', a: a};
};
var $author$project$Dialer$Types$PromptToPhoneIn = function (a) {
	return {$: 'PromptToPhoneIn', a: a};
};
var $author$project$Dialer$Types$ReadyToPhoneIn = function (a) {
	return {$: 'ReadyToPhoneIn', a: a};
};
var $author$project$InteropDefinitions$ReloadPortMessage = function (a) {
	return {$: 'ReloadPortMessage', a: a};
};
var $author$project$InteropDefinitions$ResetPortMessage = function (a) {
	return {$: 'ResetPortMessage', a: a};
};
var $author$project$Dialer$Types$SessionComplete = {$: 'SessionComplete'};
var $author$project$Dialer$Types$SubmittingPhoneNumber = function (a) {
	return {$: 'SubmittingPhoneNumber', a: a};
};
var $author$project$Dialer$Types$RefreshCampaign = function (a) {
	return {$: 'RefreshCampaign', a: a};
};
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 'Bad', a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 'Good', a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = function (a) {
	return {$: 'Parser', a: a};
};
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0.a;
		return $elm$parser$Parser$Advanced$Parser(
			function (s0) {
				var _v1 = parseA(s0);
				if (_v1.$ === 'Bad') {
					var p = _v1.a;
					var x = _v1.b;
					return A2($elm$parser$Parser$Advanced$Bad, p, x);
				} else {
					var p1 = _v1.a;
					var a = _v1.b;
					var s1 = _v1.c;
					var _v2 = callback(a);
					var parseB = _v2.a;
					var _v3 = parseB(s1);
					if (_v3.$ === 'Bad') {
						var p2 = _v3.a;
						var x = _v3.b;
						return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
					} else {
						var p2 = _v3.a;
						var b = _v3.b;
						var s2 = _v3.c;
						return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
					}
				}
			});
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$ExpectingEnd = {$: 'ExpectingEnd'};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 'AddRight', a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {col: col, contextStack: contextStack, problem: problem, row: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 'Empty'};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.row, s.col, x, s.context));
	});
var $elm$parser$Parser$Advanced$end = function (x) {
	return $elm$parser$Parser$Advanced$Parser(
		function (s) {
			return _Utils_eq(
				$elm$core$String$length(s.src),
				s.offset) ? A3($elm$parser$Parser$Advanced$Good, false, _Utils_Tuple0, s) : A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, x));
		});
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.src);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.offset, offset) < 0,
					_Utils_Tuple0,
					{col: col, context: s0.context, indent: s0.indent, offset: offset, row: row, src: s0.src});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return $elm$parser$Parser$Advanced$Parser(
		function (s) {
			return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.offset, s.row, s.col, s);
		});
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0.a;
		return $elm$parser$Parser$Advanced$Parser(
			function (s0) {
				var _v1 = parse(s0);
				if (_v1.$ === 'Bad') {
					var p = _v1.a;
					var x = _v1.b;
					return A2($elm$parser$Parser$Advanced$Bad, p, x);
				} else {
					var p = _v1.a;
					var a = _v1.b;
					var s1 = _v1.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p,
						A2(
							func,
							A3($elm$core$String$slice, s0.offset, s1.offset, s0.src),
							a),
						s1);
				}
			});
	});
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $elm$parser$Parser$Problem = function (a) {
	return {$: 'Problem', a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return $elm$parser$Parser$Advanced$Parser(
		function (s) {
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, x));
		});
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $elm$core$Basics$round = _Basics_round;
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return $elm$parser$Parser$Advanced$Parser(
		function (s) {
			return A3($elm$parser$Parser$Advanced$Good, false, a, s);
		});
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs = A2(
	$elm$parser$Parser$andThen,
	function (str) {
		if ($elm$core$String$length(str) <= 9) {
			var _v0 = $elm$core$String$toFloat('0.' + str);
			if (_v0.$ === 'Just') {
				var floatVal = _v0.a;
				return $elm$parser$Parser$succeed(
					$elm$core$Basics$round(floatVal * 1000));
			} else {
				return $elm$parser$Parser$problem('Invalid float: \"' + (str + '\"'));
			}
		} else {
			return $elm$parser$Parser$problem(
				'Expected at most 9 digits, but got ' + $elm$core$String$fromInt(
					$elm$core$String$length(str)));
		}
	},
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompWhile($elm$core$Char$isDigit)));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts = F6(
	function (monthYearDayMs, hour, minute, second, ms, utcOffsetMinutes) {
		return $elm$time$Time$millisToPosix((((monthYearDayMs + (((hour * 60) * 60) * 1000)) + (((minute - utcOffsetMinutes) * 60) * 1000)) + (second * 1000)) + ms);
	});
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0.a;
		var parseB = _v1.a;
		return $elm$parser$Parser$Advanced$Parser(
			function (s0) {
				var _v2 = parseA(s0);
				if (_v2.$ === 'Bad') {
					var p = _v2.a;
					var x = _v2.b;
					return A2($elm$parser$Parser$Advanced$Bad, p, x);
				} else {
					var p1 = _v2.a;
					var a = _v2.b;
					var s1 = _v2.c;
					var _v3 = parseB(s1);
					if (_v3.$ === 'Bad') {
						var p2 = _v3.a;
						var x = _v3.b;
						return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
					} else {
						var p2 = _v3.a;
						var b = _v3.b;
						var s2 = _v3.c;
						return A3(
							$elm$parser$Parser$Advanced$Good,
							p1 || p2,
							A2(func, a, b),
							s2);
					}
				}
			});
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 'Append', a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (_v1.$ === 'Good') {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return $elm$parser$Parser$Advanced$Parser(
		function (s) {
			return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
		});
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $elm$parser$Parser$Done = function (a) {
	return {$: 'Done', a: a};
};
var $elm$parser$Parser$Loop = function (a) {
	return {$: 'Loop', a: a};
};
var $elm$core$String$append = _String_append;
var $elm$parser$Parser$UnexpectedChar = {$: 'UnexpectedChar'};
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return $elm$parser$Parser$Advanced$Parser(
			function (s) {
				var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.offset, s.src);
				return _Utils_eq(newOffset, -1) ? A2(
					$elm$parser$Parser$Advanced$Bad,
					false,
					A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
					$elm$parser$Parser$Advanced$Good,
					true,
					_Utils_Tuple0,
					{col: 1, context: s.context, indent: s.indent, offset: s.offset + 1, row: s.row + 1, src: s.src}) : A3(
					$elm$parser$Parser$Advanced$Good,
					true,
					_Utils_Tuple0,
					{col: s.col + 1, context: s.context, indent: s.indent, offset: newOffset, row: s.row, src: s.src}));
			});
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0.a;
			var _v1 = parse(s0);
			if (_v1.$ === 'Good') {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (step.$ === 'Loop') {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return $elm$parser$Parser$Advanced$Parser(
			function (s) {
				return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
			});
	});
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0.a;
		return $elm$parser$Parser$Advanced$Parser(
			function (s0) {
				var _v1 = parse(s0);
				if (_v1.$ === 'Good') {
					var p = _v1.a;
					var a = _v1.b;
					var s1 = _v1.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p,
						func(a),
						s1);
				} else {
					var p = _v1.a;
					var x = _v1.b;
					return A2($elm$parser$Parser$Advanced$Bad, p, x);
				}
			});
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 'Done', a: a};
};
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 'Loop', a: a};
};
var $elm$parser$Parser$toAdvancedStep = function (step) {
	if (step.$ === 'Loop') {
		var s = step.a;
		return $elm$parser$Parser$Advanced$Loop(s);
	} else {
		var a = step.a;
		return $elm$parser$Parser$Advanced$Done(a);
	}
};
var $elm$parser$Parser$loop = F2(
	function (state, callback) {
		return A2(
			$elm$parser$Parser$Advanced$loop,
			state,
			function (s) {
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$toAdvancedStep,
					callback(s));
			});
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt = function (quantity) {
	var helper = function (str) {
		if (_Utils_eq(
			$elm$core$String$length(str),
			quantity)) {
			var _v0 = $elm$core$String$toInt(str);
			if (_v0.$ === 'Just') {
				var intVal = _v0.a;
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$Done,
					$elm$parser$Parser$succeed(intVal));
			} else {
				return $elm$parser$Parser$problem('Invalid integer: \"' + (str + '\"'));
			}
		} else {
			return A2(
				$elm$parser$Parser$map,
				function (nextChar) {
					return $elm$parser$Parser$Loop(
						A2($elm$core$String$append, str, nextChar));
				},
				$elm$parser$Parser$getChompedString(
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
		}
	};
	return A2($elm$parser$Parser$loop, '', helper);
};
var $elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 'ExpectingSymbol', a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 'Token', a: a, b: b};
	});
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$core$Basics$not = _Basics_not;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return $elm$parser$Parser$Advanced$Parser(
		function (s) {
			var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.offset, s.row, s.col, s.src);
			var newOffset = _v1.a;
			var newRow = _v1.b;
			var newCol = _v1.c;
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
				$elm$parser$Parser$Advanced$Good,
				progress,
				_Utils_Tuple0,
				{col: newCol, context: s.context, indent: s.indent, offset: newOffset, row: newRow, src: s.src});
		});
};
var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
var $elm$parser$Parser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear = 1970;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay = function (day) {
	return $elm$parser$Parser$problem(
		'Invalid day: ' + $elm$core$String$fromInt(day));
};
var $elm$core$Basics$modBy = _Basics_modBy;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear = function (year) {
	return (!A2($elm$core$Basics$modBy, 4, year)) && ((!(!A2($elm$core$Basics$modBy, 100, year))) || (!A2($elm$core$Basics$modBy, 400, year)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore = function (y1) {
	var y = y1 - 1;
	return (((y / 4) | 0) - ((y / 100) | 0)) + ((y / 400) | 0);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay = 86400000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear = 31536000000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay = function (_v0) {
	var year = _v0.a;
	var month = _v0.b;
	var dayInMonth = _v0.c;
	if (dayInMonth < 0) {
		return $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth);
	} else {
		var succeedWith = function (extraMs) {
			var yearMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear * (year - $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear);
			var days = ((month < 3) || (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year))) ? (dayInMonth - 1) : dayInMonth;
			var dayMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay * (days + ($rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore(year) - $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore($rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear)));
			return $elm$parser$Parser$succeed((extraMs + yearMs) + dayMs);
		};
		switch (month) {
			case 1:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(0);
			case 2:
				return ((dayInMonth > 29) || ((dayInMonth === 29) && (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year)))) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(2678400000);
			case 3:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(5097600000);
			case 4:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(7776000000);
			case 5:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(10368000000);
			case 6:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(13046400000);
			case 7:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(15638400000);
			case 8:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(18316800000);
			case 9:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(20995200000);
			case 10:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(23587200000);
			case 11:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(26265600000);
			case 12:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(28857600000);
			default:
				return $elm$parser$Parser$problem(
					'Invalid month: \"' + ($elm$core$String$fromInt(month) + '\"'));
		}
	}
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs = A2(
	$elm$parser$Parser$andThen,
	$rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay,
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F3(
						function (year, month, day) {
							return _Utils_Tuple3(year, month, day);
						})),
				$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(4)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed($elm$core$Basics$identity),
							$elm$parser$Parser$symbol('-')),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
					]))),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed($elm$core$Basics$identity),
						$elm$parser$Parser$symbol('-')),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
				]))));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes = function () {
	var utcOffsetMinutesFromParts = F3(
		function (multiplier, hours, minutes) {
			return (multiplier * (hours * 60)) + minutes;
		});
	return A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$map,
					function (_v0) {
						return 0;
					},
					$elm$parser$Parser$symbol('Z')),
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed(utcOffsetMinutesFromParts),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$map,
										function (_v1) {
											return 1;
										},
										$elm$parser$Parser$symbol('+')),
										A2(
										$elm$parser$Parser$map,
										function (_v2) {
											return -1;
										},
										$elm$parser$Parser$symbol('-'))
									]))),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$ignorer,
									$elm$parser$Parser$succeed($elm$core$Basics$identity),
									$elm$parser$Parser$symbol(':')),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
								$elm$parser$Parser$succeed(0)
							]))),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(0),
					$elm$parser$Parser$end)
				])));
}();
var $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601 = A2(
	$elm$parser$Parser$andThen,
	function (datePart) {
		return $elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed(
											$rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts(datePart)),
										$elm$parser$Parser$symbol('T')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$elm$parser$Parser$oneOf(
									_List_fromArray(
										[
											A2(
											$elm$parser$Parser$keeper,
											A2(
												$elm$parser$Parser$ignorer,
												$elm$parser$Parser$succeed($elm$core$Basics$identity),
												$elm$parser$Parser$symbol(':')),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
										]))),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$keeper,
										A2(
											$elm$parser$Parser$ignorer,
											$elm$parser$Parser$succeed($elm$core$Basics$identity),
											$elm$parser$Parser$symbol(':')),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
										$elm$parser$Parser$succeed(0)
									]))),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$symbol('.')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs),
									$elm$parser$Parser$succeed(0)
								]))),
					A2($elm$parser$Parser$ignorer, $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes, $elm$parser$Parser$end)),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A6($rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts, datePart, 0, 0, 0, 0, 0)),
					$elm$parser$Parser$end)
				]));
	},
	$rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs);
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {col: col, problem: problem, row: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.row, p.col, p.problem);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 'Empty':
					return list;
				case 'AddRight':
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0.a;
		var _v1 = parse(
			{col: 1, context: _List_Nil, indent: 1, offset: 0, row: 1, src: src});
		if (_v1.$ === 'Good') {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (_v0.$ === 'Ok') {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime = function (str) {
	return A2($elm$parser$Parser$run, $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601, str);
};
var $author$project$Dialer$State$convertToPosix = function (isoString) {
	var _v0 = $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(isoString);
	if (_v0.$ === 'Ok') {
		var time = _v0.a;
		return $elm$time$Time$posixToMillis(time);
	} else {
		return 0;
	}
};
var $author$project$Dialer$State$campaignRequiresUpdate = F2(
	function (campaign, model) {
		return _Utils_cmp(
			$author$project$Dialer$State$convertToPosix(campaign.updated_at),
			$author$project$Dialer$State$convertToPosix(model.campaignLastUpdate.time)) < 0;
	});
var $author$project$Dialer$State$campaignUpdateRequiresReload = function (model) {
	return model.campaignLastUpdate.reloadRequired;
};
var $author$project$Dialer$Types$CallQueueResponse = function (a) {
	return {$: 'CallQueueResponse', a: a};
};
var $author$project$Api$Mutation$placeCallerInCallQueue = function (requiredArgs____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
		'Bool',
		'placeCallerInCallQueue',
		_List_fromArray(
			[
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'token', requiredArgs____.token, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string),
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'callUUID', requiredArgs____.callUUID, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)
			]),
		$elm$json$Json$Decode$bool);
};
var $author$project$Dialer$Api$placeCallerInCallQueueCommand = F4(
	function (env, client, token, callUUID) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Http$send,
			$author$project$Dialer$Types$CallQueueResponse,
			A2(
				$author$project$Dialer$Api$setTenantHeader,
				client,
				A2(
					$dillonkearns$elm_graphql$Graphql$Http$mutationRequest,
					A2($author$project$Dialer$Api$gqlBaseUrl, env, client),
					$author$project$Api$Mutation$placeCallerInCallQueue(
						{callUUID: callUUID, token: token}))));
	});
var $author$project$Dialer$State$checkForUpdatedCampaign = F3(
	function (model, campaign, callUUID) {
		return A2($author$project$Dialer$State$campaignRequiresUpdate, campaign, model) ? ($author$project$Dialer$State$campaignUpdateRequiresReload(model) ? _Utils_Tuple2(
			_Utils_update(
				model,
				{
					state: $author$project$Dialer$Types$ErrorWithFeedback('Campaign update is progress')
				}),
			$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
			_Utils_update(
				model,
				{
					state: $author$project$Dialer$Types$RefreshCampaign(
						{callUUID: callUUID})
				}),
			A3($author$project$Dialer$Api$requestCampaignFromApi, model.env, model.client, model.token))) : _Utils_Tuple2(
			_Utils_update(
				model,
				{
					state: $author$project$Dialer$Types$EnteringQueue(
						{callUUID: callUUID, campaign: campaign})
				}),
			A4($author$project$Dialer$Api$placeCallerInCallQueueCommand, model.env, model.client, model.token, callUUID));
	});
var $elm$core$String$toLower = _String_toLower;
var $author$project$Utils$cleanEmail = A2($elm$core$Basics$composeR, $elm$core$String$toLower, $elm$core$String$trim);
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $author$project$Utils$removeLeadingPlus1 = A2($elm$core$String$replace, '+1', '');
var $author$project$Utils$stripNonDigits = function (string) {
	var regex = A2(
		$elm$core$Maybe$withDefault,
		$elm$regex$Regex$never,
		$elm$regex$Regex$fromString('[^0-9]'));
	return A3(
		$elm$regex$Regex$replace,
		regex,
		function (_v0) {
			return '';
		},
		string);
};
var $author$project$Utils$cleanNumber = A2($elm$core$Basics$composeR, $author$project$Utils$removeLeadingPlus1, $author$project$Utils$stripNonDigits);
var $author$project$InteropDefinitions$ConnectPortMessage = function (a) {
	return {$: 'ConnectPortMessage', a: a};
};
var $author$project$Utils$addCountryCode = F2(
	function (countryIso, phone) {
		var removeLeadingZero = A2(
			$elm$core$Maybe$withDefault,
			$elm$regex$Regex$never,
			$elm$regex$Regex$fromString('^0'));
		if (countryIso === 'AU') {
			return A3(
				$elm$regex$Regex$replace,
				removeLeadingZero,
				function (_v1) {
					return '61';
				},
				phone);
		} else {
			return '1' + phone;
		}
	});
var $author$project$Dialer$Ports$sendConnectPortMessage = F6(
	function (model, campaign, phoneIn, phone, name, email) {
		return $author$project$InteropPorts$fromElm(
			$author$project$InteropDefinitions$ConnectPortMessage(
				{
					base_url: A2($author$project$Utils$baseUrl, model.env, model.client),
					caller_channel_id: model.callerChannelId,
					dial_in: phoneIn,
					email: email,
					endpoint_login: campaign.endpoint_login,
					endpoint_password: campaign.endpoint_password,
					name: name,
					phone: A2($author$project$Utils$addCountryCode, campaign.country_iso, phone),
					provider: campaign.provider,
					pusherConfigs: campaign.pusherConfigs,
					referralCode: model.referralCode,
					tenant: model.client,
					token: model.token,
					twilio_token: campaign.twilio_token
				}));
	});
var $author$project$Dialer$State$connectCommands = F6(
	function (model, campaign, phoneIn, phone, name, email) {
		return A6($author$project$Dialer$Ports$sendConnectPortMessage, model, campaign, phoneIn, phone, name, email);
	});
var $author$project$Dialer$Types$CallState = function (campaign) {
	return function (callUUID) {
		return function (callID) {
			return function (name) {
				return function (calleeData) {
					return function (nextQuestion) {
						return function (ended) {
							return function (simulatedCall) {
								return function (responses) {
									return function (textFieldText) {
										return function (checkboxAnswers) {
											return function (surveyEndedEarlyByUser) {
												return function (calleePhoneNumber) {
													return function (calleeMessagingPhoneNumber) {
														return function (messageSubmitting) {
															return function (sendMessageError) {
																return function (questionKeysWithMessagesSent) {
																	return function (calleeAddress) {
																		return function (calleeZip) {
																			return {callID: callID, callUUID: callUUID, calleeAddress: calleeAddress, calleeData: calleeData, calleeMessagingPhoneNumber: calleeMessagingPhoneNumber, calleePhoneNumber: calleePhoneNumber, calleeZip: calleeZip, campaign: campaign, checkboxAnswers: checkboxAnswers, ended: ended, messageSubmitting: messageSubmitting, name: name, nextQuestion: nextQuestion, questionKeysWithMessagesSent: questionKeysWithMessagesSent, responses: responses, sendMessageError: sendMessageError, simulatedCall: simulatedCall, surveyEndedEarlyByUser: surveyEndedEarlyByUser, textFieldText: textFieldText};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $elm$core$Set$Set_elm_builtin = function (a) {
	return {$: 'Set_elm_builtin', a: a};
};
var $elm$core$Set$empty = $elm$core$Set$Set_elm_builtin($elm$core$Dict$empty);
var $author$project$Dialer$States$ScriptPreview$fakeCalleeDataForCommonFields = function (campaign) {
	var placeholderForField = function (calleeField) {
		var fieldName = A2($elm$core$Maybe$withDefault, '', calleeField.dataSourceFieldName);
		return _Utils_Tuple2(fieldName, calleeField.name);
	};
	return $elm$core$Dict$fromList(
		A2($elm$core$List$map, placeholderForField, campaign.callee_fields));
};
var $author$project$Dialer$States$InCall$findMatchingQuestion = F2(
	function (possibleQuestionKey, campaign) {
		return A2(
			$elm$core$Maybe$andThen,
			function (key) {
				return $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (question) {
							return _Utils_eq(question.key, key);
						},
						campaign.questions));
			},
			possibleQuestionKey);
	});
var $elm_community$list_extra$List$Extra$last = function (items) {
	last:
	while (true) {
		if (!items.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!items.b.b) {
				var x = items.a;
				return $elm$core$Maybe$Just(x);
			} else {
				var rest = items.b;
				var $temp$items = rest;
				items = $temp$items;
				continue last;
			}
		}
	}
};
var $author$project$Dialer$States$InCall$nextQuestion = F2(
	function (campaign, responses) {
		var _v0 = $elm_community$list_extra$List$Extra$last(responses);
		if (_v0.$ === 'Nothing') {
			return A2(
				$author$project$Dialer$States$InCall$findMatchingQuestion,
				$elm$core$Maybe$Just('disposition'),
				campaign);
		} else {
			var questionResponse = _v0.a;
			return A2(
				$author$project$Dialer$States$InCall$findMatchingQuestion,
				function () {
					switch (questionResponse.$) {
						case 'SingleChoiceQuestionResponse':
							var answer = questionResponse.b;
							return answer.next;
						case 'MultipleChoiceQuestionResponse':
							var question = questionResponse.a;
							return question.next;
						case 'TextFieldQuestionResponse':
							var question = questionResponse.a;
							return question.next;
						case 'PollingPlaceLookupQuestionResponse':
							var question = questionResponse.a;
							return question.next;
						default:
							var question = questionResponse.a;
							return question.next;
					}
				}(),
				campaign);
		}
	});
var $author$project$Dialer$States$ScriptPreview$createSimulatedCallStateFromCampaign = function (campaign) {
	return $author$project$Dialer$Types$CallState(campaign)('')('')(
		$elm$core$Maybe$Just('Bernie'))(
		$author$project$Dialer$States$ScriptPreview$fakeCalleeDataForCommonFields(campaign))(
		A2($author$project$Dialer$States$InCall$nextQuestion, campaign, _List_Nil))(false)(true)(_List_Nil)('')(_List_Nil)(false)('+12025551234')('+12025551234')(false)($elm$core$Maybe$Nothing)($elm$core$Set$empty)($elm$core$Maybe$Nothing)($elm$core$Maybe$Nothing);
};
var $author$project$Dialer$Types$EndConferenceResponse = function (a) {
	return {$: 'EndConferenceResponse', a: a};
};
var $author$project$Api$Mutation$endConference = function (requiredArgs____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
		'Bool',
		'endConference',
		_List_fromArray(
			[
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'token', requiredArgs____.token, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string),
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'callUUID', requiredArgs____.callUUID, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string),
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'callID', requiredArgs____.callID, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)
			]),
		$elm$json$Json$Decode$bool);
};
var $author$project$Dialer$Api$endConferenceCommand = F5(
	function (env, client, token, callUUID, callID) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Http$send,
			$author$project$Dialer$Types$EndConferenceResponse,
			A2(
				$author$project$Dialer$Api$setTenantHeader,
				client,
				A2(
					$dillonkearns$elm_graphql$Graphql$Http$mutationRequest,
					A2($author$project$Dialer$Api$gqlBaseUrl, env, client),
					$author$project$Api$Mutation$endConference(
						{callID: callID, callUUID: callUUID, token: token}))));
	});
var $author$project$Dialer$Types$SubmittingSurveyResponse = function (a) {
	return {$: 'SubmittingSurveyResponse', a: a};
};
var $author$project$Dialer$Types$SurveyResultResponse = function (a) {
	return {$: 'SurveyResultResponse', a: a};
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$Object = function (a) {
	return {$: 'Object', a: a};
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject = function (maybeValues) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Object(
		A2(
			$elm$core$List$filterMap,
			function (_v0) {
				var key = _v0.a;
				var value = _v0.b;
				if (value.$ === 'Just') {
					var actualValue = value.a;
					return $elm$core$Maybe$Just(
						_Utils_Tuple2(key, actualValue));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			},
			maybeValues));
};
var $author$project$Api$InputObject$encodeAddress = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'address',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.address))),
				_Utils_Tuple2(
				'zip',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.zip)))
			]));
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$EnumValue = function (a) {
	return {$: 'EnumValue', a: a};
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$enum = F2(
	function (enumToString, value) {
		return $dillonkearns$elm_graphql$Graphql$Internal$Encode$EnumValue(
			enumToString(value));
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$List = function (a) {
	return {$: 'List', a: a};
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$list = F2(
	function (toValue, value) {
		return $dillonkearns$elm_graphql$Graphql$Internal$Encode$List(
			A2($elm$core$List$map, toValue, value));
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$null = $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json($elm$json$Json$Encode$null);
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$optional = F2(
	function (optionalValue, toValue) {
		switch (optionalValue.$) {
			case 'Present':
				var value = optionalValue.a;
				return $elm$core$Maybe$Just(
					toValue(value));
			case 'Absent':
				return $elm$core$Maybe$Nothing;
			default:
				return $elm$core$Maybe$Just($dillonkearns$elm_graphql$Graphql$Internal$Encode$null);
		}
	});
var $author$project$Api$Enum$DispositionType$toString = function (enum____) {
	switch (enum____.$) {
		case 'Canvassed':
			return 'CANVASSED';
		case 'Callback':
			return 'CALLBACK';
		case 'NoCallback':
			return 'NO_CALLBACK';
		case 'AnsweringMachine':
			return 'ANSWERING_MACHINE';
		default:
			return 'OPT_OUT';
	}
};
var $author$project$Api$Enum$QuestionType$toString = function (enum____) {
	switch (enum____.$) {
		case 'Disposition':
			return 'DISPOSITION';
		case 'SingleChoice':
			return 'SINGLE_CHOICE';
		case 'MultipleChoice':
			return 'MULTIPLE_CHOICE';
		case 'TextField':
			return 'TEXT_FIELD';
		case 'PatchThru':
			return 'PATCH_THRU';
		case 'MobilizeamericaRsvp':
			return 'MOBILIZEAMERICA_RSVP';
		case 'SendMessage':
			return 'SEND_MESSAGE';
		default:
			return 'POLLING_PLACE_LOOKUP';
	}
};
var $author$project$Api$InputObject$encodeSurveyResult = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'questionType',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.questionType,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$QuestionType$toString))),
				_Utils_Tuple2(
				'question',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.question))),
				_Utils_Tuple2(
				'answer',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.answer, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'answerArray',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.answerArray,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($dillonkearns$elm_graphql$Graphql$Internal$Encode$string))),
				_Utils_Tuple2(
				'answerAddress',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.answerAddress, $author$project$Api$InputObject$encodeAddress)),
				_Utils_Tuple2(
				'nextAction',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.nextAction, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'disposition',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.disposition,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$DispositionType$toString))),
				_Utils_Tuple2(
				'patchThruNumber',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.patchThruNumber, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string))
			]));
};
var $author$project$Api$Mutation$recordSurveyResults = function (requiredArgs____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
		'Bool',
		'recordSurveyResults',
		_List_fromArray(
			[
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'token', requiredArgs____.token, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string),
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'callUUID', requiredArgs____.callUUID, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string),
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'callID', requiredArgs____.callID, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string),
				A3(
				$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required,
				'results',
				requiredArgs____.results,
				$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Api$InputObject$encodeSurveyResult))
			]),
		$elm$json$Json$Decode$bool);
};
var $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent = {$: 'Absent'};
var $author$project$Api$InputObject$Address = F2(
	function (address, zip) {
		return {address: address, zip: zip};
	});
var $dillonkearns$elm_graphql$Graphql$OptionalArgument$Null = {$: 'Null'};
var $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present = function (a) {
	return {$: 'Present', a: a};
};
var $author$project$Api$InputObject$SurveyResult = F8(
	function (questionType, question, answer, answerArray, answerAddress, nextAction, disposition, patchThruNumber) {
		return {answer: answer, answerAddress: answerAddress, answerArray: answerArray, disposition: disposition, nextAction: nextAction, patchThruNumber: patchThruNumber, question: question, questionType: questionType};
	});
var $dillonkearns$elm_graphql$Graphql$OptionalArgument$fromMaybe = function (maybeValue) {
	if (maybeValue.$ === 'Just') {
		var value = maybeValue.a;
		return $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(value);
	} else {
		return $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent;
	}
};
var $author$project$Dialer$Api$resultFromResponse = function (questionResponse) {
	var convertMaybe = function (maybe) {
		if (maybe.$ === 'Just') {
			var present = maybe.a;
			return $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(present);
		} else {
			return $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent;
		}
	};
	switch (questionResponse.$) {
		case 'SingleChoiceQuestionResponse':
			var question = questionResponse.a;
			var answer = questionResponse.b;
			var _v1 = question.questionType;
			switch (_v1.$) {
				case 'Disposition':
					return $elm$core$Maybe$Just(
						A8(
							$author$project$Api$InputObject$SurveyResult,
							$dillonkearns$elm_graphql$Graphql$OptionalArgument$Present($author$project$Api$Enum$QuestionType$Disposition),
							question.key,
							$dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(answer.value),
							$dillonkearns$elm_graphql$Graphql$OptionalArgument$Null,
							$dillonkearns$elm_graphql$Graphql$OptionalArgument$Null,
							convertMaybe(answer.next),
							convertMaybe(answer.disposition),
							$dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent));
				case 'PatchThru':
					return $elm$core$Maybe$Just(
						A8(
							$author$project$Api$InputObject$SurveyResult,
							$dillonkearns$elm_graphql$Graphql$OptionalArgument$Present($author$project$Api$Enum$QuestionType$PatchThru),
							question.key,
							$dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(answer.value),
							$dillonkearns$elm_graphql$Graphql$OptionalArgument$Null,
							$dillonkearns$elm_graphql$Graphql$OptionalArgument$Null,
							convertMaybe(answer.next),
							$dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
							convertMaybe(answer.patchThruNumber)));
				default:
					return $elm$core$Maybe$Just(
						A8(
							$author$project$Api$InputObject$SurveyResult,
							$dillonkearns$elm_graphql$Graphql$OptionalArgument$Present($author$project$Api$Enum$QuestionType$SingleChoice),
							question.key,
							$dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(answer.value),
							$dillonkearns$elm_graphql$Graphql$OptionalArgument$Null,
							$dillonkearns$elm_graphql$Graphql$OptionalArgument$Null,
							convertMaybe(answer.next),
							$dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
							$dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent));
			}
		case 'MultipleChoiceQuestionResponse':
			var question = questionResponse.a;
			var answers = questionResponse.b;
			return $elm$core$Maybe$Just(
				A8(
					$author$project$Api$InputObject$SurveyResult,
					$dillonkearns$elm_graphql$Graphql$OptionalArgument$Present($author$project$Api$Enum$QuestionType$MultipleChoice),
					question.key,
					$dillonkearns$elm_graphql$Graphql$OptionalArgument$Null,
					$dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
						A2(
							$elm$core$List$map,
							function (a) {
								return a.value;
							},
							answers)),
					$dillonkearns$elm_graphql$Graphql$OptionalArgument$Null,
					$dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
					$dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
					$dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent));
		case 'TextFieldQuestionResponse':
			var question = questionResponse.a;
			var answer = questionResponse.b;
			return $elm$core$Maybe$Just(
				A8(
					$author$project$Api$InputObject$SurveyResult,
					$dillonkearns$elm_graphql$Graphql$OptionalArgument$Present($author$project$Api$Enum$QuestionType$TextField),
					question.key,
					$dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(answer),
					$dillonkearns$elm_graphql$Graphql$OptionalArgument$Null,
					$dillonkearns$elm_graphql$Graphql$OptionalArgument$Null,
					convertMaybe(question.next),
					$dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
					$dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent));
		case 'PollingPlaceLookupQuestionResponse':
			var question = questionResponse.a;
			var maybeAddress = questionResponse.b;
			return $elm$core$Maybe$Just(
				A8(
					$author$project$Api$InputObject$SurveyResult,
					$dillonkearns$elm_graphql$Graphql$OptionalArgument$Present($author$project$Api$Enum$QuestionType$PollingPlaceLookup),
					question.key,
					$dillonkearns$elm_graphql$Graphql$OptionalArgument$Null,
					$dillonkearns$elm_graphql$Graphql$OptionalArgument$Null,
					convertMaybe(
						A2(
							$elm$core$Maybe$map,
							function (address) {
								return A2($author$project$Api$InputObject$Address, address.address, address.zip);
							},
							maybeAddress)),
					$dillonkearns$elm_graphql$Graphql$OptionalArgument$fromMaybe(question.next),
					$dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
					$dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent));
		default:
			var question = questionResponse.a;
			var _v2 = question.questionType;
			if (_v2.$ === 'PollingPlaceLookup') {
				return $elm$core$Maybe$Just(
					A8(
						$author$project$Api$InputObject$SurveyResult,
						$dillonkearns$elm_graphql$Graphql$OptionalArgument$Present($author$project$Api$Enum$QuestionType$PollingPlaceLookup),
						question.key,
						$dillonkearns$elm_graphql$Graphql$OptionalArgument$Null,
						$dillonkearns$elm_graphql$Graphql$OptionalArgument$Null,
						$dillonkearns$elm_graphql$Graphql$OptionalArgument$Null,
						$dillonkearns$elm_graphql$Graphql$OptionalArgument$fromMaybe(question.next),
						$dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
						$dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent));
			} else {
				return $elm$core$Maybe$Nothing;
			}
	}
};
var $author$project$Dialer$Api$sendSurveyResultCommand = F2(
	function (model, callState) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Http$send,
			$author$project$Dialer$Types$SurveyResultResponse,
			A2(
				$author$project$Dialer$Api$setTenantHeader,
				model.client,
				A2(
					$dillonkearns$elm_graphql$Graphql$Http$mutationRequest,
					A2($author$project$Dialer$Api$gqlBaseUrl, model.env, model.client),
					$author$project$Api$Mutation$recordSurveyResults(
						{
							callID: callState.callID,
							callUUID: callState.callUUID,
							results: A2($elm$core$List$filterMap, $author$project$Dialer$Api$resultFromResponse, callState.responses),
							token: model.token
						}))));
	});
var $author$project$Dialer$States$InCall$updateWithSubmittedAnswersAndContinueSurvey = F3(
	function (model, callState, updatedResponses) {
		var updatedNextQuestion = A2($author$project$Dialer$States$InCall$nextQuestion, callState.campaign, updatedResponses);
		var updatedCallState = _Utils_update(
			callState,
			{nextQuestion: updatedNextQuestion, responses: updatedResponses});
		if (updatedNextQuestion.$ === 'Just') {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						state: $author$project$Dialer$Types$InCall(updatedCallState)
					}),
				$elm$core$Platform$Cmd$none);
		} else {
			return callState.simulatedCall ? _Utils_Tuple2(
				_Utils_update(
					model,
					{
						state: $author$project$Dialer$Types$InCall(updatedCallState)
					}),
				$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
				_Utils_update(
					model,
					{
						state: $author$project$Dialer$Types$SubmittingSurveyResponse(updatedCallState)
					}),
				A2($author$project$Dialer$Api$sendSurveyResultCommand, model, updatedCallState));
		}
	});
var $author$project$Dialer$States$InCall$goToNextQuestion = F2(
	function (model, callState) {
		return A3($author$project$Dialer$States$InCall$updateWithSubmittedAnswersAndContinueSurvey, model, callState, callState.responses);
	});
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0.a;
		return $elm$core$Set$Set_elm_builtin(
			A3($elm$core$Dict$insert, key, _Utils_Tuple0, dict));
	});
var $author$project$Dialer$States$InQueue$nextJokeIndex = function (currentIndex) {
	return _Utils_eq(
		currentIndex + 1,
		$elm$core$List$length($author$project$Dialer$States$InQueue$jokes)) ? 0 : (currentIndex + 1);
};
var $author$project$Dialer$States$InCall$phoneNumberForMessaging = function (eventData) {
	return eventData.landline ? '' : eventData.calleePhoneNumber;
};
var $author$project$Dialer$Types$RecordCallersNameResponse = function (a) {
	return {$: 'RecordCallersNameResponse', a: a};
};
var $author$project$Dialer$Api$prepareEmailForSubmission = F2(
	function (campaign, email) {
		return campaign.collect_caller_email ? $dillonkearns$elm_graphql$Graphql$OptionalArgument$fromMaybe(email) : $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent;
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional = F3(
	function (fieldName, maybeValue, toValue) {
		switch (maybeValue.$) {
			case 'Present':
				var value = maybeValue.a;
				return $elm$core$Maybe$Just(
					A2(
						$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument,
						fieldName,
						toValue(value)));
			case 'Absent':
				return $elm$core$Maybe$Nothing;
			default:
				return $elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument, fieldName, $dillonkearns$elm_graphql$Graphql$Internal$Encode$null));
		}
	});
var $author$project$Api$Mutation$recordCallersName = F2(
	function (fillInOptionals____, requiredArgs____) {
		var filledInOptionals____ = fillInOptionals____(
			{email: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, referralCode: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
		var optionalArgs____ = A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'email', filledInOptionals____.email, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'referralCode', filledInOptionals____.referralCode, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)
				]));
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
			'Bool',
			'recordCallersName',
			_Utils_ap(
				optionalArgs____,
				_List_fromArray(
					[
						A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'token', requiredArgs____.token, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string),
						A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'callUUID', requiredArgs____.callUUID, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string),
						A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'name', requiredArgs____.name, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)
					])),
			$elm$json$Json$Decode$bool);
	});
var $author$project$Dialer$Api$recordCallersNameCommand = F3(
	function (model, campaign, callUUID) {
		var _v0 = model.caller.name;
		if (_v0.$ === 'Just') {
			var name = _v0.a;
			return A2(
				$dillonkearns$elm_graphql$Graphql$Http$send,
				$author$project$Dialer$Types$RecordCallersNameResponse,
				A2(
					$author$project$Dialer$Api$setTenantHeader,
					model.client,
					A2(
						$dillonkearns$elm_graphql$Graphql$Http$mutationRequest,
						A2($author$project$Dialer$Api$gqlBaseUrl, model.env, model.client),
						A2(
							$author$project$Api$Mutation$recordCallersName,
							function (optionals) {
								return _Utils_update(
									optionals,
									{
										email: A2($author$project$Dialer$Api$prepareEmailForSubmission, campaign, model.caller.email),
										referralCode: $dillonkearns$elm_graphql$Graphql$OptionalArgument$fromMaybe(model.referralCode)
									});
							},
							{callUUID: callUUID, name: name, token: model.token}))));
		} else {
			return $elm$core$Platform$Cmd$none;
		}
	});
var $author$project$Dialer$Types$RegisterCallerChannelIdResponse = function (a) {
	return {$: 'RegisterCallerChannelIdResponse', a: a};
};
var $author$project$Api$Mutation$registerPusherChannel = function (requiredArgs____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
		'Bool',
		'registerPusherChannel',
		_List_fromArray(
			[
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'token', requiredArgs____.token, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string),
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'phoneNumber', requiredArgs____.phoneNumber, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string),
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'channelId', requiredArgs____.channelId, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string),
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'pusherAppId', requiredArgs____.pusherAppId, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)
			]),
		$elm$json$Json$Decode$bool);
};
var $author$project$Dialer$Api$registerPusherChannelCommand = F3(
	function (model, phoneNumber, pusherAppId) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Http$send,
			$author$project$Dialer$Types$RegisterCallerChannelIdResponse,
			A2(
				$author$project$Dialer$Api$setTenantHeader,
				model.client,
				A2(
					$dillonkearns$elm_graphql$Graphql$Http$mutationRequest,
					A2($author$project$Dialer$Api$gqlBaseUrl, model.env, model.client),
					$author$project$Api$Mutation$registerPusherChannel(
						{channelId: model.callerChannelId, phoneNumber: phoneNumber, pusherAppId: pusherAppId, token: model.token}))));
	});
var $author$project$InteropDefinitions$SaveCallerPortMessage = function (a) {
	return {$: 'SaveCallerPortMessage', a: a};
};
var $author$project$Dialer$Ports$saveCaller = function (caller) {
	return $author$project$InteropPorts$fromElm(
		$author$project$InteropDefinitions$SaveCallerPortMessage(caller));
};
var $author$project$InteropDefinitions$ErrorPortMessage = function (a) {
	return {$: 'ErrorPortMessage', a: a};
};
var $author$project$Dialer$Ports$sendErrorMessage = function (message) {
	return $author$project$InteropPorts$fromElm(
		$author$project$InteropDefinitions$ErrorPortMessage(
			{message: message}));
};
var $author$project$Dialer$Types$SendMessageResponse = function (a) {
	return {$: 'SendMessageResponse', a: a};
};
var $author$project$Api$Mutation$sendMessage = function (requiredArgs____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
		'Bool',
		'sendMessage',
		_List_fromArray(
			[
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'token', requiredArgs____.token, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string),
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'callID', requiredArgs____.callID, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string),
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'questionKey', requiredArgs____.questionKey, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string),
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'phoneNumber', requiredArgs____.phoneNumber, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string),
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'text', requiredArgs____.text, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)
			]),
		$elm$json$Json$Decode$bool);
};
var $author$project$Dialer$Api$sendMessageCommand = F4(
	function (model, callState, questionKey, text) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Http$send,
			$author$project$Dialer$Types$SendMessageResponse,
			A2(
				$author$project$Dialer$Api$setTenantHeader,
				model.client,
				A2(
					$dillonkearns$elm_graphql$Graphql$Http$mutationRequest,
					A2($author$project$Dialer$Api$gqlBaseUrl, model.env, model.client),
					$author$project$Api$Mutation$sendMessage(
						{callID: callState.callID, phoneNumber: callState.calleeMessagingPhoneNumber, questionKey: questionKey, text: text, token: model.token}))));
	});
var $elm_community$list_extra$List$Extra$filterNot = F2(
	function (pred, list) {
		return A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, pred),
			list);
	});
var $author$project$Dialer$States$InCall$updateWithClickedMultipleReponseResult = F3(
	function (model, callState, _v0) {
		var question = _v0.a;
		var answer = _v0.b;
		var wasChecked = A2(
			$elm$core$List$member,
			answer.value,
			A2(
				$elm$core$List$map,
				function (a) {
					return a.value;
				},
				callState.checkboxAnswers));
		var newAnswers = wasChecked ? A2(
			$elm_community$list_extra$List$Extra$filterNot,
			function (a) {
				return _Utils_eq(a.value, answer.value);
			},
			callState.checkboxAnswers) : A2(
			$elm$core$List$append,
			callState.checkboxAnswers,
			_List_fromArray(
				[answer]));
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					state: $author$project$Dialer$Types$InCall(
						_Utils_update(
							callState,
							{checkboxAnswers: newAnswers}))
				}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Dialer$Types$SingleChoiceQuestionResponse = F2(
	function (a, b) {
		return {$: 'SingleChoiceQuestionResponse', a: a, b: b};
	});
var $author$project$Dialer$States$InCall$updateWithClickedSingleResponseResult = F3(
	function (model, callState, _v0) {
		var question = _v0.a;
		var answer = _v0.b;
		return A3(
			$author$project$Dialer$States$InCall$updateWithSubmittedAnswersAndContinueSurvey,
			model,
			callState,
			A2(
				$elm$core$List$append,
				callState.responses,
				_List_fromArray(
					[
						A2($author$project$Dialer$Types$SingleChoiceQuestionResponse, question, answer)
					])));
	});
var $author$project$Dialer$States$InCall$updateWithEndSurveyClicked = F2(
	function (model, callState) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					state: $author$project$Dialer$Types$SubmittingSurveyResponse(
						_Utils_update(
							callState,
							{surveyEndedEarlyByUser: true}))
				}),
			A2($author$project$Dialer$Api$sendSurveyResultCommand, model, callState));
	});
var $author$project$Dialer$Types$CallCompleteAndSurveyComplete = function (a) {
	return {$: 'CallCompleteAndSurveyComplete', a: a};
};
var $author$project$Utils$maybeEqual = F2(
	function (maybeValue1, value2) {
		if (maybeValue1.$ === 'Just') {
			var value1 = maybeValue1.a;
			return _Utils_eq(value1, value2);
		} else {
			return false;
		}
	});
var $author$project$Dialer$Views$CalleeData$calleeFieldHasTargetBuiltinField = F2(
	function (targetBuiltinField, calleeField) {
		return A2($author$project$Utils$maybeEqual, calleeField.builtInField, targetBuiltinField);
	});
var $author$project$Dialer$Views$CalleeData$CalleeFieldValue = F2(
	function (name, value) {
		return {name: name, value: value};
	});
var $author$project$Dialer$Views$CalleeData$fieldFromCalleeData = F2(
	function (calleeData, calleeField) {
		return A2(
			$author$project$Dialer$Views$CalleeData$CalleeFieldValue,
			calleeField.name,
			A2(
				$elm$core$Maybe$andThen,
				function (name) {
					return A2($elm$core$Dict$get, name, calleeData);
				},
				calleeField.dataSourceFieldName));
	});
var $elm_community$list_extra$List$Extra$find = F2(
	function (predicate, list) {
		find:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var first = list.a;
				var rest = list.b;
				if (predicate(first)) {
					return $elm$core$Maybe$Just(first);
				} else {
					var $temp$predicate = predicate,
						$temp$list = rest;
					predicate = $temp$predicate;
					list = $temp$list;
					continue find;
				}
			}
		}
	});
var $author$project$Dialer$Views$CalleeData$mapCalleeBuiltinFieldToValue = F3(
	function (campaign, calleeData, builtinField) {
		return A2(
			$elm$core$Maybe$map,
			$author$project$Dialer$Views$CalleeData$fieldFromCalleeData(calleeData),
			A2(
				$elm_community$list_extra$List$Extra$find,
				$author$project$Dialer$Views$CalleeData$calleeFieldHasTargetBuiltinField(builtinField),
				campaign.callee_fields));
	});
var $author$project$Dialer$States$InCall$calleeBuiltinFieldValue = F3(
	function (campaign, calleeData, calleeField) {
		return A2(
			$elm$core$Maybe$andThen,
			function ($) {
				return $.value;
			},
			A3($author$project$Dialer$Views$CalleeData$mapCalleeBuiltinFieldToValue, campaign, calleeData, calleeField));
	});
var $author$project$Dialer$States$InCall$calleeFirstName = F2(
	function (campaign, calleeData) {
		return A3($author$project$Dialer$States$InCall$calleeBuiltinFieldValue, campaign, calleeData, $author$project$Api$Enum$BuiltinCalleeField$FirstName);
	});
var $author$project$Dialer$States$InCall$updateWithInCallEvent = F9(
	function (model, campaign, responses, surveyEndedEarlyByUser, eventData, calleeMessagingPhoneNumber, textFieldText, checkboxAnswers, questionKeysWithMessagesSent) {
		var surveyComplete = surveyEndedEarlyByUser || _Utils_eq(
			A2($author$project$Dialer$States$InCall$nextQuestion, campaign, responses),
			$elm$core$Maybe$Nothing);
		var calleeBuiltInFieldValue = function (field) {
			return A2(
				$elm$core$Maybe$andThen,
				function ($) {
					return $.value;
				},
				A3($author$project$Dialer$Views$CalleeData$mapCalleeBuiltinFieldToValue, campaign, eventData.calleeData, field));
		};
		var calleeAddress = A2(
			$elm$core$Maybe$map,
			function (addressLine1) {
				var _v1 = calleeBuiltInFieldValue($author$project$Api$Enum$BuiltinCalleeField$AddressLine2);
				if (_v1.$ === 'Nothing') {
					return addressLine1;
				} else {
					var addressLine2 = _v1.a;
					return A2(
						$elm$core$String$join,
						' ',
						_List_fromArray(
							[addressLine1, addressLine2]));
				}
			},
			calleeBuiltInFieldValue($author$project$Api$Enum$BuiltinCalleeField$AddressLine1));
		var _v0 = _Utils_Tuple2(eventData.ended, surveyComplete);
		if (_v0.a && _v0.b) {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						state: $author$project$Dialer$Types$CallCompleteAndSurveyComplete(
							$author$project$Dialer$Types$CallState(campaign)(eventData.callUUID)(eventData.callID)(
								A2($author$project$Dialer$States$InCall$calleeFirstName, campaign, eventData.calleeData))(eventData.calleeData)($elm$core$Maybe$Nothing)(eventData.ended)(false)(responses)('')(_List_Nil)(surveyEndedEarlyByUser)(eventData.calleePhoneNumber)(calleeMessagingPhoneNumber)(false)($elm$core$Maybe$Nothing)(questionKeysWithMessagesSent)($elm$core$Maybe$Nothing)($elm$core$Maybe$Nothing))
					}),
				$elm$core$Platform$Cmd$none);
		} else {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						state: $author$project$Dialer$Types$InCall(
							$author$project$Dialer$Types$CallState(campaign)(eventData.callUUID)(eventData.callID)(
								A2($author$project$Dialer$States$InCall$calleeFirstName, campaign, eventData.calleeData))(eventData.calleeData)(
								A2($author$project$Dialer$States$InCall$nextQuestion, campaign, responses))(eventData.ended)(false)(responses)(textFieldText)(checkboxAnswers)(surveyEndedEarlyByUser)(eventData.calleePhoneNumber)(calleeMessagingPhoneNumber)(false)($elm$core$Maybe$Nothing)(questionKeysWithMessagesSent)(calleeAddress)(
								calleeBuiltInFieldValue($author$project$Api$Enum$BuiltinCalleeField$Zip)))
					}),
				$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Dialer$Types$MultipleChoiceQuestionResponse = F2(
	function (a, b) {
		return {$: 'MultipleChoiceQuestionResponse', a: a, b: b};
	});
var $author$project$Dialer$States$InCall$updateWithMultipleResponseAnswersSubmitted = F3(
	function (model, callState, _v0) {
		var question = _v0.a;
		var answers = _v0.b;
		var resetCheckboxAnswers = _Utils_update(
			callState,
			{checkboxAnswers: _List_Nil});
		return A3(
			$author$project$Dialer$States$InCall$updateWithSubmittedAnswersAndContinueSurvey,
			model,
			resetCheckboxAnswers,
			A2(
				$elm$core$List$append,
				callState.responses,
				_List_fromArray(
					[
						A2($author$project$Dialer$Types$MultipleChoiceQuestionResponse, question, answers)
					])));
	});
var $author$project$Dialer$Types$NullQuestionResponse = function (a) {
	return {$: 'NullQuestionResponse', a: a};
};
var $author$project$Dialer$States$InCall$updateWithNullResponse = F3(
	function (model, callState, question) {
		return A3(
			$author$project$Dialer$States$InCall$updateWithSubmittedAnswersAndContinueSurvey,
			model,
			callState,
			A2(
				$elm$core$List$append,
				callState.responses,
				_List_fromArray(
					[
						$author$project$Dialer$Types$NullQuestionResponse(question)
					])));
	});
var $author$project$Dialer$Types$PollingPlaceLookupQuestionResponse = F2(
	function (a, b) {
		return {$: 'PollingPlaceLookupQuestionResponse', a: a, b: b};
	});
var $author$project$Dialer$States$InCall$updateWithSubmittedAnswersAndStayOnCurrentQuestion = F3(
	function (model, callState, updatedResponses) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					state: $author$project$Dialer$Types$InCall(
						_Utils_update(
							callState,
							{responses: updatedResponses}))
				}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Dialer$States$InCall$updateWithPollingPlaceLookupAnswerSubmitted = F3(
	function (model, callState, _v0) {
		var question = _v0.a;
		var address = _v0.b;
		return A3(
			$author$project$Dialer$States$InCall$updateWithSubmittedAnswersAndStayOnCurrentQuestion,
			model,
			callState,
			A2(
				$elm$core$List$append,
				callState.responses,
				_List_fromArray(
					[
						A2(
						$author$project$Dialer$Types$PollingPlaceLookupQuestionResponse,
						question,
						$elm$core$Maybe$Just(address))
					])));
	});
var $author$project$Dialer$Types$TextFieldQuestionResponse = F2(
	function (a, b) {
		return {$: 'TextFieldQuestionResponse', a: a, b: b};
	});
var $author$project$Dialer$States$InCall$updateWithTextAnswerSubmitted = F4(
	function (model, callState, question, text) {
		var resetTextField = _Utils_update(
			callState,
			{textFieldText: ''});
		return A3(
			$author$project$Dialer$States$InCall$updateWithSubmittedAnswersAndContinueSurvey,
			model,
			resetTextField,
			A2(
				$elm$core$List$append,
				callState.responses,
				_List_fromArray(
					[
						A2($author$project$Dialer$Types$TextFieldQuestionResponse, question, text)
					])));
	});
var $elm_community$list_extra$List$Extra$unconsLast = function (list) {
	var _v0 = $elm$core$List$reverse(list);
	if (!_v0.b) {
		return $elm$core$Maybe$Nothing;
	} else {
		var last_ = _v0.a;
		var rest = _v0.b;
		return $elm$core$Maybe$Just(
			_Utils_Tuple2(
				last_,
				$elm$core$List$reverse(rest)));
	}
};
var $author$project$Dialer$States$InCall$updateWithUndoAnswer = F2(
	function (model, callState) {
		var setTextFieldIfNecessary = function () {
			var _v5 = $elm_community$list_extra$List$Extra$unconsLast(callState.responses);
			if (_v5.$ === 'Just') {
				var _v6 = _v5.a;
				var questionResponse = _v6.a;
				if (questionResponse.$ === 'TextFieldQuestionResponse') {
					var answer = questionResponse.b;
					return answer;
				} else {
					return '';
				}
			} else {
				return '';
			}
		}();
		var setCheckboxAnswersIfNeccesary = function () {
			var _v2 = $elm_community$list_extra$List$Extra$unconsLast(callState.responses);
			if (_v2.$ === 'Just') {
				var _v3 = _v2.a;
				var questionResponse = _v3.a;
				if (questionResponse.$ === 'MultipleChoiceQuestionResponse') {
					var answers = questionResponse.b;
					return answers;
				} else {
					return _List_Nil;
				}
			} else {
				return _List_Nil;
			}
		}();
		var responsesWithoutLastResponse = function () {
			var _v0 = $elm_community$list_extra$List$Extra$unconsLast(callState.responses);
			if (_v0.$ === 'Just') {
				var _v1 = _v0.a;
				var remainingQuestions = _v1.b;
				return remainingQuestions;
			} else {
				return _List_Nil;
			}
		}();
		return A3(
			$author$project$Dialer$States$InCall$updateWithSubmittedAnswersAndContinueSurvey,
			model,
			_Utils_update(
				callState,
				{checkboxAnswers: setCheckboxAnswersIfNeccesary, surveyEndedEarlyByUser: false, textFieldText: setTextFieldIfNecessary}),
			responsesWithoutLastResponse);
	});
var $author$project$Dialer$States$Login$validCallerInfo = F2(
	function (campaign, caller) {
		if (campaign.collect_caller_email) {
			var _v0 = _Utils_Tuple3(caller.phone, caller.name, caller.email);
			if (((_v0.a.$ === 'Just') && (_v0.b.$ === 'Just')) && (_v0.c.$ === 'Just')) {
				var phone = _v0.a.a;
				var name = _v0.b.a;
				var email = _v0.c.a;
				return (($elm$core$String$length(phone) < 10) || (($elm$core$String$length(name) < 3) || ($elm$core$String$length(email) < 3))) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
					_Utils_Tuple3(phone, name, email));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		} else {
			var _v1 = _Utils_Tuple2(caller.phone, caller.name);
			if ((_v1.a.$ === 'Just') && (_v1.b.$ === 'Just')) {
				var phone = _v1.a.a;
				var name = _v1.b.a;
				return (($elm$core$String$length(phone) < 10) || ($elm$core$String$length(name) < 3)) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
					_Utils_Tuple3(
						phone,
						name,
						A2($elm$core$Maybe$withDefault, '', caller.email)));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}
	});
var $author$project$Dialer$State$waitInThisState = function (model) {
	return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
};
var $author$project$Dialer$State$updateWithSensitiveDataRemoved = F2(
	function (msg, model) {
		var promptToPhoneIn = function (campaign) {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						state: $author$project$Dialer$Types$PromptToPhoneIn(
							{campaign: campaign})
					}),
				$author$project$Dialer$Ports$saveCaller(
					A4(
						$author$project$InteropDefinitions$Caller,
						$elm$core$Maybe$Nothing,
						$elm$core$Maybe$Nothing,
						$elm$core$Maybe$Just('phone'),
						$elm$core$Maybe$Just('email'))));
		};
		var promptForCallersPhoneNumberToConnect = F2(
			function (campaign, phoneIn) {
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							state: $author$project$Dialer$Types$PromptForCallersPhoneNumberToConnect(
								{
									callersEmail: A2($elm$core$Maybe$withDefault, '', model.caller.email),
									callersName: A2($elm$core$Maybe$withDefault, '', model.caller.name),
									callersPhoneNumber: A2($elm$core$Maybe$withDefault, '', model.caller.phone),
									campaign: campaign,
									phoneIn: phoneIn
								})
						}),
					$elm$core$Platform$Cmd$none);
			});
		var displayMediumChoices = function (campaign) {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						state: $author$project$Dialer$Types$CampaignReady(
							{campaign: campaign})
					}),
				$elm$core$Platform$Cmd$none);
		};
		var determineCallMethodsToDisplayBasedOnSavedPreferences = F2(
			function (savedCaller, campaign) {
				var _v57 = _Utils_Tuple2(
					model.caller.lastMedium,
					A2($author$project$Dialer$States$Login$validCallerInfo, campaign, savedCaller));
				_v57$4:
				while (true) {
					if (_v57.a.$ === 'Just') {
						if (_v57.b.$ === 'Nothing') {
							switch (_v57.a.a) {
								case 'computer':
									var _v58 = _v57.b;
									return A2(promptForCallersPhoneNumberToConnect, campaign, false);
								case 'phone_and_computer':
									var _v59 = _v57.b;
									return A2(promptForCallersPhoneNumberToConnect, campaign, true);
								default:
									break _v57$4;
							}
						} else {
							switch (_v57.a.a) {
								case 'computer':
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{
												state: $author$project$Dialer$Types$ConfirmLastCallInMethod(
													{campaign: campaign, lastMedium: 'computer'})
											}),
										$elm$core$Platform$Cmd$none);
								case 'phone_and_computer':
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{
												state: $author$project$Dialer$Types$ConfirmLastCallInMethod(
													{campaign: campaign, lastMedium: 'phone_and_computer'})
											}),
										$elm$core$Platform$Cmd$none);
								default:
									break _v57$4;
							}
						}
					} else {
						break _v57$4;
					}
				}
				return displayMediumChoices(campaign);
			});
		var _v0 = _Utils_Tuple2(model.state, msg);
		_v0$2:
		while (true) {
			_v0$3:
			while (true) {
				_v0$4:
				while (true) {
					_v0$5:
					while (true) {
						_v0$6:
						while (true) {
							_v0$7:
							while (true) {
								_v0$8:
								while (true) {
									_v0$9:
									while (true) {
										_v0$10:
										while (true) {
											_v0$11:
											while (true) {
												_v0$12:
												while (true) {
													_v0$13:
													while (true) {
														_v0$14:
														while (true) {
															_v0$15:
															while (true) {
																_v0$23:
																while (true) {
																	_v0$72:
																	while (true) {
																		switch (_v0.b.$) {
																			case 'ReportIssue':
																				var _v1 = _v0.b;
																				return _Utils_Tuple2(
																					model,
																					$author$project$InteropPorts$fromElm(
																						$author$project$InteropDefinitions$IssuePortMessage(
																							{})));
																			case 'RetryLoadingApp':
																				var _v2 = _v0.b;
																				return _Utils_Tuple2(
																					_Utils_update(
																						model,
																						{state: $author$project$Dialer$Types$LoadingCampaign}),
																					$author$project$InteropPorts$fromElm(
																						$author$project$InteropDefinitions$ReloadPortMessage(
																							{})));
																			case 'Tick':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$6;
																					default:
																						break _v0$6;
																				}
																			case 'MinuteTick':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$7;
																					default:
																						break _v0$7;
																				}
																			case 'ShuffledJokes':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$8;
																					default:
																						break _v0$8;
																				}
																			case 'GeneratedCallerChannelId':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$9;
																					default:
																						break _v0$9;
																				}
																			case 'CampaignLoaded':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						if (_v0.b.a.$ === 'Ok') {
																							break _v0$10;
																						} else {
																							break _v0$11;
																						}
																					default:
																						if (_v0.b.a.$ === 'Ok') {
																							break _v0$10;
																						} else {
																							break _v0$11;
																						}
																				}
																			case 'CampaignAlertsLoaded':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						if (_v0.b.a.$ === 'Ok') {
																							break _v0$12;
																						} else {
																							break _v0$13;
																						}
																					default:
																						if (_v0.b.a.$ === 'Ok') {
																							break _v0$12;
																						} else {
																							break _v0$13;
																						}
																				}
																			case 'RetryLoadingCampaign':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$14;
																					default:
																						break _v0$14;
																				}
																			case 'CallAnotherWay':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$15;
																					default:
																						break _v0$15;
																				}
																			case 'ResetSurveyPreviewClicked':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'InCall':
																						var campaign = _v0.a.a.campaign;
																						var _v12 = _v0.b;
																						return _Utils_Tuple2(
																							_Utils_update(
																								model,
																								{
																									state: $author$project$Dialer$Types$InCall(
																										$author$project$Dialer$States$ScriptPreview$createSimulatedCallStateFromCampaign(campaign))
																								}),
																							$elm$core$Platform$Cmd$none);
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					default:
																						break _v0$72;
																				}
																			case 'ExitSurveyPreviewClicked':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'InCall':
																						var campaign = _v0.a.a.campaign;
																						var _v13 = _v0.b;
																						return A2(determineCallMethodsToDisplayBasedOnSavedPreferences, model.caller, campaign);
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					default:
																						break _v0$72;
																				}
																			case 'CallWithPhoneOnlyClicked':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'CampaignReady':
																						var campaign = _v0.a.a.campaign;
																						var _v16 = _v0.b;
																						return promptToPhoneIn(campaign);
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					default:
																						break _v0$72;
																				}
																			case 'CallWithComputerClicked':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'CampaignReady':
																						var campaign = _v0.a.a.campaign;
																						var _v14 = _v0.b;
																						return A2(promptForCallersPhoneNumberToConnect, campaign, false);
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					default:
																						var _v20 = _v0.b;
																						return $author$project$Dialer$State$waitInThisState(model);
																				}
																			case 'CallWithPhoneClicked':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'CampaignReady':
																						var campaign = _v0.a.a.campaign;
																						var _v15 = _v0.b;
																						return A2(promptForCallersPhoneNumberToConnect, campaign, true);
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					default:
																						var _v21 = _v0.b;
																						return $author$project$Dialer$State$waitInThisState(model);
																				}
																			case 'PreviewScriptClicked':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'CampaignReady':
																						var campaign = _v0.a.a.campaign;
																						var _v11 = _v0.b;
																						return _Utils_Tuple2(
																							_Utils_update(
																								model,
																								{
																									state: $author$project$Dialer$Types$InCall(
																										$author$project$Dialer$States$ScriptPreview$createSimulatedCallStateFromCampaign(campaign))
																								}),
																							$elm$core$Platform$Cmd$none);
																					case 'ConfirmLastCallInMethod':
																						var campaign = _v0.a.a.campaign;
																						var _v17 = _v0.b;
																						return _Utils_Tuple2(
																							_Utils_update(
																								model,
																								{
																									state: $author$project$Dialer$Types$InCall(
																										$author$project$Dialer$States$ScriptPreview$createSimulatedCallStateFromCampaign(campaign))
																								}),
																							$elm$core$Platform$Cmd$none);
																					case 'PromptToPhoneIn':
																						var campaign = _v0.a.a.campaign;
																						var _v22 = _v0.b;
																						return _Utils_Tuple2(
																							_Utils_update(
																								model,
																								{
																									state: $author$project$Dialer$Types$InCall(
																										$author$project$Dialer$States$ScriptPreview$createSimulatedCallStateFromCampaign(campaign))
																								}),
																							$elm$core$Platform$Cmd$none);
																					default:
																						break _v0$72;
																				}
																			case 'CaptureCallerName':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					case 'PromptForCallersPhoneNumberToConnect':
																						var campaign = _v0.a.a.campaign;
																						var phoneIn = _v0.a.a.phoneIn;
																						var callersPhoneNumber = _v0.a.a.callersPhoneNumber;
																						var callersEmail = _v0.a.a.callersEmail;
																						var updatedText = _v0.b.a;
																						return _Utils_Tuple2(
																							_Utils_update(
																								model,
																								{
																									state: $author$project$Dialer$Types$PromptForCallersPhoneNumberToConnect(
																										{callersEmail: callersEmail, callersName: updatedText, callersPhoneNumber: callersPhoneNumber, campaign: campaign, phoneIn: phoneIn})
																								}),
																							$elm$core$Platform$Cmd$none);
																					default:
																						break _v0$72;
																				}
																			case 'CapturePhoneNumber':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					case 'PromptForCallersPhoneNumberToConnect':
																						var campaign = _v0.a.a.campaign;
																						var phoneIn = _v0.a.a.phoneIn;
																						var callersName = _v0.a.a.callersName;
																						var callersEmail = _v0.a.a.callersEmail;
																						var updatedText = _v0.b.a;
																						return _Utils_Tuple2(
																							_Utils_update(
																								model,
																								{
																									state: $author$project$Dialer$Types$PromptForCallersPhoneNumberToConnect(
																										{
																											callersEmail: callersEmail,
																											callersName: callersName,
																											callersPhoneNumber: $author$project$Utils$cleanNumber(updatedText),
																											campaign: campaign,
																											phoneIn: phoneIn
																										})
																								}),
																							$elm$core$Platform$Cmd$none);
																					default:
																						break _v0$72;
																				}
																			case 'CaptureCallerEmail':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					case 'PromptForCallersPhoneNumberToConnect':
																						var campaign = _v0.a.a.campaign;
																						var phoneIn = _v0.a.a.phoneIn;
																						var callersName = _v0.a.a.callersName;
																						var callersPhoneNumber = _v0.a.a.callersPhoneNumber;
																						var updatedText = _v0.b.a;
																						return _Utils_Tuple2(
																							_Utils_update(
																								model,
																								{
																									state: $author$project$Dialer$Types$PromptForCallersPhoneNumberToConnect(
																										{
																											callersEmail: $author$project$Utils$cleanEmail(updatedText),
																											callersName: callersName,
																											callersPhoneNumber: callersPhoneNumber,
																											campaign: campaign,
																											phoneIn: phoneIn
																										})
																								}),
																							$elm$core$Platform$Cmd$none);
																					default:
																						break _v0$72;
																				}
																			case 'SubmitPhoneNumberClicked':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					case 'PromptForCallersPhoneNumberToConnect':
																						var campaign = _v0.a.a.campaign;
																						var phoneIn = _v0.a.a.phoneIn;
																						var callersName = _v0.a.a.callersName;
																						var callersPhoneNumber = _v0.a.a.callersPhoneNumber;
																						var callersEmail = _v0.a.a.callersEmail;
																						var _v23 = _v0.b;
																						var medium = $elm$core$Maybe$Just(
																							phoneIn ? 'phone_and_computer' : 'computer');
																						var caller = A4(
																							$author$project$InteropDefinitions$Caller,
																							$elm$core$Maybe$Just(callersPhoneNumber),
																							$elm$core$Maybe$Just(callersName),
																							$elm$core$Maybe$Just(callersEmail),
																							medium);
																						return _Utils_Tuple2(
																							_Utils_update(
																								model,
																								{
																									caller: caller,
																									state: $author$project$Dialer$Types$SubmittingPhoneNumber(
																										{callersEmail: callersEmail, callersName: callersName, callersPhoneNumber: callersPhoneNumber, campaign: campaign, phoneIn: phoneIn})
																								}),
																							$elm$core$Platform$Cmd$batch(
																								_List_fromArray(
																									[
																										A6($author$project$Dialer$State$connectCommands, model, campaign, phoneIn, callersPhoneNumber, callersName, callersEmail),
																										$author$project$Dialer$Ports$saveCaller(caller)
																									])));
																					default:
																						var _v24 = _v0.b;
																						return $author$project$Dialer$State$waitInThisState(model);
																				}
																			case 'RecordCallersNameResponse':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					default:
																						return $author$project$Dialer$State$waitInThisState(model);
																				}
																			case 'RegisterCallerChannelIdResponse':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					default:
																						return $author$project$Dialer$State$waitInThisState(model);
																				}
																			case 'StartCallingClicked':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					case 'PhoneDialerInterfaceConnected':
																						var campaign = _v0.a.a.campaign;
																						var callUUID = _v0.a.a.callUUID;
																						var _v25 = _v0.b;
																						return _Utils_Tuple2(
																							_Utils_update(
																								model,
																								{
																									state: $author$project$Dialer$Types$EnteringQueue(
																										{callUUID: callUUID, campaign: campaign})
																								}),
																							A4($author$project$Dialer$Api$placeCallerInCallQueueCommand, model.env, model.client, model.token, callUUID));
																					case 'DialerInterfaceConnected':
																						var campaign = _v0.a.a.campaign;
																						var callUUID = _v0.a.a.callUUID;
																						var _v26 = _v0.b;
																						return _Utils_Tuple2(
																							_Utils_update(
																								model,
																								{
																									state: $author$project$Dialer$Types$EnteringQueue(
																										{callUUID: callUUID, campaign: campaign})
																								}),
																							A4($author$project$Dialer$Api$placeCallerInCallQueueCommand, model.env, model.client, model.token, callUUID));
																					default:
																						var _v27 = _v0.b;
																						return $author$project$Dialer$State$waitInThisState(model);
																				}
																			case 'CallQueueResponse':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					default:
																						if (_v0.b.a.$ === 'Err') {
																							var error = _v0.b.a.a;
																							return _Utils_Tuple2(
																								_Utils_update(
																									model,
																									{
																										state: $author$project$Dialer$Types$MutationError(error)
																									}),
																								$elm$core$Platform$Cmd$none);
																						} else {
																							return $author$project$Dialer$State$waitInThisState(model);
																						}
																				}
																			case 'ReceivedEvent':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					default:
																						var event = _v0.b.a;
																						var _v28 = _Utils_Tuple2(model.state, event);
																						_v28$16:
																						while (true) {
																							switch (_v28.b.$) {
																								case 'ConnectedEvent':
																									switch (_v28.a.$) {
																										case 'ReadyToPhoneIn':
																											var campaign = _v28.a.a.campaign;
																											var callUUID = _v28.b.a;
																											return _Utils_Tuple2(
																												_Utils_update(
																													model,
																													{
																														state: $author$project$Dialer$Types$DialerInterfaceConnected(
																															{callUUID: callUUID, campaign: campaign})
																													}),
																												A3($author$project$Dialer$Api$recordCallersNameCommand, model, campaign, callUUID));
																										case 'DialerInterfaceLoaded':
																											var campaign = _v28.a.a.campaign;
																											var callUUID = _v28.b.a;
																											return _Utils_Tuple2(
																												_Utils_update(
																													model,
																													{
																														state: $author$project$Dialer$Types$DialerInterfaceConnected(
																															{callUUID: callUUID, campaign: campaign})
																													}),
																												$elm$core$Platform$Cmd$none);
																										default:
																											break _v28$16;
																									}
																								case 'InQueueEvent':
																									if (_v28.a.$ === 'EnteringQueue') {
																										var campaign = _v28.a.a.campaign;
																										var callUUID = _v28.a.a.callUUID;
																										var _v29 = _v28.b;
																										return _Utils_Tuple2(
																											_Utils_update(
																												model,
																												{
																													jokeIndex: $author$project$Dialer$States$InQueue$nextJokeIndex(model.jokeIndex),
																													state: $author$project$Dialer$Types$InQueue(
																														{callUUID: callUUID, campaign: campaign, enteredQueueAt: model.currentTime})
																												}),
																											$elm$core$Platform$Cmd$none);
																									} else {
																										break _v28$16;
																									}
																								case 'InCallEvent':
																									switch (_v28.a.$) {
																										case 'InQueue':
																											var campaign = _v28.a.a.campaign;
																											var eventCallData = _v28.b.a;
																											return A9(
																												$author$project$Dialer$States$InCall$updateWithInCallEvent,
																												model,
																												campaign,
																												_List_Nil,
																												false,
																												eventCallData,
																												$author$project$Dialer$States$InCall$phoneNumberForMessaging(eventCallData),
																												'',
																												_List_Nil,
																												$elm$core$Set$empty);
																										case 'EndingConference':
																											var campaign = _v28.a.a.campaign;
																											var responses = _v28.a.a.responses;
																											var textFieldText = _v28.a.a.textFieldText;
																											var checkboxAnswers = _v28.a.a.checkboxAnswers;
																											var questionKeysWithMessagesSent = _v28.a.a.questionKeysWithMessagesSent;
																											var calleeMessagingPhoneNumber = _v28.a.a.calleeMessagingPhoneNumber;
																											var eventCallData = _v28.b.a;
																											return A9($author$project$Dialer$States$InCall$updateWithInCallEvent, model, campaign, responses, false, eventCallData, calleeMessagingPhoneNumber, textFieldText, checkboxAnswers, questionKeysWithMessagesSent);
																										case 'SubmittingSurveyResponse':
																											var campaign = _v28.a.a.campaign;
																											var responses = _v28.a.a.responses;
																											var surveyEndedEarlyByUser = _v28.a.a.surveyEndedEarlyByUser;
																											var questionKeysWithMessagesSent = _v28.a.a.questionKeysWithMessagesSent;
																											var calleeMessagingPhoneNumber = _v28.a.a.calleeMessagingPhoneNumber;
																											var eventCallData = _v28.b.a;
																											return A9($author$project$Dialer$States$InCall$updateWithInCallEvent, model, campaign, responses, surveyEndedEarlyByUser, eventCallData, calleeMessagingPhoneNumber, '', _List_Nil, questionKeysWithMessagesSent);
																										case 'InCall':
																											var campaign = _v28.a.a.campaign;
																											var responses = _v28.a.a.responses;
																											var surveyEndedEarlyByUser = _v28.a.a.surveyEndedEarlyByUser;
																											var textFieldText = _v28.a.a.textFieldText;
																											var checkboxAnswers = _v28.a.a.checkboxAnswers;
																											var questionKeysWithMessagesSent = _v28.a.a.questionKeysWithMessagesSent;
																											var calleeMessagingPhoneNumber = _v28.a.a.calleeMessagingPhoneNumber;
																											var eventCallData = _v28.b.a;
																											return A9($author$project$Dialer$States$InCall$updateWithInCallEvent, model, campaign, responses, surveyEndedEarlyByUser, eventCallData, calleeMessagingPhoneNumber, textFieldText, checkboxAnswers, questionKeysWithMessagesSent);
																										case 'CallCompleteAndSurveyComplete':
																											return $author$project$Dialer$State$waitInThisState(model);
																										default:
																											break _v28$16;
																									}
																								case 'CallerCompleteEvent':
																									var _v30 = _v28.b;
																									return _Utils_Tuple2(
																										_Utils_update(
																											model,
																											{state: $author$project$Dialer$Types$SessionComplete}),
																										$elm$core$Platform$Cmd$none);
																								case 'CampaignUpdateEvent':
																									var campaignUpdated = _v28.b.a;
																									return _Utils_Tuple2(
																										_Utils_update(
																											model,
																											{campaignLastUpdate: campaignUpdated}),
																										$elm$core$Platform$Cmd$none);
																								case 'CampaignAlertsUpdateEvent':
																									var _v31 = _v28.b;
																									return _Utils_Tuple2(
																										model,
																										A3($author$project$Dialer$Api$requestCampaignAlertsFromApi, model.env, model.client, model.token));
																								case 'NoAssetsEvent':
																									var _v32 = _v28.b;
																									return _Utils_Tuple2(
																										_Utils_update(
																											model,
																											{
																												state: $author$project$Dialer$Types$ErrorWithFeedback('Network error: unable to load assets')
																											}),
																										$elm$core$Platform$Cmd$none);
																								case 'ConnectionLostEvent':
																									var message = _v28.b.a;
																									return _Utils_Tuple2(
																										_Utils_update(
																											model,
																											{
																												state: $author$project$Dialer$Types$ErrorWithFeedback(message)
																											}),
																										$elm$core$Platform$Cmd$none);
																								case 'ErrorEvent':
																									if (_v28.b.a.$ === 'Just') {
																										var message = _v28.b.a.a;
																										return _Utils_Tuple2(
																											_Utils_update(
																												model,
																												{
																													state: $author$project$Dialer$Types$ErrorWithFeedback(message)
																												}),
																											$elm$core$Platform$Cmd$none);
																									} else {
																										break _v28$16;
																									}
																								default:
																									if (_v28.a.$ === 'SubmittingPhoneNumber') {
																										var campaign = _v28.a.a.campaign;
																										var phoneIn = _v28.a.a.phoneIn;
																										var callersName = _v28.a.a.callersName;
																										var callersPhoneNumber = _v28.a.a.callersPhoneNumber;
																										var appId = _v28.b.a.appId;
																										return phoneIn ? _Utils_Tuple2(
																											_Utils_update(
																												model,
																												{
																													state: $author$project$Dialer$Types$ReadyToPhoneIn(
																														{callersName: callersName, callersPhoneNumber: callersPhoneNumber, campaign: campaign})
																												}),
																											A3($author$project$Dialer$Api$registerPusherChannelCommand, model, callersPhoneNumber, appId)) : _Utils_Tuple2(
																											_Utils_update(
																												model,
																												{
																													state: $author$project$Dialer$Types$DialerInterfaceLoaded(
																														{campaign: campaign})
																												}),
																											$elm$core$Platform$Cmd$none);
																									} else {
																										return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
																									}
																							}
																						}
																						return _Utils_Tuple2(
																							_Utils_update(
																								model,
																								{state: $author$project$Dialer$Types$ErrorOccurred}),
																							$author$project$Dialer$Ports$sendErrorMessage('Unexpected event and state occurred'));
																				}
																			case 'ReceiveEventFailed':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					default:
																						var failedMsg = _v0.b.a;
																						return _Utils_Tuple2(
																							_Utils_update(
																								model,
																								{state: $author$project$Dialer$Types$ErrorOccurred}),
																							$author$project$Dialer$Ports$sendErrorMessage('Error parsing event: ' + failedMsg));
																				}
																			case 'EndConferenceClicked':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					case 'InCall':
																						var callState = _v0.a.a;
																						var _v33 = _v0.b;
																						return _Utils_Tuple2(
																							_Utils_update(
																								model,
																								{
																									state: $author$project$Dialer$Types$EndingConference(callState)
																								}),
																							A5($author$project$Dialer$Api$endConferenceCommand, model.env, model.client, model.token, callState.callUUID, callState.callID));
																					default:
																						var _v34 = _v0.b;
																						return $author$project$Dialer$State$waitInThisState(model);
																				}
																			case 'ToggleSidebarClicked':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					default:
																						var _v36 = _v0.b;
																						return _Utils_Tuple2(
																							_Utils_update(
																								model,
																								{sidebarOpen: !model.sidebarOpen}),
																							$elm$core$Platform$Cmd$none);
																				}
																			case 'EndSurveyClicked':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					case 'InCall':
																						var callState = _v0.a.a;
																						var _v35 = _v0.b;
																						return A2($author$project$Dialer$States$InCall$updateWithEndSurveyClicked, model, callState);
																					default:
																						var _v37 = _v0.b;
																						return $author$project$Dialer$State$waitInThisState(model);
																				}
																			case 'EndConferenceResponse':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					default:
																						if (_v0.b.a.$ === 'Err') {
																							var error = _v0.b.a.a;
																							return _Utils_Tuple2(
																								_Utils_update(
																									model,
																									{
																										state: $author$project$Dialer$Types$MutationError(error)
																									}),
																								$elm$core$Platform$Cmd$none);
																						} else {
																							return $author$project$Dialer$State$waitInThisState(model);
																						}
																				}
																			case 'SurveyResultSingleResponseClicked':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					case 'InCall':
																						var callState = _v0.a.a;
																						var _v38 = _v0.b;
																						var question = _v38.a;
																						var answer = _v38.b;
																						return A3(
																							$author$project$Dialer$States$InCall$updateWithClickedSingleResponseResult,
																							model,
																							callState,
																							_Utils_Tuple2(question, answer));
																					default:
																						var _v39 = _v0.b;
																						return $author$project$Dialer$State$waitInThisState(model);
																				}
																			case 'CaptureTextFieldAnswer':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					case 'InCall':
																						var callState = _v0.a.a;
																						var updatedText = _v0.b.a;
																						return _Utils_Tuple2(
																							_Utils_update(
																								model,
																								{
																									state: $author$project$Dialer$Types$InCall(
																										_Utils_update(
																											callState,
																											{textFieldText: updatedText}))
																								}),
																							$elm$core$Platform$Cmd$none);
																					default:
																						break _v0$72;
																				}
																			case 'SubmitTextFieldAnswer':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					case 'InCall':
																						var callState = _v0.a.a;
																						var _v40 = _v0.b;
																						var question = _v40.a;
																						var updatedText = _v40.b;
																						return A4($author$project$Dialer$States$InCall$updateWithTextAnswerSubmitted, model, callState, question, updatedText);
																					default:
																						var _v41 = _v0.b;
																						return $author$project$Dialer$State$waitInThisState(model);
																				}
																			case 'SubmitNullAnswer':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					case 'InCall':
																						var callState = _v0.a.a;
																						var question = _v0.b.a;
																						return A3(
																							$author$project$Dialer$States$InCall$updateWithNullResponse,
																							model,
																							_Utils_update(
																								callState,
																								{sendMessageError: $elm$core$Maybe$Nothing}),
																							question);
																					default:
																						break _v0$72;
																				}
																			case 'GoToNextQuestion':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					case 'InCall':
																						var callState = _v0.a.a;
																						var _v42 = _v0.b;
																						return A2($author$project$Dialer$States$InCall$goToNextQuestion, model, callState);
																					default:
																						break _v0$72;
																				}
																			case 'SurveyResultMultipleResponseClicked':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					case 'InCall':
																						var callState = _v0.a.a;
																						var _v43 = _v0.b;
																						var question = _v43.a;
																						var answer = _v43.b;
																						return A3(
																							$author$project$Dialer$States$InCall$updateWithClickedMultipleReponseResult,
																							model,
																							callState,
																							_Utils_Tuple2(question, answer));
																					default:
																						var _v44 = _v0.b;
																						return $author$project$Dialer$State$waitInThisState(model);
																				}
																			case 'SubmitMultipleResponseAnswers':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					case 'InCall':
																						var callState = _v0.a.a;
																						var _v45 = _v0.b;
																						var question = _v45.a;
																						var answers = _v45.b;
																						return A3(
																							$author$project$Dialer$States$InCall$updateWithMultipleResponseAnswersSubmitted,
																							model,
																							callState,
																							_Utils_Tuple2(question, answers));
																					default:
																						break _v0$72;
																				}
																			case 'UndoSurveyResponseClicked':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					case 'InCall':
																						var callState = _v0.a.a;
																						var _v46 = _v0.b;
																						return A2($author$project$Dialer$States$InCall$updateWithUndoAnswer, model, callState);
																					case 'CallCompleteAndSurveyComplete':
																						var callState = _v0.a.a;
																						var _v47 = _v0.b;
																						return A2($author$project$Dialer$States$InCall$updateWithUndoAnswer, model, callState);
																					default:
																						var _v48 = _v0.b;
																						return $author$project$Dialer$State$waitInThisState(model);
																				}
																			case 'CaptureCalleeMessagingPhoneNumber':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					case 'InCall':
																						var callState = _v0.a.a;
																						var calleeMessagingPhoneNumber = _v0.b.a;
																						return _Utils_Tuple2(
																							_Utils_update(
																								model,
																								{
																									state: $author$project$Dialer$Types$InCall(
																										_Utils_update(
																											callState,
																											{calleeMessagingPhoneNumber: calleeMessagingPhoneNumber}))
																								}),
																							$elm$core$Platform$Cmd$none);
																					default:
																						break _v0$72;
																				}
																			case 'CaptureCalleeAddress':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					case 'InCall':
																						var callState = _v0.a.a;
																						var address = _v0.b.a;
																						return _Utils_Tuple2(
																							_Utils_update(
																								model,
																								{
																									state: $author$project$Dialer$Types$InCall(
																										_Utils_update(
																											callState,
																											{
																												calleeAddress: $elm$core$Maybe$Just(address)
																											}))
																								}),
																							$elm$core$Platform$Cmd$none);
																					default:
																						break _v0$72;
																				}
																			case 'CaptureCalleeZip':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					case 'InCall':
																						var callState = _v0.a.a;
																						var zip = _v0.b.a;
																						return _Utils_Tuple2(
																							_Utils_update(
																								model,
																								{
																									state: $author$project$Dialer$Types$InCall(
																										_Utils_update(
																											callState,
																											{
																												calleeZip: $elm$core$Maybe$Just(zip)
																											}))
																								}),
																							$elm$core$Platform$Cmd$none);
																					default:
																						break _v0$72;
																				}
																			case 'ConfirmPollingPlaceLookupAddress':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					case 'InCall':
																						var callState = _v0.a.a;
																						var _v49 = _v0.b;
																						var _v50 = _Utils_Tuple3(callState.nextQuestion, callState.calleeAddress, callState.calleeZip);
																						if (((_v50.a.$ === 'Just') && (_v50.b.$ === 'Just')) && (_v50.c.$ === 'Just')) {
																							var question = _v50.a.a;
																							var address = _v50.b.a;
																							var zip = _v50.c.a;
																							return A3(
																								$author$project$Dialer$States$InCall$updateWithPollingPlaceLookupAnswerSubmitted,
																								model,
																								callState,
																								_Utils_Tuple2(
																									question,
																									A2($author$project$Dialer$Types$PollingPlaceLookupAddress, address, zip)));
																						} else {
																							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
																						}
																					default:
																						break _v0$72;
																				}
																			case 'SendMessageClicked':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					case 'InCall':
																						var callState = _v0.a.a;
																						var _v51 = _v0.b;
																						var questionKey = _v51.a;
																						var text = _v51.b;
																						return _Utils_Tuple2(
																							_Utils_update(
																								model,
																								{
																									state: $author$project$Dialer$Types$InCall(
																										_Utils_update(
																											callState,
																											{messageSubmitting: true, sendMessageError: $elm$core$Maybe$Nothing}))
																								}),
																							A4($author$project$Dialer$Api$sendMessageCommand, model, callState, questionKey, text));
																					default:
																						var _v52 = _v0.b;
																						return $author$project$Dialer$State$waitInThisState(model);
																				}
																			case 'SendMessageResponse':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					case 'InCall':
																						var callState = _v0.a.a;
																						var result = _v0.b.a;
																						if (result.$ === 'Ok') {
																							var _v54 = callState.nextQuestion;
																							if (_v54.$ === 'Just') {
																								var question = _v54.a;
																								return A3(
																									$author$project$Dialer$States$InCall$updateWithNullResponse,
																									model,
																									_Utils_update(
																										callState,
																										{
																											messageSubmitting: false,
																											questionKeysWithMessagesSent: A2($elm$core$Set$insert, question.key, callState.questionKeysWithMessagesSent)
																										}),
																									question);
																							} else {
																								return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
																							}
																						} else {
																							var error = result.a;
																							return _Utils_Tuple2(
																								_Utils_update(
																									model,
																									{
																										state: $author$project$Dialer$Types$InCall(
																											_Utils_update(
																												callState,
																												{
																													messageSubmitting: false,
																													sendMessageError: $elm$core$Maybe$Just(
																														$author$project$Utils$gqlErrorMessage(error))
																												}))
																									}),
																								$elm$core$Platform$Cmd$none);
																						}
																					default:
																						break _v0$72;
																				}
																			case 'SurveyResultResponse':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					default:
																						if (_v0.b.a.$ === 'Err') {
																							var error = _v0.b.a.a;
																							return _Utils_Tuple2(
																								_Utils_update(
																									model,
																									{
																										state: $author$project$Dialer$Types$MutationError(error)
																									}),
																								$elm$core$Platform$Cmd$none);
																						} else {
																							return $author$project$Dialer$State$waitInThisState(model);
																						}
																				}
																			case 'ContinueCallingClicked':
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					case 'CallCompleteAndSurveyComplete':
																						var campaign = _v0.a.a.campaign;
																						var callUUID = _v0.a.a.callUUID;
																						var _v55 = _v0.b;
																						return A3($author$project$Dialer$State$checkForUpdatedCampaign, model, campaign, callUUID);
																					default:
																						var _v56 = _v0.b;
																						return $author$project$Dialer$State$waitInThisState(model);
																				}
																			default:
																				switch (_v0.a.$) {
																					case 'ErrorWithFeedback':
																						break _v0$2;
																					case 'ErrorOccurred':
																						break _v0$3;
																					case 'MutationError':
																						break _v0$4;
																					case 'SessionComplete':
																						break _v0$5;
																					case 'ConfirmLastCallInMethod':
																						break _v0$23;
																					default:
																						break _v0$72;
																				}
																		}
																	}
																	return _Utils_Tuple2(
																		_Utils_update(
																			model,
																			{state: $author$project$Dialer$Types$ErrorOccurred}),
																		$author$project$Dialer$Ports$sendErrorMessage('Unexpected state transition occurred'));
																}
																var campaign = _v0.a.a.campaign;
																var _v18 = A2($author$project$Dialer$States$Login$validCallerInfo, campaign, model.caller);
																if (_v18.$ === 'Just') {
																	var _v19 = _v18.a;
																	var phone = _v19.a;
																	var name = _v19.b;
																	var email = _v19.c;
																	return _Utils_Tuple2(
																		_Utils_update(
																			model,
																			{
																				state: $author$project$Dialer$Types$SubmittingPhoneNumber(
																					{
																						callersEmail: email,
																						callersName: name,
																						callersPhoneNumber: phone,
																						campaign: campaign,
																						phoneIn: _Utils_eq(msg, $author$project$Dialer$Types$CallWithPhoneClicked)
																					})
																			}),
																		A6(
																			$author$project$Dialer$State$connectCommands,
																			model,
																			campaign,
																			_Utils_eq(msg, $author$project$Dialer$Types$CallWithPhoneClicked),
																			phone,
																			name,
																			email));
																} else {
																	return _Utils_Tuple2(
																		_Utils_update(
																			model,
																			{state: $author$project$Dialer$Types$ErrorOccurred}),
																		$author$project$Dialer$Ports$sendErrorMessage('Invalid saved caller details'));
																}
															}
															var _v10 = _v0.b;
															return _Utils_Tuple2(
																_Utils_update(
																	model,
																	{state: $author$project$Dialer$Types$LoadingCampaign}),
																$author$project$InteropPorts$fromElm(
																	$author$project$InteropDefinitions$ResetPortMessage(
																		{})));
														}
														var _v9 = _v0.b;
														return _Utils_Tuple2(
															_Utils_update(
																model,
																{state: $author$project$Dialer$Types$LoadingCampaign}),
															A3($author$project$Dialer$Api$requestCampaignFromApi, model.env, model.client, model.token));
													}
													var error = _v0.b.a.a;
													return _Utils_Tuple2(
														model,
														$author$project$Dialer$Ports$sendErrorMessage(
															$author$project$Utils$gqlErrorMessage(error)));
												}
												var campaignAlerts = _v0.b.a.a;
												return _Utils_Tuple2(
													_Utils_update(
														model,
														{
															campaignAlerts: $elm$core$Maybe$Just(campaignAlerts)
														}),
													$elm$core$Platform$Cmd$none);
											}
											var error = _v0.b.a.a;
											if (((error.$ === 'GraphqlError') && error.b.b) && (!error.b.b.b)) {
												var _v7 = error.b;
												var message = _v7.a.message;
												if (message === 'No Campaign exists with this token') {
													return _Utils_Tuple2(
														_Utils_update(
															model,
															{state: $author$project$Dialer$Types$NoMatchingCampaignForToken}),
														$elm$core$Platform$Cmd$none);
												} else {
													return _Utils_Tuple2(
														_Utils_update(
															model,
															{
																state: $author$project$Dialer$Types$CampaignLoadError(error)
															}),
														$elm$core$Platform$Cmd$none);
												}
											} else {
												return _Utils_Tuple2(
													_Utils_update(
														model,
														{
															state: $author$project$Dialer$Types$CampaignLoadError(error)
														}),
													$elm$core$Platform$Cmd$none);
											}
										}
										var campaign = _v0.b.a.a;
										var _v5 = model.state;
										switch (_v5.$) {
											case 'LoadingCampaign':
												return A2(determineCallMethodsToDisplayBasedOnSavedPreferences, model.caller, campaign);
											case 'RefreshCampaign':
												var callUUID = _v5.a.callUUID;
												return _Utils_Tuple2(
													_Utils_update(
														model,
														{
															state: $author$project$Dialer$Types$EnteringQueue(
																{callUUID: callUUID, campaign: campaign})
														}),
													A4($author$project$Dialer$Api$placeCallerInCallQueueCommand, model.env, model.client, model.token, callUUID));
											default:
												return _Utils_Tuple2(
													_Utils_update(
														model,
														{state: $author$project$Dialer$Types$ErrorOccurred}),
													$author$project$Dialer$Ports$sendErrorMessage('Unexpected state transition occurred'));
										}
									}
									var channelId = _v0.b.a;
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{callerChannelId: channelId}),
										$elm$core$Platform$Cmd$none);
								}
								var shuffledJokes = _v0.b.a;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{shuffledJokes: shuffledJokes}),
									$elm$core$Platform$Cmd$none);
							}
							return _Utils_Tuple2(
								model,
								A3($author$project$Dialer$Api$requestCampaignAlertsFromApi, model.env, model.client, model.token));
						}
						var timestamp = _v0.b.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{currentTime: timestamp}),
							$elm$core$Platform$Cmd$none);
					}
					var _v4 = _v0.a;
					return $author$project$Dialer$State$waitInThisState(model);
				}
				return $author$project$Dialer$State$waitInThisState(model);
			}
			var _v3 = _v0.a;
			return $author$project$Dialer$State$waitInThisState(model);
		}
		return $author$project$Dialer$State$waitInThisState(model);
	});
var $author$project$Dialer$State$update = F2(
	function (msg, model) {
		var _v0 = A2($author$project$Dialer$State$updateWithSensitiveDataRemoved, msg, model);
		var updatedModel = _v0.a;
		var cmd = _v0.b;
		switch (msg.$) {
			case 'CaptureCallerName':
				return _Utils_Tuple2(updatedModel, cmd);
			case 'CapturePhoneNumber':
				return _Utils_Tuple2(updatedModel, cmd);
			case 'CaptureCallerEmail':
				return _Utils_Tuple2(updatedModel, cmd);
			default:
				return _Utils_Tuple2(
					updatedModel,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								cmd,
								A3($author$project$Dialer$Ports$sendBreadCrumbMessage, msg, model, updatedModel)
							])));
		}
	});
var $author$project$Main$update = F2(
	function (msg, model) {
		var _v0 = _Utils_Tuple2(msg, model);
		_v0$2:
		while (true) {
			if (_v0.a.$ === 'GotClickToCallMsg') {
				if (_v0.b.$ === 'ClickToCall') {
					var appMsg = _v0.a.a;
					var appModel = _v0.b.a;
					return A3(
						$author$project$Main$updateWith,
						$author$project$Main$ClickToCall,
						$author$project$Main$GotClickToCallMsg,
						A2($author$project$ClickToCall$State$update, appMsg, appModel));
				} else {
					break _v0$2;
				}
			} else {
				if (_v0.b.$ === 'Dialer') {
					var appMsg = _v0.a.a;
					var appModel = _v0.b.a;
					return A3(
						$author$project$Main$updateWith,
						$author$project$Main$Dialer,
						$author$project$Main$GotDialerMsg,
						A2($author$project$Dialer$State$update, appMsg, appModel));
				} else {
					break _v0$2;
				}
			}
		}
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$html$Html$img = _VirtualDom_node('img');
var $author$project$Utils$logoUrl = 'assets/alt-logo.svg';
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$Main$renderMessage = function (message) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('top-level-container')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('container')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('header')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$img,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$src($author$project$Utils$logoUrl),
										$elm$html$Html$Attributes$class('logo')
									]),
								_List_Nil)
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('instructions-container')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('instructions')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(message)
							]))
					]))
			]));
};
var $author$project$ClickToCall$View$Action = function (a) {
	return {$: 'Action', a: a};
};
var $author$project$ClickToCall$Types$ApproveNextCallee = {$: 'ApproveNextCallee'};
var $author$project$ClickToCall$Types$CheckForCalleesNeedingApproval = {$: 'CheckForCalleesNeedingApproval'};
var $author$project$ClickToCall$View$NoAction = {$: 'NoAction'};
var $author$project$ClickToCall$Types$UploadApprovals = {$: 'UploadApprovals'};
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 'Normal', a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$ClickToCall$View$renderUI = F3(
	function (status, action, campaignName) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('top-level-container-clicker')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('header')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$src($author$project$Utils$logoUrl),
											$elm$html$Html$Attributes$class('logo')
										]),
									_List_Nil),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('campaign-heading')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$h1,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(
													A2($elm$core$Maybe$withDefault, '', campaignName))
												]))
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('instructions-container')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('instructions')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(status)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('actions-container')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('actions')
										]),
									function () {
										if (action.$ === 'NoAction') {
											return _List_Nil;
										} else {
											var msg = action.a.msg;
											var label = action.a.label;
											return _List_fromArray(
												[
													A2(
													$elm$html$Html$button,
													_List_fromArray(
														[
															$elm$html$Html$Events$onClick(msg)
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(label)
														]))
												]);
										}
									}())
								]))
						]))
				]));
	});
var $author$project$ClickToCall$View$view = function (model) {
	var _v0 = function () {
		var _v1 = model.state;
		switch (_v1.$) {
			case 'ReadyToGetStarted':
				return _Utils_Tuple2(
					'Ready to approve numbers to call?',
					$author$project$ClickToCall$View$Action(
						{label: 'Request numbers', msg: $author$project$ClickToCall$Types$CheckForCalleesNeedingApproval}));
			case 'LoadingAssignments':
				return _Utils_Tuple2('Requesting assignments...', $author$project$ClickToCall$View$NoAction);
			case 'NoMatchingCampaignForToken':
				return _Utils_Tuple2('Invalid token. Perhaps it has expired? Please re-request the link from your administrator.', $author$project$ClickToCall$View$NoAction);
			case 'AssignmentsNetworkError':
				return _Utils_Tuple2(
					'Error getting assignments',
					$author$project$ClickToCall$View$Action(
						{label: 'Retry', msg: $author$project$ClickToCall$Types$CheckForCalleesNeedingApproval}));
			case 'AssignmentsLoaded':
				var _v2 = model.assigned;
				if (_v2.b) {
					var callee = _v2.a;
					return _Utils_Tuple2(
						'Approved ' + ($elm$core$String$fromInt(
							$elm$core$List$length(model.approved)) + (' of ' + ($elm$core$String$fromInt(
							$elm$core$List$length(model.approved) + $elm$core$List$length(model.assigned)) + ' assigned callees'))),
						$author$project$ClickToCall$View$Action(
							{label: 'Approve ID: ' + callee, msg: $author$project$ClickToCall$Types$ApproveNextCallee}));
				} else {
					return _Utils_Tuple2(
						'No callees require approval at this time. Job well done!',
						$author$project$ClickToCall$View$Action(
							{label: 'Check again', msg: $author$project$ClickToCall$Types$CheckForCalleesNeedingApproval}));
				}
			case 'UploadingApprovals':
				return _Utils_Tuple2('Uploading approvals...', $author$project$ClickToCall$View$NoAction);
			case 'ApprovalsNetworkError':
				return _Utils_Tuple2(
					'Error uploading approvals',
					$author$project$ClickToCall$View$Action(
						{label: 'Retry', msg: $author$project$ClickToCall$Types$UploadApprovals}));
			default:
				return _Utils_Tuple2(
					'Nice work!',
					$author$project$ClickToCall$View$Action(
						{label: 'Keep approving assignments', msg: $author$project$ClickToCall$Types$CheckForCalleesNeedingApproval}));
		}
	}();
	var message = _v0.a;
	var action = _v0.b;
	return A3($author$project$ClickToCall$View$renderUI, message, action, model.campaignName);
};
var $author$project$Dialer$Types$Action = function (a) {
	return {$: 'Action', a: a};
};
var $author$project$Dialer$Types$CallAnotherWay = {$: 'CallAnotherWay'};
var $author$project$Dialer$Types$RetryLoadingApp = {$: 'RetryLoadingApp'};
var $author$project$Dialer$Types$StartCallingClicked = {$: 'StartCallingClicked'};
var $author$project$Dialer$Types$Alert = F2(
	function (message, requiresReload) {
		return {message: message, requiresReload: requiresReload};
	});
var $author$project$Dialer$Views$Helpers$activeCallerAlert = F2(
	function (campaignAlerts, currentTime) {
		var messageExpiry = A2(
			$elm$core$Maybe$andThen,
			function (str) {
				return $elm$core$Result$toMaybe(
					$rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(str));
			},
			A2(
				$elm$core$Maybe$andThen,
				function (alerts) {
					return alerts.callerAlertMessage.expiresAt;
				},
				campaignAlerts));
		var messageIsExpired = A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				function (expiry) {
					return _Utils_cmp(
						$elm$time$Time$posixToMillis(expiry),
						$elm$time$Time$posixToMillis(currentTime)) < 0;
				},
				messageExpiry));
		return messageIsExpired ? $elm$core$Maybe$Nothing : A2(
			$elm$core$Maybe$andThen,
			function (message) {
				return $elm$core$Maybe$Just(
					A2($author$project$Dialer$Types$Alert, message, false));
			},
			A2(
				$elm$core$Maybe$andThen,
				function (alerts) {
					return alerts.callerAlertMessage.message;
				},
				campaignAlerts));
	});
var $author$project$Dialer$View$minutesAgo = F3(
	function (model, minutes, time) {
		return _Utils_cmp(
			$elm$time$Time$posixToMillis(time) + (minutes * 60000),
			$elm$time$Time$posixToMillis(model.currentTime)) < 0;
	});
var $author$project$Dialer$View$campaignUpdatedAlert = F3(
	function (campaign, model, enteredQueueAt) {
		var reloadRequired = A2($author$project$Dialer$State$campaignRequiresUpdate, campaign, model) && $author$project$Dialer$State$campaignUpdateRequiresReload(model);
		var moreThan3MinutesAgo = A2($author$project$Dialer$View$minutesAgo, model, 3);
		return (reloadRequired && moreThan3MinutesAgo(enteredQueueAt)) ? $elm$core$Maybe$Just(
			A2($author$project$Dialer$Types$Alert, 'Campaign has been updated', true)) : $elm$core$Maybe$Nothing;
	});
var $author$project$Dialer$View$alertForCampaign = F3(
	function (campaign, model, enteredQueueAt) {
		var _v0 = A3($author$project$Dialer$View$campaignUpdatedAlert, campaign, model, enteredQueueAt);
		if (_v0.$ === 'Just') {
			var alert = _v0.a;
			return $elm$core$Maybe$Just(alert);
		} else {
			return A2($author$project$Dialer$Views$Helpers$activeCallerAlert, model.campaignAlerts, model.currentTime);
		}
	});
var $author$project$Dialer$Views$Helpers$primaryAction = F2(
	function (msg, label) {
		return $author$project$Dialer$Types$Action(
			{actionClass: 'primary', context: $elm$core$Maybe$Nothing, label: label, msg: msg});
	});
var $author$project$Dialer$Types$CaptureCallerName = function (a) {
	return {$: 'CaptureCallerName', a: a};
};
var $author$project$Dialer$Types$CapturePhoneNumber = function (a) {
	return {$: 'CapturePhoneNumber', a: a};
};
var $author$project$Dialer$Types$SubmitPhoneNumberClicked = {$: 'SubmitPhoneNumberClicked'};
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $author$project$Dialer$Views$Helpers$addTestLabelFromMsg = A2(
	$elm$core$Basics$composeL,
	$elm$html$Html$Attributes$attribute('data-cmd'),
	$elm$core$Debug$toString);
var $author$project$Dialer$Views$Helpers$callerFieldsInvalid = F4(
	function (campaign, phone, name, email) {
		return ($elm$core$String$length(phone) < 10) || (($elm$core$String$length(name) < 3) || (campaign.collect_caller_email && ($elm$core$String$length(email) < 3)));
	});
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $author$project$Utils$phoneNumberRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\d{1,3}){0,1}(\\d{1,3}){0,1}(\\d{1,4}){0,1}'));
var $author$project$Utils$phoneNumberRegexAu = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('61(\\d)(\\d{4})(\\d{4})'));
var $author$project$Utils$phoneNumberReplacer = function (_v0) {
	var match = _v0.match;
	var submatches = _v0.submatches;
	_v1$2:
	while (true) {
		if ((((submatches.b && (submatches.a.$ === 'Just')) && submatches.b.b) && (submatches.b.a.$ === 'Just')) && submatches.b.b.b) {
			if (submatches.b.b.a.$ === 'Nothing') {
				if (!submatches.b.b.b.b) {
					var m1 = submatches.a.a;
					var _v2 = submatches.b;
					var m2 = _v2.a.a;
					var _v3 = _v2.b;
					var _v4 = _v3.a;
					return '(' + (m1 + (') ' + m2));
				} else {
					break _v1$2;
				}
			} else {
				if (!submatches.b.b.b.b) {
					var m1 = submatches.a.a;
					var _v5 = submatches.b;
					var m2 = _v5.a.a;
					var _v6 = _v5.b;
					var m3 = _v6.a.a;
					return '(' + (m1 + (') ' + (m2 + ('-' + m3))));
				} else {
					break _v1$2;
				}
			}
		} else {
			break _v1$2;
		}
	}
	return match;
};
var $author$project$Utils$phoneNumberReplacerAu = function (_v0) {
	var match = _v0.match;
	var submatches = _v0.submatches;
	if ((((((submatches.b && (submatches.a.$ === 'Just')) && submatches.b.b) && (submatches.b.a.$ === 'Just')) && submatches.b.b.b) && (submatches.b.b.a.$ === 'Just')) && (!submatches.b.b.b.b)) {
		var areaCode = submatches.a.a;
		var _v2 = submatches.b;
		var n1 = _v2.a.a;
		var _v3 = _v2.b;
		var n2 = _v3.a.a;
		return '(0' + (areaCode + (') ' + (n1 + ('-' + n2))));
	} else {
		return match;
	}
};
var $author$project$Utils$formatNumber = function (countryIso) {
	if (countryIso === 'AU') {
		return A2(
			$elm$core$Basics$composeR,
			$author$project$Utils$cleanNumber,
			A2(
				$elm$core$Basics$composeR,
				A2($elm$regex$Regex$replace, $author$project$Utils$phoneNumberRegexAu, $author$project$Utils$phoneNumberReplacerAu),
				$elm$core$String$left(14)));
	} else {
		return A2(
			$elm$core$Basics$composeR,
			$author$project$Utils$cleanNumber,
			A2(
				$elm$core$Basics$composeR,
				A2($elm$regex$Regex$replace, $author$project$Utils$phoneNumberRegex, $author$project$Utils$phoneNumberReplacer),
				$elm$core$String$left(14)));
	}
};
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$Attributes$name = $elm$html$Html$Attributes$stringProperty('name');
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 'MayStopPropagation', a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $author$project$Dialer$Types$CaptureCallerEmail = function (a) {
	return {$: 'CaptureCallerEmail', a: a};
};
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Dialer$Views$Login$renderEmailInput = F2(
	function (campaign, callersEmail) {
		return campaign.collect_caller_email ? _List_fromArray(
			[
				$elm$html$Html$text('Email'),
				A2(
				$elm$html$Html$input,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$name('email'),
						$elm$html$Html$Attributes$value(callersEmail),
						$elm$html$Html$Events$onInput($author$project$Dialer$Types$CaptureCallerEmail)
					]),
				_List_Nil)
			]) : _List_Nil;
	});
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $author$project$Dialer$Views$Helpers$emptyDiv = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			A2($elm$html$Html$Attributes$style, 'display', 'none')
		]),
	_List_Nil);
var $author$project$Dialer$Types$ReportIssue = {$: 'ReportIssue'};
var $elm$html$Html$a = _VirtualDom_node('a');
var $author$project$Dialer$Views$Images$logo = A2(
	$elm$html$Html$img,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$src($author$project$Utils$logoUrl),
			$elm$html$Html$Attributes$class('logo')
		]),
	_List_Nil);
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $elm$html$Html$p = _VirtualDom_node('p');
var $elm$html$Html$Attributes$rel = _VirtualDom_attribute('rel');
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$html$Html$Attributes$target = $elm$html$Html$Attributes$stringProperty('target');
var $author$project$Dialer$Views$AlertParser$Plaintext = function (a) {
	return {$: 'Plaintext', a: a};
};
var $author$project$Dialer$Views$AlertParser$Url = function (a) {
	return {$: 'Url', a: a};
};
var $elm$parser$Parser$Expecting = function (a) {
	return {$: 'Expecting', a: a};
};
var $elm$parser$Parser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $elm$parser$Parser$token = function (str) {
	return $elm$parser$Parser$Advanced$token(
		$elm$parser$Parser$toToken(str));
};
var $author$project$Dialer$Views$AlertParser$tokenParser = function () {
	var tokenParserHelper = function (chunks) {
		var notToken = A2(
			$elm$parser$Parser$map,
			$author$project$Dialer$Views$AlertParser$Plaintext,
			$elm$parser$Parser$getChompedString(
				A2(
					$elm$parser$Parser$keeper,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompIf(
						function (_v1) {
							return true;
						}))));
		var isNonAlphaUrlChar = function (c) {
			return A2(
				$elm$core$List$member,
				c,
				_List_fromArray(
					[
						_Utils_chr('-'),
						_Utils_chr('.'),
						_Utils_chr('_'),
						_Utils_chr('~'),
						_Utils_chr(':'),
						_Utils_chr('/'),
						_Utils_chr('?'),
						_Utils_chr('#'),
						_Utils_chr('['),
						_Utils_chr(']'),
						_Utils_chr('@'),
						_Utils_chr('!'),
						_Utils_chr('$'),
						_Utils_chr('&'),
						_Utils_chr('\''),
						_Utils_chr('('),
						_Utils_chr(')'),
						_Utils_chr('*'),
						_Utils_chr('+'),
						_Utils_chr(';'),
						_Utils_chr('%'),
						_Utils_chr('=')
					]));
		};
		var finalizeSegments = function (_v0) {
			return $elm$parser$Parser$Done(
				$elm$core$List$reverse(chunks));
		};
		var chompWhileUrlCharacter = $elm$parser$Parser$chompWhile(
			function (c) {
				return $elm$core$Char$isAlphaNum(c) || isNonAlphaUrlChar(c);
			});
		var linkWithScheme = function (scheme) {
			var mapUrl = function (urlWithoutScheme) {
				return $author$project$Dialer$Views$AlertParser$Url(
					_Utils_ap(scheme, urlWithoutScheme));
			};
			return A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$token(scheme)),
				A2(
					$elm$parser$Parser$map,
					mapUrl,
					$elm$parser$Parser$getChompedString(chompWhileUrlCharacter)));
		};
		var appendSegment = function (chunk) {
			return $elm$parser$Parser$Loop(
				A2($elm$core$List$cons, chunk, chunks));
		};
		return $elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$map,
					appendSegment,
					linkWithScheme('https')),
					A2(
					$elm$parser$Parser$map,
					appendSegment,
					linkWithScheme('http')),
					A2($elm$parser$Parser$map, appendSegment, notToken),
					A2($elm$parser$Parser$map, finalizeSegments, $elm$parser$Parser$end)
				]));
	};
	return A2($elm$parser$Parser$loop, _List_Nil, tokenParserHelper);
}();
var $author$project$Dialer$Views$AlertParser$formatAlert = function (script) {
	var endsWithPuctuation = function (str) {
		return A2(
			$elm$core$List$member,
			A2($elm$core$String$right, 1, str),
			_List_fromArray(
				['.', '?', '!', ':', ')', ';']));
	};
	var link = function (url) {
		return endsWithPuctuation(url) ? _Utils_ap(
			link(
				A2($elm$core$String$dropRight, 1, url)),
			_List_fromArray(
				[
					$elm$html$Html$text(
					A2($elm$core$String$right, 1, url))
				])) : _List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href(url),
						$elm$html$Html$Attributes$target('_blank'),
						$elm$html$Html$Attributes$rel('noopener noreferrer')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(url)
					]))
			]);
	};
	var alertSegmentToHtml = function (segment) {
		if (segment.$ === 'Plaintext') {
			var textSegment = segment.a;
			return $elm$html$Html$text(textSegment);
		} else {
			var url = segment.a;
			return A2(
				$elm$html$Html$span,
				_List_Nil,
				link(url));
		}
	};
	var substituteTokens = function (scriptWithoutSubstitions) {
		var _v0 = A2($elm$parser$Parser$run, $author$project$Dialer$Views$AlertParser$tokenParser, scriptWithoutSubstitions);
		if (_v0.$ === 'Ok') {
			var alertSegments = _v0.a;
			return A2($elm$core$List$map, alertSegmentToHtml, alertSegments);
		} else {
			return _List_fromArray(
				[
					$elm$html$Html$text(script)
				]);
		}
	};
	return A2(
		$elm$core$List$map,
		A2(
			$elm$core$Basics$composeL,
			$elm$html$Html$p(_List_Nil),
			substituteTokens),
		A2($elm$core$String$split, '\n', script));
};
var $author$project$Dialer$Views$Layout$renderCallerAlert = function (possibleCallerAlert) {
	if (possibleCallerAlert.$ === 'Just') {
		var callerAlert = possibleCallerAlert.a;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('caller-alert')
					]),
				_Utils_ap(
					$author$project$Dialer$Views$AlertParser$formatAlert(callerAlert.message),
					callerAlert.requiresReload ? _List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick($author$project$Dialer$Types$RetryLoadingApp)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Reconnect')
								]))
						]) : _List_Nil))
			]);
	} else {
		return _List_Nil;
	}
};
var $author$project$Dialer$Views$Layout$renderLayout = F7(
	function (heading, callerAlert, content, possibleStatusClass, status, sidebar, sidebarIsOpen) {
		var sidebarClass = function () {
			if ((sidebarIsOpen.$ === 'Just') && sidebarIsOpen.a) {
				return 'sidebar-open';
			} else {
				return 'sidebar-closed';
			}
		}();
		var optionalStatusClass = function () {
			if (possibleStatusClass.$ === 'Just') {
				var statusClass = possibleStatusClass.a;
				return _List_fromArray(
					[
						$elm$html$Html$Attributes$class(statusClass)
					]);
			} else {
				return _List_Nil;
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_Utils_ap(
				$author$project$Dialer$Views$Layout$renderCallerAlert(callerAlert),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('top-level-container'),
								$elm$html$Html$Attributes$class(sidebarClass)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('container')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('header')
											]),
										_List_fromArray(
											[
												$author$project$Dialer$Views$Images$logo,
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('campaign-heading')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$h1,
														_List_fromArray(
															[
																A2($elm$html$Html$Attributes$attribute, 'data-heading', heading)
															]),
														_List_fromArray(
															[
																$elm$html$Html$text(heading)
															])),
														A2(
														$elm$html$Html$a,
														_List_fromArray(
															[
																$elm$html$Html$Events$onClick($author$project$Dialer$Types$ReportIssue),
																$elm$html$Html$Attributes$class('feedback')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('Report an issue')
															]))
													]))
											])),
										A2(
										$elm$html$Html$div,
										A2(
											$elm$core$List$cons,
											$elm$html$Html$Attributes$class('status-container'),
											optionalStatusClass),
										_List_fromArray(
											[status])),
										content
									])),
								sidebar
							]))
					])));
	});
var $elm$html$Html$strong = _VirtualDom_node('strong');
var $author$project$Dialer$Views$Layout$statusLabel = A2(
	$elm$html$Html$strong,
	_List_Nil,
	_List_fromArray(
		[
			$elm$html$Html$text('Status: ')
		]));
var $author$project$Dialer$Views$Layout$renderLayoutWithMessage = F4(
	function (heading, callerAlert, content, message) {
		return A7(
			$author$project$Dialer$Views$Layout$renderLayout,
			heading,
			callerAlert,
			content,
			$elm$core$Maybe$Nothing,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('message'),
						A2($elm$html$Html$Attributes$attribute, 'data-message', message)
					]),
				_List_fromArray(
					[
						$author$project$Dialer$Views$Layout$statusLabel,
						$elm$html$Html$text(message)
					])),
			$author$project$Dialer$Views$Helpers$emptyDiv,
			$elm$core$Maybe$Nothing);
	});
var $author$project$Dialer$Types$RetryLoadingCampaign = {$: 'RetryLoadingCampaign'};
var $author$project$Dialer$Views$Layout$renderDialog = F5(
	function (heading, callerAlert, message, instructions, buttons) {
		return A4(
			$author$project$Dialer$Views$Layout$renderLayoutWithMessage,
			heading,
			callerAlert,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('instructions-container')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('instructions')
							]),
						instructions),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('actions-container')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('actions')
									]),
								A2(
									$elm$core$List$map,
									function (_v0) {
										var action = _v0.a;
										var actionLabel = _v0.b;
										return A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Events$onClick(action),
													$elm$html$Html$Attributes$class('secondary')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(actionLabel)
												]));
									},
									buttons))
							]))
					])),
			message);
	});
var $author$project$Dialer$Views$Layout$renderMessageWithRetry = F4(
	function (campaign, campaignAlerts, currentTime, message) {
		return A5(
			$author$project$Dialer$Views$Layout$renderDialog,
			campaign.name,
			A2($author$project$Dialer$Views$Helpers$activeCallerAlert, campaignAlerts, currentTime),
			'Not connected',
			$author$project$Dialer$Views$AlertParser$formatAlert(message),
			_List_fromArray(
				[
					_Utils_Tuple2($author$project$Dialer$Types$RetryLoadingCampaign, 'Retry')
				]));
	});
var $author$project$Dialer$Views$Login$renderCallerInfoInput = F6(
	function (campaign, campaignAlerts, currentTime, callersName, callersPhoneNumber, callersEmail) {
		var _v0 = campaign.web_phone;
		if (_v0.$ === 'Just') {
			return A4(
				$author$project$Dialer$Views$Layout$renderLayoutWithMessage,
				campaign.name,
				A2($author$project$Dialer$Views$Helpers$activeCallerAlert, campaignAlerts, currentTime),
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('form-container')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('form-fields')
								]),
							$elm$core$List$concat(
								_List_fromArray(
									[
										_List_fromArray(
										[
											$elm$html$Html$text('Phone'),
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$name('phone'),
													$elm$html$Html$Attributes$value(
													A2($author$project$Utils$formatNumber, campaign.country_iso, callersPhoneNumber)),
													$elm$html$Html$Events$onInput($author$project$Dialer$Types$CapturePhoneNumber)
												]),
											_List_Nil),
											$elm$html$Html$text('Name'),
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$name('name'),
													$elm$html$Html$Attributes$value(callersName),
													$elm$html$Html$Events$onInput($author$project$Dialer$Types$CaptureCallerName)
												]),
											_List_Nil)
										]),
										A2($author$project$Dialer$Views$Login$renderEmailInput, campaign, callersEmail),
										_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('This information will NOT be viewable by the people you are calling. It will only be shared with the organization or campaign you are making calls with.')
												]))
										])
									]))),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('actions-container')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('actions')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$disabled(
													A4($author$project$Dialer$Views$Helpers$callerFieldsInvalid, campaign, callersPhoneNumber, callersName, callersEmail)),
													$author$project$Dialer$Views$Helpers$addTestLabelFromMsg($author$project$Dialer$Types$SubmitPhoneNumberClicked),
													$elm$html$Html$Events$onClick($author$project$Dialer$Types$SubmitPhoneNumberClicked)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Get started')
												])),
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Events$onClick($author$project$Dialer$Types$CallAnotherWay),
													$elm$html$Html$Attributes$class('secondary')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Call another way')
												]))
										]))
								]))
						])),
				'Not Connected');
		} else {
			return A4($author$project$Dialer$Views$Layout$renderMessageWithRetry, campaign, campaignAlerts, currentTime, 'The campaign is not setup for dialing using a browser');
		}
	});
var $author$project$Dialer$Types$CallWithComputerClicked = {$: 'CallWithComputerClicked'};
var $author$project$Dialer$Types$CallWithPhoneOnlyClicked = {$: 'CallWithPhoneOnlyClicked'};
var $author$project$Dialer$Types$PreviewScriptClicked = {$: 'PreviewScriptClicked'};
var $author$project$Dialer$Views$Layout$actionContext = function (context) {
	if (context.$ === 'Just') {
		var content = context.a;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('action-context')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(content)
				]));
	} else {
		return $author$project$Dialer$Views$Helpers$emptyDiv;
	}
};
var $author$project$Dialer$Views$Layout$actionClassName = 'action';
var $author$project$Dialer$Views$Layout$classesForAction = function (context) {
	if (context.$ === 'Just') {
		return _List_fromArray(
			[
				$elm$html$Html$Attributes$class($author$project$Dialer$Views$Layout$actionClassName),
				$elm$html$Html$Attributes$class('action-with-context')
			]);
	} else {
		return _List_fromArray(
			[
				$elm$html$Html$Attributes$class($author$project$Dialer$Views$Layout$actionClassName)
			]);
	}
};
var $author$project$Dialer$Views$Layout$renderMessageWithChoiceOfActions = F4(
	function (heading, callerAlert, message, actions) {
		return A4(
			$author$project$Dialer$Views$Layout$renderLayoutWithMessage,
			heading,
			callerAlert,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('actions-container multiple-actions')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('actions')
							]),
						A2(
							$elm$core$List$map,
							function (nextAction) {
								if (nextAction.$ === 'NoAction') {
									return $author$project$Dialer$Views$Helpers$emptyDiv;
								} else {
									var action = nextAction.a;
									return A2(
										$elm$html$Html$div,
										$author$project$Dialer$Views$Layout$classesForAction(action.context),
										_List_fromArray(
											[
												$author$project$Dialer$Views$Layout$actionContext(action.context),
												A2(
												$elm$html$Html$button,
												_List_fromArray(
													[
														$elm$html$Html$Events$onClick(action.msg),
														$author$project$Dialer$Views$Helpers$addTestLabelFromMsg(action.msg),
														$elm$html$Html$Attributes$class(action.actionClass)
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(action.label)
													]))
											]));
								}
							},
							actions))
					])),
			message);
	});
var $author$project$Dialer$Types$Complete = {$: 'Complete'};
var $author$project$Dialer$Types$NotActiveYet = {$: 'NotActiveYet'};
var $author$project$Dialer$Types$Paused = {$: 'Paused'};
var $author$project$Dialer$Views$Login$renderNonActiveCampaignMessages = F3(
	function (campaign, campaignAlerts, currentTime) {
		var inactiveStatus = function () {
			var _v1 = campaign.status;
			switch (_v1) {
				case 'complete':
					return $author$project$Dialer$Types$Complete;
				case 'stalled':
					return $author$project$Dialer$Types$Paused;
				case 'pausing':
					return $author$project$Dialer$Types$Paused;
				case 'paused':
					return $author$project$Dialer$Types$Paused;
				default:
					return $author$project$Dialer$Types$NotActiveYet;
			}
		}();
		switch (inactiveStatus.$) {
			case 'Complete':
				return A4(
					$author$project$Dialer$Views$Layout$renderMessageWithRetry,
					campaign,
					campaignAlerts,
					currentTime,
					A2(
						$elm$core$Maybe$withDefault,
						'This campaign is complete',
						A2(
							$elm$core$Maybe$map,
							function (alerts) {
								return alerts.onCompleteMessage;
							},
							campaignAlerts)));
			case 'Paused':
				return A4(
					$author$project$Dialer$Views$Layout$renderMessageWithRetry,
					campaign,
					campaignAlerts,
					currentTime,
					A2(
						$elm$core$Maybe$withDefault,
						'This campaign is paused',
						A2(
							$elm$core$Maybe$map,
							function (alerts) {
								return alerts.onPausedMessage;
							},
							campaignAlerts)));
			default:
				return A4($author$project$Dialer$Views$Layout$renderMessageWithRetry, campaign, campaignAlerts, currentTime, 'The campaign is not active yet');
		}
	});
var $author$project$Dialer$Views$Login$renderChooseCallInMethod = F3(
	function (campaign, campaignAlerts, currentTime) {
		var _v0 = campaign.status;
		if (_v0 === 'active') {
			return A4(
				$author$project$Dialer$Views$Layout$renderMessageWithChoiceOfActions,
				campaign.name,
				A2($author$project$Dialer$Views$Helpers$activeCallerAlert, campaignAlerts, currentTime),
				'Not connected',
				A2(
					$elm$core$List$append,
					_List_fromArray(
						[
							$author$project$Dialer$Types$Action(
							{
								actionClass: 'secondary',
								context: $elm$core$Maybe$Just('Explore the script before making any calls'),
								label: 'Preview script',
								msg: $author$project$Dialer$Types$PreviewScriptClicked
							}),
							$author$project$Dialer$Types$Action(
							{
								actionClass: 'primary',
								context: $elm$core$Maybe$Just('Use a computer, smartphone, or tablet for both the call script and call audio'),
								label: 'Call with this device',
								msg: $author$project$Dialer$Types$CallWithComputerClicked
							}),
							$author$project$Dialer$Types$Action(
							{
								actionClass: 'primary',
								context: $elm$core$Maybe$Just('Use a computer, smartphone, or tablet for the call script and a phone for the call audio'),
								label: 'Call with this device + phone for audio',
								msg: $author$project$Dialer$Types$CallWithPhoneClicked
							})
						]),
					function () {
						var _v1 = _Utils_Tuple2(campaign.ivr, campaign.phone);
						if (_v1.a && (_v1.b.$ === 'Just')) {
							return _List_fromArray(
								[
									$author$project$Dialer$Types$Action(
									{
										actionClass: 'primary',
										context: $elm$core$Maybe$Just('Call from your phone, and answer questions on your phone’s keypad'),
										label: 'Call with a phone only',
										msg: $author$project$Dialer$Types$CallWithPhoneOnlyClicked
									})
								]);
						} else {
							return _List_Nil;
						}
					}()));
		} else {
			return A3($author$project$Dialer$Views$Login$renderNonActiveCampaignMessages, campaign, campaignAlerts, currentTime);
		}
	});
var $author$project$Dialer$Views$Login$renderConfirmLastCallInMethod = F4(
	function (campaign, campaignAlerts, currentTime, lastMedium) {
		var _v0 = campaign.status;
		if (_v0 === 'active') {
			return A4(
				$author$project$Dialer$Views$Layout$renderMessageWithChoiceOfActions,
				campaign.name,
				A2($author$project$Dialer$Views$Helpers$activeCallerAlert, campaignAlerts, currentTime),
				'Not connected',
				_List_fromArray(
					[
						function () {
						if (lastMedium === 'computer') {
							return A2($author$project$Dialer$Views$Helpers$primaryAction, $author$project$Dialer$Types$CallWithComputerClicked, 'Call with this device');
						} else {
							return A2($author$project$Dialer$Views$Helpers$primaryAction, $author$project$Dialer$Types$CallWithPhoneClicked, 'Call with this device + phone for audio');
						}
					}(),
						$author$project$Dialer$Types$Action(
						{
							actionClass: 'secondary',
							context: $elm$core$Maybe$Just('Explore the script before making any calls'),
							label: 'Preview script',
							msg: $author$project$Dialer$Types$PreviewScriptClicked
						}),
						$author$project$Dialer$Types$Action(
						{actionClass: 'secondary', context: $elm$core$Maybe$Nothing, label: 'Call another way', msg: $author$project$Dialer$Types$CallAnotherWay})
					]));
		} else {
			return A3($author$project$Dialer$Views$Login$renderNonActiveCampaignMessages, campaign, campaignAlerts, currentTime);
		}
	});
var $author$project$Dialer$Types$CaptureCalleeAddress = function (a) {
	return {$: 'CaptureCalleeAddress', a: a};
};
var $author$project$Dialer$Types$CaptureCalleeMessagingPhoneNumber = function (a) {
	return {$: 'CaptureCalleeMessagingPhoneNumber', a: a};
};
var $author$project$Dialer$Types$CaptureCalleeZip = function (a) {
	return {$: 'CaptureCalleeZip', a: a};
};
var $author$project$Dialer$Types$CaptureTextFieldAnswer = function (a) {
	return {$: 'CaptureTextFieldAnswer', a: a};
};
var $author$project$Dialer$Types$ConfirmPollingPlaceLookupAddress = {$: 'ConfirmPollingPlaceLookupAddress'};
var $author$project$Dialer$Types$ContinueCallingClicked = {$: 'ContinueCallingClicked'};
var $author$project$Dialer$Types$ExitSurveyPreviewClicked = {$: 'ExitSurveyPreviewClicked'};
var $author$project$Dialer$Types$GoToNextQuestion = {$: 'GoToNextQuestion'};
var $author$project$Dialer$Types$ResetSurveyPreviewClicked = {$: 'ResetSurveyPreviewClicked'};
var $author$project$Dialer$Types$SendMessageClicked = F2(
	function (a, b) {
		return {$: 'SendMessageClicked', a: a, b: b};
	});
var $author$project$Dialer$Types$SubmitMultipleResponseAnswers = F2(
	function (a, b) {
		return {$: 'SubmitMultipleResponseAnswers', a: a, b: b};
	});
var $author$project$Dialer$Types$SubmitNullAnswer = function (a) {
	return {$: 'SubmitNullAnswer', a: a};
};
var $author$project$Dialer$Types$SubmitTextFieldAnswer = F2(
	function (a, b) {
		return {$: 'SubmitTextFieldAnswer', a: a, b: b};
	});
var $author$project$Dialer$Types$SurveyResultMultipleResponseClicked = F2(
	function (a, b) {
		return {$: 'SurveyResultMultipleResponseClicked', a: a, b: b};
	});
var $author$project$Dialer$Types$SurveyResultSingleResponseClicked = F2(
	function (a, b) {
		return {$: 'SurveyResultSingleResponseClicked', a: a, b: b};
	});
var $author$project$Dialer$Types$UndoSurveyResponseClicked = {$: 'UndoSurveyResponseClicked'};
var $author$project$Dialer$Views$Helpers$addTestLabel = $elm$html$Html$Attributes$attribute('data-cmd');
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $elm$json$Json$Encode$dict = F3(
	function (toKey, toValue, dictionary) {
		return _Json_wrap(
			A3(
				$elm$core$Dict$foldl,
				F3(
					function (key, value, obj) {
						return A3(
							_Json_addField,
							toKey(key),
							toValue(value),
							obj);
					}),
				_Json_emptyObject(_Utils_Tuple0),
				dictionary));
	});
var $author$project$Dialer$Views$DraftJsWrapper$stringDict = function (value) {
	return A3($elm$json$Json$Encode$dict, $elm$core$Basics$identity, $elm$json$Json$Encode$string, value);
};
var $author$project$Api$Enum$BuiltinCalleeField$toString = function (enum____) {
	switch (enum____.$) {
		case 'FirstName':
			return 'FIRST_NAME';
		case 'LastName':
			return 'LAST_NAME';
		case 'Email':
			return 'EMAIL';
		case 'AddressLine1':
			return 'ADDRESS_LINE_1';
		case 'AddressLine2':
			return 'ADDRESS_LINE_2';
		case 'City':
			return 'CITY';
		case 'State':
			return 'STATE';
		case 'Zip':
			return 'ZIP';
		case 'PollingPlaceName':
			return 'POLLING_PLACE_NAME';
		case 'PollingPlaceAddressLine1':
			return 'POLLING_PLACE_ADDRESS_LINE_1';
		case 'PollingPlaceCity':
			return 'POLLING_PLACE_CITY';
		default:
			return 'POLLING_PLACE_STATE';
	}
};
var $author$project$Dialer$Views$DraftJsWrapper$builtInFieldAsString = function (field) {
	if (field.$ === 'Just') {
		var builtinField = field.a;
		return $author$project$Api$Enum$BuiltinCalleeField$toString(builtinField);
	} else {
		return '';
	}
};
var $author$project$Dialer$Views$DraftJsWrapper$getCalleeFieldIdAsValue = function (id) {
	if (id.$ === 'Just') {
		var fieldId = id.a;
		return $author$project$Api$Scalar$defaultCodecs.codecId.encoder(fieldId);
	} else {
		return $elm$json$Json$Encode$null;
	}
};
var $author$project$Dialer$Views$DraftJsWrapper$calleeFieldToValue = function (field) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$author$project$Dialer$Views$DraftJsWrapper$getCalleeFieldIdAsValue(field.id)),
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(field.name)),
				_Utils_Tuple2(
				'builtInField',
				$elm$json$Json$Encode$string(
					$author$project$Dialer$Views$DraftJsWrapper$builtInFieldAsString(field.builtInField))),
				_Utils_Tuple2(
				'dataSourceFieldName',
				$elm$json$Json$Encode$string(
					A2($elm$core$Maybe$withDefault, '', field.dataSourceFieldName)))
			]));
};
var $author$project$Dialer$Views$DraftJsWrapper$stringDictList = function (fields) {
	return A2(
		$elm$json$Json$Encode$list,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, $author$project$Dialer$Views$DraftJsWrapper$calleeFieldToValue, fields));
};
var $author$project$Dialer$Views$DraftJsWrapper$displayReadonlyEditor = F2(
	function (script, callState) {
		return A3(
			$elm$html$Html$node,
			'draft-js-element',
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$attribute, 'script', script),
					A2(
					$elm$html$Html$Attributes$attribute,
					'calleeData',
					A2(
						$elm$json$Json$Encode$encode,
						0,
						$author$project$Dialer$Views$DraftJsWrapper$stringDict(callState.calleeData))),
					A2(
					$elm$html$Html$Attributes$attribute,
					'calleeFields',
					A2(
						$elm$json$Json$Encode$encode,
						0,
						$author$project$Dialer$Views$DraftJsWrapper$stringDictList(callState.campaign.callee_fields)))
				]),
			_List_Nil);
	});
var $author$project$Dialer$Views$ScriptParser$CalleeField = function (a) {
	return {$: 'CalleeField', a: a};
};
var $author$project$Dialer$Views$ScriptParser$LegacyCalleeName = {$: 'LegacyCalleeName'};
var $author$project$Dialer$Views$ScriptParser$Plaintext = function (a) {
	return {$: 'Plaintext', a: a};
};
var $author$project$Dialer$Views$ScriptParser$UnknownCalleeField = {$: 'UnknownCalleeField'};
var $author$project$Dialer$Views$ScriptParser$Url = function (a) {
	return {$: 'Url', a: a};
};
var $author$project$Dialer$Views$CalleeData$calleeFieldHasTargetId = F2(
	function (targetId, calleeField) {
		return A2(
			$author$project$Utils$maybeEqual,
			calleeField.id,
			$author$project$Api$Scalar$Id(targetId));
	});
var $author$project$Dialer$Views$CalleeData$mapCalleeFieldIdToValue = F3(
	function (campaign, calleeData, id) {
		return A2(
			$elm$core$Maybe$map,
			$author$project$Dialer$Views$CalleeData$fieldFromCalleeData(calleeData),
			A2(
				$elm_community$list_extra$List$Extra$find,
				$author$project$Dialer$Views$CalleeData$calleeFieldHasTargetId(id),
				campaign.callee_fields));
	});
var $author$project$Dialer$Views$ScriptParser$tokenParser = function (callState) {
	var tokenParserHelper = function (chunks) {
		var notToken = A2(
			$elm$parser$Parser$map,
			$author$project$Dialer$Views$ScriptParser$Plaintext,
			$elm$parser$Parser$getChompedString(
				A2(
					$elm$parser$Parser$keeper,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompIf(
						function (_v2) {
							return true;
						}))));
		var mapCalleeFieldIdToValueInCampaign = function (id) {
			var _v1 = A3($author$project$Dialer$Views$CalleeData$mapCalleeFieldIdToValue, callState.campaign, callState.calleeData, id);
			if (_v1.$ === 'Just') {
				var calleeFieldValue = _v1.a;
				return $author$project$Dialer$Views$ScriptParser$CalleeField(
					{name: calleeFieldValue.name, value: calleeFieldValue.value});
			} else {
				return $author$project$Dialer$Views$ScriptParser$UnknownCalleeField;
			}
		};
		var legacyCalleeName = A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($author$project$Dialer$Views$ScriptParser$LegacyCalleeName),
			$elm$parser$Parser$token('[NAME]'));
		var isNonAlphaUrlChar = function (c) {
			return A2(
				$elm$core$List$member,
				c,
				_List_fromArray(
					[
						_Utils_chr('-'),
						_Utils_chr('.'),
						_Utils_chr('_'),
						_Utils_chr('~'),
						_Utils_chr(':'),
						_Utils_chr('/'),
						_Utils_chr('?'),
						_Utils_chr('#'),
						_Utils_chr('['),
						_Utils_chr(']'),
						_Utils_chr('@'),
						_Utils_chr('!'),
						_Utils_chr('$'),
						_Utils_chr('&'),
						_Utils_chr('\''),
						_Utils_chr('('),
						_Utils_chr(')'),
						_Utils_chr('*'),
						_Utils_chr('+'),
						_Utils_chr(';'),
						_Utils_chr('%'),
						_Utils_chr('=')
					]));
		};
		var finalizeSegments = function (_v0) {
			return $elm$parser$Parser$Done(
				$elm$core$List$reverse(chunks));
		};
		var chompWhileUrlCharacter = $elm$parser$Parser$chompWhile(
			function (c) {
				return $elm$core$Char$isAlphaNum(c) || isNonAlphaUrlChar(c);
			});
		var linkWithScheme = function (scheme) {
			var mapUrl = function (urlWithoutScheme) {
				return $author$project$Dialer$Views$ScriptParser$Url(
					_Utils_ap(scheme, urlWithoutScheme));
			};
			return A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$token(scheme)),
				A2(
					$elm$parser$Parser$map,
					mapUrl,
					$elm$parser$Parser$getChompedString(chompWhileUrlCharacter)));
		};
		var calleeToken = function () {
			var chompWhileAlphaNumOrUnderscore = $elm$parser$Parser$chompWhile(
				function (c) {
					return $elm$core$Char$isAlphaNum(c) || _Utils_eq(
						c,
						_Utils_chr('-'));
				});
			return A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$token('{callee_field:')),
				A2(
					$elm$parser$Parser$ignorer,
					A2(
						$elm$parser$Parser$map,
						mapCalleeFieldIdToValueInCampaign,
						$elm$parser$Parser$getChompedString(chompWhileAlphaNumOrUnderscore)),
					$elm$parser$Parser$token('}')));
		}();
		var appendSegment = function (chunk) {
			return $elm$parser$Parser$Loop(
				A2($elm$core$List$cons, chunk, chunks));
		};
		return $elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2($elm$parser$Parser$map, appendSegment, calleeToken),
					A2($elm$parser$Parser$map, appendSegment, legacyCalleeName),
					A2(
					$elm$parser$Parser$map,
					appendSegment,
					linkWithScheme('https')),
					A2(
					$elm$parser$Parser$map,
					appendSegment,
					linkWithScheme('http')),
					A2($elm$parser$Parser$map, appendSegment, notToken),
					A2($elm$parser$Parser$map, finalizeSegments, $elm$parser$Parser$end)
				]));
	};
	return A2($elm$parser$Parser$loop, _List_Nil, tokenParserHelper);
};
var $author$project$Dialer$Views$ScriptParser$formatAndSubstituteFields = F2(
	function (script, callState) {
		var endsWithPuctuation = function (str) {
			return A2(
				$elm$core$List$member,
				A2($elm$core$String$right, 1, str),
				_List_fromArray(
					['.', '?', '!', ':', ')', ';']));
		};
		var link = function (url) {
			return endsWithPuctuation(url) ? _Utils_ap(
				link(
					A2($elm$core$String$dropRight, 1, url)),
				_List_fromArray(
					[
						$elm$html$Html$text(
						A2($elm$core$String$right, 1, url))
					])) : _List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href(url),
							$elm$html$Html$Attributes$target('_blank'),
							$elm$html$Html$Attributes$rel('noopener noreferrer')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(url)
						]))
				]);
		};
		var scriptSegmentToHtml = function (segment) {
			switch (segment.$) {
				case 'Plaintext':
					var textSegment = segment.a;
					return $elm$html$Html$text(textSegment);
				case 'Url':
					var url = segment.a;
					return A2(
						$elm$html$Html$span,
						_List_Nil,
						link(url));
				case 'CalleeField':
					var calleeField = segment.a;
					var _v2 = calleeField.value;
					if (_v2.$ === 'Just') {
						var value = _v2.a;
						return $elm$core$String$isEmpty(value) ? A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('callee-field-unknown')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Unknown ' + calleeField.name)
								])) : ((A2($elm$core$String$startsWith, 'http', value) || A2($elm$core$String$startsWith, 'https', value)) ? A2(
							$elm$html$Html$span,
							_List_Nil,
							link(value)) : A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('callee-field')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(value)
								])));
					} else {
						return A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('callee-field-unknown')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Unknown ' + calleeField.name)
								]));
					}
				case 'UnknownCalleeField':
					return A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('callee-field-unknown')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Unknown')
							]));
				default:
					return A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('callee-name')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								A2($elm$core$Maybe$withDefault, 'Unknown Name', callState.name))
							]));
			}
		};
		var substituteTokens = function (scriptWithoutSubstitions) {
			var _v0 = A2(
				$elm$parser$Parser$run,
				$author$project$Dialer$Views$ScriptParser$tokenParser(callState),
				scriptWithoutSubstitions);
			if (_v0.$ === 'Ok') {
				var scriptSegments = _v0.a;
				return A2($elm$core$List$map, scriptSegmentToHtml, scriptSegments);
			} else {
				return _List_fromArray(
					[
						$elm$html$Html$text(script)
					]);
			}
		};
		return A2(
			$elm$core$List$map,
			A2(
				$elm$core$Basics$composeL,
				$elm$html$Html$p(_List_Nil),
				substituteTokens),
			A2($elm$core$String$split, '\n', script));
	});
var $author$project$Dialer$Views$ScriptParser$displayScript = F3(
	function (script, isRichText, callState) {
		if ((isRichText.$ === 'Just') && isRichText.a) {
			return _List_fromArray(
				[
					A2($author$project$Dialer$Views$DraftJsWrapper$displayReadonlyEditor, script, callState)
				]);
		} else {
			return A2($author$project$Dialer$Views$ScriptParser$formatAndSubstituteFields, script, callState);
		}
	});
var $elm$html$Html$iframe = _VirtualDom_node('iframe');
var $author$project$Dialer$Types$EndConferenceClicked = {$: 'EndConferenceClicked'};
var $author$project$Dialer$Types$EndSurveyClicked = {$: 'EndSurveyClicked'};
var $author$project$Dialer$Views$Helpers$calleeName = function (possibleName) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('callee-name')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(
				A2($elm$core$Maybe$withDefault, 'UNKNOWN', possibleName))
			]));
};
var $author$project$Dialer$Views$Images$ringing = A2(
	$elm$html$Html$img,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$src('assets/ringing.svg'),
			$elm$html$Html$Attributes$class('oncall')
		]),
	_List_Nil);
var $author$project$Dialer$Views$InCall$surveyCanBeEndedEarly = function (callState) {
	return (!$elm$core$List$isEmpty(callState.responses)) && ((!_Utils_eq(callState.nextQuestion, $elm$core$Maybe$Nothing)) && (!callState.surveyEndedEarlyByUser));
};
var $author$project$Dialer$Views$InCall$inCallStatus = function (callState) {
	var messageContainer = function (message) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('message'),
					A2($elm$html$Html$Attributes$attribute, 'data-message', message)
				]),
			_List_fromArray(
				[
					$author$project$Dialer$Views$Layout$statusLabel,
					$elm$html$Html$text(message)
				]));
	};
	return callState.simulatedCall ? A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('active-call-controls'),
				$elm$html$Html$Attributes$class('message')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Script preview mode')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('call-control')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('secondary'),
								$elm$html$Html$Events$onClick($author$project$Dialer$Types$ExitSurveyPreviewClicked)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Exit script preview')
							]))
					]))
			])) : (callState.ended ? ($author$project$Dialer$Views$InCall$surveyCanBeEndedEarly(callState) ? A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('active-call-controls'),
				$elm$html$Html$Attributes$class('message')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('The call has ended')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('call-control')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('secondary'),
								$elm$html$Html$Events$onClick($author$project$Dialer$Types$EndSurveyClicked)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('End survey')
							]))
					]))
			])) : messageContainer('The call has ended')) : A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('active-call-controls')
			]),
		_List_fromArray(
			[
				$author$project$Dialer$Views$Images$ringing,
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('You are speaking to '),
						$author$project$Dialer$Views$Helpers$calleeName(callState.name)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('call-control')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('urgent'),
								$author$project$Dialer$Views$Helpers$addTestLabelFromMsg($author$project$Dialer$Types$EndConferenceClicked),
								$elm$html$Html$Events$onClick($author$project$Dialer$Types$EndConferenceClicked)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('End call')
							]))
					]))
			])));
};
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (_v0.$ === 'Just') {
			return true;
		} else {
			return false;
		}
	});
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0.a;
		return A2($elm$core$Dict$member, key, dict);
	});
var $elm$url$Url$Builder$toQueryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return key + ('=' + value);
};
var $elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var $elm$url$Url$Builder$crossOrigin = F3(
	function (prePath, pathSegments, parameters) {
		return prePath + ('/' + (A2($elm$core$String$join, '/', pathSegments) + $elm$url$Url$Builder$toQuery(parameters)));
	});
var $elm$url$Url$Builder$QueryParameter = F2(
	function (a, b) {
		return {$: 'QueryParameter', a: a, b: b};
	});
var $elm$url$Url$Builder$string = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$url$Url$percentEncode(value));
	});
var $author$project$Dialer$Views$InCall$mobilizeAmericaEventUrl = F2(
	function (callState, settings) {
		var fieldValue = function (builtInField) {
			return A2(
				$elm$core$Maybe$withDefault,
				' ',
				A3($author$project$Dialer$States$InCall$calleeBuiltinFieldValue, callState.campaign, callState.calleeData, builtInField));
		};
		return A3(
			$elm$url$Url$Builder$crossOrigin,
			'https://www.mobilize.us',
			_List_fromArray(
				[
					settings.mobilizeTenantSlug,
					'event',
					$elm$core$String$fromInt(settings.eventId),
					''
				]),
			_List_fromArray(
				[
					A2($elm$url$Url$Builder$string, 'phone', callState.calleePhoneNumber),
					A2(
					$elm$url$Url$Builder$string,
					'first_name',
					fieldValue($author$project$Api$Enum$BuiltinCalleeField$FirstName)),
					A2(
					$elm$url$Url$Builder$string,
					'last_name',
					fieldValue($author$project$Api$Enum$BuiltinCalleeField$LastName)),
					A2(
					$elm$url$Url$Builder$string,
					'email',
					fieldValue($author$project$Api$Enum$BuiltinCalleeField$Email)),
					A2(
					$elm$url$Url$Builder$string,
					'zip',
					fieldValue($author$project$Api$Enum$BuiltinCalleeField$Zip))
				]));
	});
var $author$project$Dialer$Views$InCall$renderAddress = function (address) {
	return $elm$html$Html$text(
		$elm$core$String$concat(
			_List_fromArray(
				[address.address, ', ', address.zip])));
};
var $elm$core$String$cons = _String_cons;
var $elm_community$string_extra$String$Extra$changeCase = F2(
	function (mutator, word) {
		return A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function (_v0) {
					var head = _v0.a;
					var tail = _v0.b;
					return A2(
						$elm$core$String$cons,
						mutator(head),
						tail);
				},
				$elm$core$String$uncons(word)));
	});
var $elm$core$Char$toUpper = _Char_toUpper;
var $elm_community$string_extra$String$Extra$toSentenceCase = function (word) {
	return A2($elm_community$string_extra$String$Extra$changeCase, $elm$core$Char$toUpper, word);
};
var $author$project$Dialer$Views$InCall$renderAnsweredQuestion = function (response) {
	switch (response.$) {
		case 'SingleChoiceQuestionResponse':
			var question = response.a;
			var answer = response.b;
			return A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(question.name)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('response-answer')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$elm_community$string_extra$String$Extra$toSentenceCase(answer.value))
							]))
					]));
		case 'MultipleChoiceQuestionResponse':
			var question = response.a;
			var answers = response.b;
			return A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(question.name)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('response-answer')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$elm_community$string_extra$String$Extra$toSentenceCase(
									A2(
										$elm$core$String$join,
										', ',
										A2(
											$elm$core$List$map,
											function (a) {
												return a.value;
											},
											answers))))
							]))
					]));
		case 'TextFieldQuestionResponse':
			var question = response.a;
			var answer = response.b;
			return A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(question.name)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('response-answer')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$elm_community$string_extra$String$Extra$toSentenceCase(answer))
							]))
					]));
		case 'PollingPlaceLookupQuestionResponse':
			var question = response.a;
			var maybeAddress = response.b;
			return A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(question.name)
							])),
						function () {
						if (maybeAddress.$ === 'Just') {
							var address = maybeAddress.a;
							return A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('response-answer')
									]),
								_List_fromArray(
									[
										$author$project$Dialer$Views$InCall$renderAddress(address)
									]));
						} else {
							return A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('response-answer')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('No address provided')
									]));
						}
					}()
					]));
		default:
			return A2($elm$html$Html$div, _List_Nil, _List_Nil);
	}
};
var $author$project$Dialer$Views$InCall$renderPollingPlaceLookup = F4(
	function (tool, address, tenantSlug, campaignName) {
		return A2(
			$elm$html$Html$iframe,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$src(
					A3(
						$elm$url$Url$Builder$crossOrigin,
						'https://iwillvote.com',
						_List_fromArray(
							['locate', 'results']),
						_List_fromArray(
							[
								A2(
								$elm$url$Url$Builder$string,
								'partner',
								$elm$core$String$concat(
									_List_fromArray(
										['ScaletoWin_', tenantSlug]))),
								A2(
								$elm$url$Url$Builder$string,
								'partner_campaign',
								$elm$core$String$concat(
									_List_fromArray(
										['ScaletoWin_', campaignName]))),
								A2($elm$url$Url$Builder$string, 'partner_medium', 'dialer'),
								A2(
								$elm$url$Url$Builder$string,
								'utm_source',
								$elm$core$String$concat(
									_List_fromArray(
										['ScaletoWin_', tenantSlug]))),
								A2(
								$elm$url$Url$Builder$string,
								'utm_campaign',
								$elm$core$String$concat(
									_List_fromArray(
										['ScaletoWin_', campaignName]))),
								A2($elm$url$Url$Builder$string, 'utm_medium', 'dialer'),
								A2($elm$url$Url$Builder$string, 'client_id', 'site'),
								A2($elm$url$Url$Builder$string, 'address_line_1', address.address),
								A2($elm$url$Url$Builder$string, 'zip', address.zip)
							]))),
					$elm$html$Html$Attributes$class('iwillvote-embed')
				]),
			_List_Nil);
	});
var $author$project$Dialer$Types$ToggleSidebarClicked = {$: 'ToggleSidebarClicked'};
var $elm$html$Html$br = _VirtualDom_node('br');
var $elm$core$List$intersperse = F2(
	function (sep, xs) {
		if (!xs.b) {
			return _List_Nil;
		} else {
			var hd = xs.a;
			var tl = xs.b;
			var step = F2(
				function (x, rest) {
					return A2(
						$elm$core$List$cons,
						sep,
						A2($elm$core$List$cons, x, rest));
				});
			var spersed = A3($elm$core$List$foldr, step, _List_Nil, tl);
			return A2($elm$core$List$cons, hd, spersed);
		}
	});
var $author$project$Dialer$Views$Sidebar$lookupCalleeField = F3(
	function (campaign, calleeData, field) {
		return A2(
			$elm$core$Maybe$withDefault,
			'??',
			A3($author$project$Dialer$States$InCall$calleeBuiltinFieldValue, campaign, calleeData, field));
	});
var $elm$core$String$lines = _String_lines;
var $author$project$Dialer$Views$Sidebar$newlinesToParagraphs = function (textWithNewlines) {
	return A2(
		$elm$core$List$intersperse,
		A2($elm$html$Html$br, _List_Nil, _List_Nil),
		A2(
			$elm$core$List$map,
			$elm$html$Html$text,
			$elm$core$String$lines(textWithNewlines)));
};
var $author$project$Dialer$Views$Sidebar$renderTalkingPoints = function (talkingPoints) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('sidebar-group')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('heading')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(talkingPoints.heading)
					])),
				A2(
				$elm$html$Html$div,
				_List_Nil,
				$author$project$Dialer$Views$Sidebar$newlinesToParagraphs(talkingPoints.text))
			]));
};
var $author$project$Dialer$Views$Sidebar$renderSidebarContent = F2(
	function (model, callState) {
		var talkingPointsSection = callState.campaign.enableTalkingPoints ? A2(
			$elm$core$List$cons,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('section-heading')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Additional Information')
					])),
			A2($elm$core$List$map, $author$project$Dialer$Views$Sidebar$renderTalkingPoints, callState.campaign.talkingPoints)) : _List_Nil;
		var optionalLookup = function (field) {
			return A3($author$project$Dialer$States$InCall$calleeBuiltinFieldValue, callState.campaign, callState.calleeData, field);
		};
		var customFields = function () {
			var renderCustomField = function (field) {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('custom-field')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('field-name')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(field.name + ': ')
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('field-value')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									A2($elm$core$Maybe$withDefault, '??', field.value))
								]))
						]));
			};
			var customFieldsEnabledInSidebar = A2(
				$elm$core$List$sortBy,
				function (field) {
					return A2($elm$core$Maybe$withDefault, 0, field.sidebarOrder);
				},
				A2(
					$elm$core$List$filter,
					function (field) {
						return !_Utils_eq(field.sidebarOrder, $elm$core$Maybe$Nothing);
					},
					callState.campaign.callee_fields));
			var customFieldsAndValues = A2(
				$elm$core$List$map,
				$author$project$Dialer$Views$CalleeData$fieldFromCalleeData(callState.calleeData),
				customFieldsEnabledInSidebar);
			return (!_Utils_eq(customFieldsEnabledInSidebar, _List_Nil)) ? A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('sidebar-group')
					]),
				A2($elm$core$List$map, renderCustomField, customFieldsAndValues)) : $author$project$Dialer$Views$Helpers$emptyDiv;
		}();
		var calleeLookup = A2($author$project$Dialer$Views$Sidebar$lookupCalleeField, callState.campaign, callState.calleeData);
		var calleeInfo = callState.campaign.calleeAdditionalInfo;
		var pollingPlaceInfo = calleeInfo.pollingPlace ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('sidebar-group')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('heading')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Polling Location')
						])),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					A2(
						$elm$core$List$intersperse,
						A2($elm$html$Html$br, _List_Nil, _List_Nil),
						_List_fromArray(
							[
								$elm$html$Html$text(
								calleeLookup($author$project$Api$Enum$BuiltinCalleeField$PollingPlaceName)),
								$elm$html$Html$text(
								calleeLookup($author$project$Api$Enum$BuiltinCalleeField$PollingPlaceAddressLine1)),
								$elm$html$Html$text(
								calleeLookup($author$project$Api$Enum$BuiltinCalleeField$PollingPlaceCity) + (' ' + calleeLookup($author$project$Api$Enum$BuiltinCalleeField$PollingPlaceState)))
							])))
				])) : $author$project$Dialer$Views$Helpers$emptyDiv;
		var basicContactInfo = calleeInfo.name ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('sidebar-group')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('heading')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							calleeLookup($author$project$Api$Enum$BuiltinCalleeField$FirstName) + (' ' + calleeLookup($author$project$Api$Enum$BuiltinCalleeField$LastName)))
						])),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					calleeInfo.phoneNumber ? _List_fromArray(
						[
							$elm$html$Html$text(
							A2($author$project$Utils$formatNumber, callState.campaign.country_iso, callState.calleePhoneNumber))
						]) : _List_Nil)
				])) : $author$project$Dialer$Views$Helpers$emptyDiv;
		var atLeastOneSidebarSectionEnabled = callState.campaign.enableCalleeAdditionalInfo || callState.campaign.enableTalkingPoints;
		var addressInfo = calleeInfo.homeAddress ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('sidebar-group')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('heading')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Home Address')
						])),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					A2(
						$elm$core$List$intersperse,
						A2($elm$html$Html$br, _List_Nil, _List_Nil),
						A2(
							$elm$core$List$filterMap,
							$elm$core$Maybe$andThen(
								A2($elm$core$Basics$composeL, $elm$core$Maybe$Just, $elm$html$Html$text)),
							_List_fromArray(
								[
									optionalLookup($author$project$Api$Enum$BuiltinCalleeField$AddressLine1),
									optionalLookup($author$project$Api$Enum$BuiltinCalleeField$AddressLine2),
									$elm$core$Maybe$Just(
									calleeLookup($author$project$Api$Enum$BuiltinCalleeField$City) + (' ' + (calleeLookup($author$project$Api$Enum$BuiltinCalleeField$State) + (' ' + calleeLookup($author$project$Api$Enum$BuiltinCalleeField$Zip)))))
								]))))
				])) : $author$project$Dialer$Views$Helpers$emptyDiv;
		var calleeInfoSection = callState.campaign.enableCalleeAdditionalInfo ? _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('section-heading')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Contact Information')
					])),
				basicContactInfo,
				customFields,
				addressInfo,
				pollingPlaceInfo
			]) : _List_Nil;
		var _v0 = _Utils_Tuple2(atLeastOneSidebarSectionEnabled, model.sidebarOpen);
		if (_v0.a) {
			if (_v0.b) {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('sidebar')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('scrollable-column')
								]),
							A2(
								$elm$core$List$cons,
								A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('sidebarToggle'),
											$elm$html$Html$Events$onClick($author$project$Dialer$Types$ToggleSidebarClicked)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Close')
										])),
								_Utils_ap(calleeInfoSection, talkingPointsSection)))
						]));
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('sidebar')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('sidebarToggle'),
									$elm$html$Html$Events$onClick($author$project$Dialer$Types$ToggleSidebarClicked)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Info')
								]))
						]));
			}
		} else {
			return $author$project$Dialer$Views$Helpers$emptyDiv;
		}
	});
var $author$project$Dialer$Views$Sidebar$sidebarIsOpen = F2(
	function (model, callState) {
		return $elm$core$Maybe$Just((callState.campaign.enableTalkingPoints || callState.campaign.enableCalleeAdditionalInfo) && model.sidebarOpen);
	});
var $author$project$Dialer$Views$Layout$statusClassForCallState = function (callState) {
	return callState.ended ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just('call-active');
};
var $author$project$Dialer$Views$ScriptParser$substituteFields = F2(
	function (script, callState) {
		var scriptSegmentToString = function (segment) {
			switch (segment.$) {
				case 'Plaintext':
					var textSegment = segment.a;
					return textSegment;
				case 'Url':
					var url = segment.a;
					return url;
				case 'CalleeField':
					var calleeField = segment.a;
					var _v2 = calleeField.value;
					if (_v2.$ === 'Just') {
						var value = _v2.a;
						return $elm$core$String$isEmpty(value) ? ('Unknown ' + calleeField.name) : value;
					} else {
						return 'Unknown ' + calleeField.name;
					}
				case 'UnknownCalleeField':
					return 'Unknown';
				default:
					return A2($elm$core$Maybe$withDefault, 'Unknown Name', callState.name);
			}
		};
		var _v0 = A2(
			$elm$parser$Parser$run,
			$author$project$Dialer$Views$ScriptParser$tokenParser(callState),
			script);
		if (_v0.$ === 'Ok') {
			var scriptSegments = _v0.a;
			return A2(
				$elm$core$String$join,
				'',
				A2($elm$core$List$map, scriptSegmentToString, scriptSegments));
		} else {
			return script;
		}
	});
var $elm_community$maybe_extra$Maybe$Extra$unwrap = F3(
	function (_default, f, m) {
		if (m.$ === 'Nothing') {
			return _default;
		} else {
			var a = m.a;
			return f(a);
		}
	});
var $author$project$Dialer$Views$InCall$renderInCallUI = F2(
	function (model, callState) {
		var undoButton = A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick($author$project$Dialer$Types$UndoSurveyResponseClicked),
					$elm$html$Html$Attributes$class('undo')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('< Back')
				]));
		var undoButtonIfPreviousQuestion = function (question) {
			return (question.key === 'disposition') ? $author$project$Dialer$Views$Helpers$emptyDiv : undoButton;
		};
		var textFieldQuestion = function (question) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-field-input')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('input-container')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Enter the answer below'),
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$value(callState.textFieldText),
										$elm$html$Html$Events$onInput($author$project$Dialer$Types$CaptureTextFieldAnswer)
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick(
								A2($author$project$Dialer$Types$SubmitTextFieldAnswer, question, callState.textFieldText)),
								$author$project$Dialer$Views$Helpers$addTestLabel('SubmitSurvey'),
								$elm$html$Html$Attributes$class('primary')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Submit')
							]))
					]));
		};
		var sendMessageQuestion = function (question) {
			var onClickSend = function (settings) {
				return callState.simulatedCall ? $author$project$Dialer$Types$SubmitNullAnswer(question) : A2(
					$author$project$Dialer$Types$SendMessageClicked,
					question.key,
					A2($author$project$Dialer$Views$ScriptParser$substituteFields, settings.text, callState));
			};
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('send-message-wrapper')
					]),
				function () {
					var _v12 = question.sendMessageSettings;
					if (_v12.$ === 'Nothing') {
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('send-message-continue-buttons')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Events$onClick(
												$author$project$Dialer$Types$SubmitNullAnswer(question)),
												$elm$html$Html$Attributes$class('primary')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Continue')
											]))
									]))
							]);
					} else {
						var settings = _v12.a;
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('send-message-input-wrapper')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('send-message-field-wrapper')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('send-message-text-field-label')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Confirm contact\'s number')
													])),
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('send-message-text-field'),
														$elm$html$Html$Attributes$value(callState.calleeMessagingPhoneNumber),
														$elm$html$Html$Events$onInput($author$project$Dialer$Types$CaptureCalleeMessagingPhoneNumber)
													]),
												_List_Nil)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('send-message-field-wrapper')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('send-message-text-field-label')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Message')
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('send-message-text-preview')
													]),
												A2($author$project$Dialer$Views$ScriptParser$formatAndSubstituteFields, settings.text, callState))
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('send-message-error')
									]),
								function () {
									var _v13 = callState.sendMessageError;
									if (_v13.$ === 'Just') {
										var sendMessageError = _v13.a;
										return _List_fromArray(
											[
												$elm$html$Html$text(sendMessageError)
											]);
									} else {
										return _List_Nil;
									}
								}()),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('send-message-continue-buttons')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Events$onClick(
												onClickSend(settings)),
												$author$project$Dialer$Views$Helpers$addTestLabel('SubmitSurvey'),
												$elm$html$Html$Attributes$disabled(
												callState.messageSubmitting || A2($elm$core$Set$member, question.key, callState.questionKeysWithMessagesSent)),
												$elm$html$Html$Attributes$class('send-message-button primary')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Send Text and Continue')
											])),
										A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Events$onClick(
												$author$project$Dialer$Types$SubmitNullAnswer(question)),
												$author$project$Dialer$Views$Helpers$addTestLabel('SubmitSurvey'),
												$elm$html$Html$Attributes$class('send-message-button secondary')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Skip')
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('send-message-info')
									]),
								callState.simulatedCall ? _List_fromArray(
									[
										$elm$html$Html$text('Clicking the \"Send Text and Continue\" button will not send a text message while in Preview Script mode.')
									]) : _List_Nil)
							]);
					}
				}());
		};
		var renderScript = F2(
			function (possibleScript, isRichText) {
				if (possibleScript.$ === 'Just') {
					var script = possibleScript.a;
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('script')
							]),
						A3($author$project$Dialer$Views$ScriptParser$displayScript, script, isRichText, callState));
				} else {
					return $author$project$Dialer$Views$Helpers$emptyDiv;
				}
			});
		var pollingPlaceLookupQuestion = function (question) {
			var questionResponseIsForCurrentQuestion = function (questionResponse) {
				if (questionResponse.$ === 'PollingPlaceLookupQuestionResponse') {
					var q = questionResponse.a;
					return _Utils_eq(q.key, question.key);
				} else {
					return false;
				}
			};
			var matchingQuestionResponse = $elm$core$List$head(
				A2($elm$core$List$filter, questionResponseIsForCurrentQuestion, callState.responses));
			var inputValue = function (maybeString) {
				if (maybeString.$ === 'Nothing') {
					return '';
				} else {
					var string = maybeString.a;
					return string;
				}
			};
			var hasRequiredAddressFields = function () {
				var _v7 = callState.calleeAddress;
				if (_v7.$ === 'Nothing') {
					return false;
				} else {
					var address = _v7.a;
					if ($elm$core$String$isEmpty(address)) {
						return false;
					} else {
						var _v8 = callState.calleeZip;
						if (_v8.$ === 'Nothing') {
							return false;
						} else {
							var zip = _v8.a;
							return !$elm$core$String$isEmpty(zip);
						}
					}
				}
			}();
			var addressForQuestionResponse = function (questionResponse) {
				if (questionResponse.$ === 'PollingPlaceLookupQuestionResponse') {
					var maybeAddress = questionResponse.b;
					return maybeAddress;
				} else {
					return $elm$core$Maybe$Nothing;
				}
			};
			var confirmedAddress = A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$core$Maybe$Nothing, addressForQuestionResponse, matchingQuestionResponse);
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('polling-place-lookup-and-actions')
					]),
				function () {
					var _v4 = question.pollingPlaceLookupSettings;
					if (_v4.$ === 'Nothing') {
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('polling-place-lookup-continue-buttons')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Events$onClick(
												$author$project$Dialer$Types$SubmitNullAnswer(question)),
												$elm$html$Html$Attributes$class('primary')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Continue')
											]))
									]))
							]);
					} else {
						var settings = _v4.a;
						if (confirmedAddress.$ === 'Nothing') {
							return _List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('polling-place-lookup-wrapper')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('polling-place-lookup-header')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('polling-place-lookup-header-text')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Voter\'s Address')
														]))
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('polling-place-lookup-form')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('polling-place-lookup-address')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$div,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Home Street Address*')
																])),
															A2(
															$elm$html$Html$input,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$value(
																	inputValue(callState.calleeAddress)),
																	$elm$html$Html$Events$onInput($author$project$Dialer$Types$CaptureCalleeAddress)
																]),
															_List_Nil)
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('polling-place-lookup-zip')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$div,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('Zip Code*')
																])),
															A2(
															$elm$html$Html$input,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$value(
																	inputValue(callState.calleeZip)),
																	$elm$html$Html$Events$onInput($author$project$Dialer$Types$CaptureCalleeZip)
																]),
															_List_Nil)
														]))
												]))
										])),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick($author$project$Dialer$Types$ConfirmPollingPlaceLookupAddress),
											$elm$html$Html$Attributes$disabled(!hasRequiredAddressFields),
											$elm$html$Html$Attributes$class('polling-place-lookup-button')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Look Up Polling Place')
										])),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Events$onClick(
													$author$project$Dialer$Types$SubmitNullAnswer(question)),
													$elm$html$Html$Attributes$class('polling-place-lookup-button skip-button')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Skip this Question >')
												]))
										]))
								]);
						} else {
							var address = confirmedAddress.a;
							return _List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('polling-place-lookup-wrapper')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('polling-place-lookup-header')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$button,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('change-address-button'),
															$elm$html$Html$Events$onClick($author$project$Dialer$Types$UndoSurveyResponseClicked)
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('< Change Address')
														])),
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('polling-place-lookup-header-text')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Voter\'s Address: '),
															A2(
															$elm$html$Html$strong,
															_List_Nil,
															_List_fromArray(
																[
																	$author$project$Dialer$Views$InCall$renderAddress(address)
																]))
														]))
												])),
											A4($author$project$Dialer$Views$InCall$renderPollingPlaceLookup, settings.lookupTool, address, model.client, callState.campaign.name)
										])),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Events$onClick($author$project$Dialer$Types$GoToNextQuestion),
													$author$project$Dialer$Views$Helpers$addTestLabel('SubmitSurvey'),
													$elm$html$Html$Attributes$class('polling-place-lookup-button')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Continue')
												]))
										]))
								]);
						}
					}
				}());
		};
		var nextActionsAfterSurvey = function () {
			var _v3 = _Utils_Tuple2(callState.simulatedCall, callState.ended);
			_v3$2:
			while (true) {
				if (!_v3.a) {
					if (_v3.b) {
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick($author$project$Dialer$Types$ContinueCallingClicked),
										$elm$html$Html$Attributes$class('primary'),
										$author$project$Dialer$Views$Helpers$addTestLabelFromMsg($author$project$Dialer$Types$ContinueCallingClicked)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Continue Calling')
									])),
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick($author$project$Dialer$Types$RetryLoadingApp),
										$elm$html$Html$Attributes$class('secondary')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('End Session')
									]))
							]);
					} else {
						break _v3$2;
					}
				} else {
					if (!_v3.b) {
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick($author$project$Dialer$Types$ResetSurveyPreviewClicked),
										$elm$html$Html$Attributes$class('primary')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Preview Script Again')
									])),
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick($author$project$Dialer$Types$ExitSurveyPreviewClicked),
										$elm$html$Html$Attributes$class('secondary')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Exit Script Preview')
									]))
							]);
					} else {
						break _v3$2;
					}
				}
			}
			return _List_Nil;
		}();
		var mobilizeAmericaRsvpQuestion = function (question) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mobilize-rsvp-wrapper')
					]),
				_List_fromArray(
					[
						function () {
						var _v2 = question.mobilizeAmericaRsvpSettings;
						if (_v2.$ === 'Nothing') {
							return A2($elm$html$Html$div, _List_Nil, _List_Nil);
						} else {
							var settings = _v2.a;
							return A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('mobilize-rsvp-iframe-wrapper')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$iframe,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('mobilize-rsvp-iframe'),
												$elm$html$Html$Attributes$src(
												A2($author$project$Dialer$Views$InCall$mobilizeAmericaEventUrl, callState, settings))
											]),
										_List_Nil)
									]));
						}
					}(),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mobilize-rsvp-continue-button')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick(
										$author$project$Dialer$Types$SubmitNullAnswer(question)),
										$author$project$Dialer$Views$Helpers$addTestLabel('SubmitSurvey'),
										$elm$html$Html$Attributes$class('primary')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Continue')
									]))
							]))
					]));
		};
		var classForNumberOfChoices = function (choices) {
			return ($elm$core$List$length(choices) > 2) ? 'three-or-more-choices' : 'two-or-less-choices';
		};
		var multipleChoiceQuestion = function (question) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('checkbox-input')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('actions'),
								$elm$html$Html$Attributes$class(
								classForNumberOfChoices(question.answers))
							]),
						A2(
							$elm$core$List$map,
							function (answer) {
								return A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick(
											A2($author$project$Dialer$Types$SurveyResultMultipleResponseClicked, question, answer)),
											$author$project$Dialer$Views$Helpers$addTestLabel(answer.key),
											$elm$html$Html$Attributes$class(
											A2($elm$core$List$member, answer, callState.checkboxAnswers) ? 'checkbox-checked' : 'checkbox-unchecked')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											$elm_community$string_extra$String$Extra$toSentenceCase(answer.value))
										]));
							},
							question.answers)),
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick(
								A2($author$project$Dialer$Types$SubmitMultipleResponseAnswers, question, callState.checkboxAnswers)),
								$author$project$Dialer$Views$Helpers$addTestLabel('SubmitSurvey'),
								$elm$html$Html$Attributes$class('primary')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Submit')
							]))
					]));
		};
		var singleChoiceQuestion = function (question) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('actions'),
						$elm$html$Html$Attributes$class(
						classForNumberOfChoices(question.answers))
					]),
				A2(
					$elm$core$List$map,
					function (answer) {
						return A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick(
									A2($author$project$Dialer$Types$SurveyResultSingleResponseClicked, question, answer)),
									$author$project$Dialer$Views$Helpers$addTestLabel(answer.key)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$elm_community$string_extra$String$Extra$toSentenceCase(answer.value))
								]));
					},
					question.answers));
		};
		var inputForQuestion = function (question) {
			var _v1 = question.questionType;
			switch (_v1.$) {
				case 'SingleChoice':
					return singleChoiceQuestion(question);
				case 'Disposition':
					return singleChoiceQuestion(question);
				case 'PatchThru':
					return singleChoiceQuestion(question);
				case 'MultipleChoice':
					return multipleChoiceQuestion(question);
				case 'TextField':
					return textFieldQuestion(question);
				case 'MobilizeamericaRsvp':
					return mobilizeAmericaRsvpQuestion(question);
				case 'SendMessage':
					return sendMessageQuestion(question);
				default:
					return pollingPlaceLookupQuestion(question);
			}
		};
		return A7(
			$author$project$Dialer$Views$Layout$renderLayout,
			callState.campaign.name,
			A2($author$project$Dialer$Views$Helpers$activeCallerAlert, model.campaignAlerts, model.currentTime),
			function () {
				var _v0 = callState.nextQuestion;
				if (_v0.$ === 'Just') {
					var question = _v0.a;
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('call-ui-container')
							]),
						_List_fromArray(
							[
								undoButtonIfPreviousQuestion(question),
								A2(renderScript, question.script, question.isRichText),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('actions-container')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('question-text')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(question.name)
											])),
										inputForQuestion(question)
									]))
							]));
				} else {
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('call-ui-survey-complete-container')
							]),
						_List_fromArray(
							[
								undoButton,
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('actions')
									]),
								_Utils_ap(
									A2($elm$core$List$map, $author$project$Dialer$Views$InCall$renderAnsweredQuestion, callState.responses),
									nextActionsAfterSurvey))
							]));
				}
			}(),
			$author$project$Dialer$Views$Layout$statusClassForCallState(callState),
			$author$project$Dialer$Views$InCall$inCallStatus(callState),
			A2($author$project$Dialer$Views$Sidebar$renderSidebarContent, model, callState),
			A2($author$project$Dialer$Views$Sidebar$sidebarIsOpen, model, callState));
	});
var $author$project$Dialer$Views$Layout$renderInstructions = F5(
	function (campaign, campaignAlerts, currentTime, message, instructions) {
		return A5(
			$author$project$Dialer$Views$Layout$renderDialog,
			campaign.name,
			A2($author$project$Dialer$Views$Helpers$activeCallerAlert, campaignAlerts, currentTime),
			message,
			instructions,
			_List_fromArray(
				[
					_Utils_Tuple2($author$project$Dialer$Types$CallAnotherWay, 'Call another way')
				]));
	});
var $author$project$Dialer$Views$Layout$renderInstructionsWithPreview = F5(
	function (campaign, campaignAlerts, currentTime, message, instructions) {
		return A5(
			$author$project$Dialer$Views$Layout$renderDialog,
			campaign.name,
			A2($author$project$Dialer$Views$Helpers$activeCallerAlert, campaignAlerts, currentTime),
			message,
			instructions,
			_List_fromArray(
				[
					_Utils_Tuple2($author$project$Dialer$Types$PreviewScriptClicked, 'Script Preview'),
					_Utils_Tuple2($author$project$Dialer$Types$CallAnotherWay, 'Call another way')
				]));
	});
var $author$project$Dialer$Views$Images$loader = A2(
	$elm$html$Html$img,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$src('assets/loader.svg'),
			$elm$html$Html$Attributes$class('loader')
		]),
	_List_Nil);
var $author$project$Dialer$Views$Loading$renderLoader = function (heading) {
	return A3($author$project$Dialer$Views$Layout$renderLayoutWithMessage, heading, $elm$core$Maybe$Nothing, $author$project$Dialer$Views$Images$loader);
};
var $author$project$Dialer$Views$Loading$renderLoaderWhilePossiblyInCall = F3(
	function (callState, model, message) {
		return A7(
			$author$project$Dialer$Views$Layout$renderLayout,
			callState.campaign.name,
			A2($author$project$Dialer$Views$Helpers$activeCallerAlert, model.campaignAlerts, model.currentTime),
			$author$project$Dialer$Views$Images$loader,
			$author$project$Dialer$Views$Layout$statusClassForCallState(callState),
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('message'),
						A2($elm$html$Html$Attributes$attribute, 'data-message', message)
					]),
				_List_fromArray(
					[
						$author$project$Dialer$Views$Layout$statusLabel,
						$elm$html$Html$text(message)
					])),
			A2($author$project$Dialer$Views$Sidebar$renderSidebarContent, model, callState),
			A2($author$project$Dialer$Views$Sidebar$sidebarIsOpen, model, callState));
	});
var $author$project$Dialer$Views$Layout$renderMessage = F3(
	function (heading, callerAlert, message) {
		return A4($author$project$Dialer$Views$Layout$renderMessageWithChoiceOfActions, heading, callerAlert, message, _List_Nil);
	});
var $author$project$Dialer$Views$Layout$renderMessageWithReloadApp = function (message) {
	return A5(
		$author$project$Dialer$Views$Layout$renderDialog,
		'Session ended',
		$elm$core$Maybe$Nothing,
		'Not connected',
		$author$project$Dialer$Views$AlertParser$formatAlert(message),
		_List_fromArray(
			[
				_Utils_Tuple2($author$project$Dialer$Types$RetryLoadingApp, 'Retry')
			]));
};
var $author$project$Dialer$Views$PhoneOnly$renderPhoneInInstruction = F3(
	function (campaign, phone, _v0) {
		return _List_fromArray(
			[
				$elm$html$Html$text(
				'Call ' + (A2($author$project$Utils$formatNumber, campaign.country_iso, phone) + ' and follow the instructions. Use the Script Preview to familiarize yourself with the script.'))
			]);
	});
var $author$project$Dialer$Views$Images$inQueueRinging = A2(
	$elm$html$Html$img,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$src('assets/phone.gif'),
			$elm$html$Html$Attributes$class('in-queue-ringing')
		]),
	_List_Nil);
var $author$project$Dialer$Views$InQueue$noJoke = A2($author$project$Dialer$Types$Joke, '', '');
var $author$project$Dialer$Views$InQueue$renderWaitingInQueue = F4(
	function (heading, alertMessage, jokeIndex, shuffledJokes) {
		var joke = A2(
			$elm$core$Maybe$withDefault,
			$author$project$Dialer$Views$InQueue$noJoke,
			A2(
				$elm$core$Array$get,
				jokeIndex,
				$elm$core$Array$fromList(shuffledJokes)));
		return A3(
			$author$project$Dialer$Views$Layout$renderLayoutWithMessage,
			heading,
			alertMessage,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('queue-loader')
					]),
				_List_fromArray(
					[
						$author$project$Dialer$Views$Images$inQueueRinging,
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('queue-message')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Hang tight, we\'re dialing people for you to talk to.')
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('joke')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('setup')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(joke.setup)
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('punchline')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(joke.punchline)
									]))
							]))
					])));
	});
var $author$project$Dialer$Views$Login$renderWebPhoneInInstructions = F2(
	function (campaign, phone) {
		return _List_fromArray(
			[
				$elm$html$Html$text(
				'Please call ' + (A2($author$project$Utils$formatNumber, campaign.country_iso, phone) + ' and follow the instructions'))
			]);
	});
var $author$project$Dialer$View$view = function (model) {
	var _v0 = model.state;
	switch (_v0.$) {
		case 'LoadingCampaign':
			return A2($author$project$Dialer$Views$Loading$renderLoader, '', 'Connecting...');
		case 'RefreshCampaign':
			return A2($author$project$Dialer$Views$Loading$renderLoader, '', 'Connecting...');
		case 'NoMatchingCampaignForToken':
			return A3($author$project$Dialer$Views$Layout$renderMessage, 'Session ended', $elm$core$Maybe$Nothing, 'Invalid token. Perhaps it has expired? Please re-request the link from your administrator.');
		case 'CampaignReady':
			var campaign = _v0.a.campaign;
			return A3($author$project$Dialer$Views$Login$renderChooseCallInMethod, campaign, model.campaignAlerts, model.currentTime);
		case 'ConfirmLastCallInMethod':
			var campaign = _v0.a.campaign;
			var lastMedium = _v0.a.lastMedium;
			return A4($author$project$Dialer$Views$Login$renderConfirmLastCallInMethod, campaign, model.campaignAlerts, model.currentTime, lastMedium);
		case 'ScriptPreview':
			var campaign = _v0.a.campaign;
			return A2(
				$author$project$Dialer$Views$InCall$renderInCallUI,
				model,
				$author$project$Dialer$States$ScriptPreview$createSimulatedCallStateFromCampaign(campaign));
		case 'PromptForCallersPhoneNumberToConnect':
			var campaign = _v0.a.campaign;
			var callersName = _v0.a.callersName;
			var callersPhoneNumber = _v0.a.callersPhoneNumber;
			var callersEmail = _v0.a.callersEmail;
			return A6($author$project$Dialer$Views$Login$renderCallerInfoInput, campaign, model.campaignAlerts, model.currentTime, callersName, callersPhoneNumber, callersEmail);
		case 'PromptToPhoneIn':
			var campaign = _v0.a.campaign;
			var _v1 = _Utils_Tuple2(campaign.ivr, campaign.phone);
			if (_v1.a && (_v1.b.$ === 'Just')) {
				var phone = _v1.b.a;
				return A5(
					$author$project$Dialer$Views$Layout$renderInstructionsWithPreview,
					campaign,
					model.campaignAlerts,
					model.currentTime,
					'Not connected',
					A3($author$project$Dialer$Views$PhoneOnly$renderPhoneInInstruction, campaign, phone, model.token));
			} else {
				return A4($author$project$Dialer$Views$Layout$renderMessageWithRetry, campaign, model.campaignAlerts, model.currentTime, 'The campaign does not have an inbound phone number set');
			}
		case 'SubmittingPhoneNumber':
			var campaign = _v0.a.campaign;
			return A2($author$project$Dialer$Views$Loading$renderLoader, campaign.name, 'Connecting...');
		case 'ReadyToPhoneIn':
			var campaign = _v0.a.campaign;
			var _v2 = campaign.web_phone;
			if (_v2.$ === 'Just') {
				var phone = _v2.a;
				return A5(
					$author$project$Dialer$Views$Layout$renderInstructions,
					campaign,
					model.campaignAlerts,
					model.currentTime,
					'Connected',
					A2($author$project$Dialer$Views$Login$renderWebPhoneInInstructions, campaign, phone));
			} else {
				return A4($author$project$Dialer$Views$Layout$renderMessageWithRetry, campaign, model.campaignAlerts, model.currentTime, 'The campaign does not have an inbound phone number set');
			}
		case 'DialerInterfaceLoaded':
			var campaign = _v0.a.campaign;
			return A2($author$project$Dialer$Views$Loading$renderLoader, campaign.name, 'Connecting...');
		case 'DialerInterfaceConnected':
			var campaign = _v0.a.campaign;
			return A4(
				$author$project$Dialer$Views$Layout$renderMessageWithChoiceOfActions,
				campaign.name,
				A2($author$project$Dialer$Views$Helpers$activeCallerAlert, model.campaignAlerts, model.currentTime),
				'Connected',
				_List_fromArray(
					[
						A2($author$project$Dialer$Views$Helpers$primaryAction, $author$project$Dialer$Types$StartCallingClicked, 'Start calling'),
						$author$project$Dialer$Types$Action(
						{actionClass: 'secondary', context: $elm$core$Maybe$Nothing, label: 'Call another way', msg: $author$project$Dialer$Types$CallAnotherWay})
					]));
		case 'PhoneDialerInterfaceConnected':
			var campaign = _v0.a.campaign;
			return A4(
				$author$project$Dialer$Views$Layout$renderMessageWithChoiceOfActions,
				campaign.name,
				A2($author$project$Dialer$Views$Helpers$activeCallerAlert, model.campaignAlerts, model.currentTime),
				'Connected',
				_List_fromArray(
					[
						A2($author$project$Dialer$Views$Helpers$primaryAction, $author$project$Dialer$Types$StartCallingClicked, 'Start calling'),
						$author$project$Dialer$Types$Action(
						{actionClass: 'secondary', context: $elm$core$Maybe$Nothing, label: 'Call another way', msg: $author$project$Dialer$Types$CallAnotherWay})
					]));
		case 'EnteringQueue':
			var campaign = _v0.a.campaign;
			return A2($author$project$Dialer$Views$Loading$renderLoader, campaign.name, 'Entering the call queue...');
		case 'InQueue':
			var campaign = _v0.a.campaign;
			var enteredQueueAt = _v0.a.enteredQueueAt;
			return A5(
				$author$project$Dialer$Views$InQueue$renderWaitingInQueue,
				campaign.name,
				A3($author$project$Dialer$View$alertForCampaign, campaign, model, enteredQueueAt),
				model.jokeIndex,
				model.shuffledJokes,
				'In the call queue.');
		case 'InCall':
			var callState = _v0.a;
			return A2($author$project$Dialer$Views$InCall$renderInCallUI, model, callState);
		case 'EndingConference':
			var callState = _v0.a;
			return A3($author$project$Dialer$Views$Loading$renderLoaderWhilePossiblyInCall, callState, model, 'Ending call...');
		case 'SubmittingSurveyResponse':
			var callState = _v0.a;
			return A3($author$project$Dialer$Views$Loading$renderLoaderWhilePossiblyInCall, callState, model, 'Submitting responses...');
		case 'CallCompleteAndSurveyComplete':
			var callState = _v0.a;
			return A2($author$project$Dialer$Views$InCall$renderInCallUI, model, callState);
		case 'SessionComplete':
			return A4(
				$author$project$Dialer$Views$Layout$renderMessageWithChoiceOfActions,
				'Session complete',
				$elm$core$Maybe$Nothing,
				'Your session has ended.',
				_List_fromArray(
					[
						A2($author$project$Dialer$Views$Helpers$primaryAction, $author$project$Dialer$Types$RetryLoadingApp, 'Resume calling')
					]));
		case 'MutationError':
			var error = _v0.a;
			return $author$project$Dialer$Views$Layout$renderMessageWithReloadApp(
				$author$project$Utils$gqlErrorMessage(error));
		case 'CampaignLoadError':
			var error = _v0.a;
			return $author$project$Dialer$Views$Layout$renderMessageWithReloadApp(
				$author$project$Utils$gqlErrorMessage(error));
		case 'ErrorWithFeedback':
			var feedback = _v0.a;
			return $author$project$Dialer$Views$Layout$renderMessageWithReloadApp(feedback);
		default:
			return $author$project$Dialer$Views$Layout$renderMessageWithReloadApp('An application error has occurred. The development team will be notified.');
	}
};
var $author$project$Main$view = function (model) {
	switch (model.$) {
		case 'IncorrectParameters':
			return $author$project$Main$renderMessage('Incorrect link. The link you were given is missing data. Please re-request the link from your administrator');
		case 'ClickToCall':
			var clickToCallModel = model.a;
			return A2(
				$elm$html$Html$map,
				$author$project$Main$GotClickToCallMsg,
				$author$project$ClickToCall$View$view(clickToCallModel));
		default:
			var dialerModel = model.a;
			return A2(
				$elm$html$Html$map,
				$author$project$Main$GotDialerMsg,
				$author$project$Dialer$View$view(dialerModel));
	}
};
var $author$project$Main$main = $elm$browser$Browser$element(
	{init: $author$project$Main$init, subscriptions: $author$project$Main$subscriptions, update: $author$project$Main$update, view: $author$project$Main$view});
/*
_Platform_export({'Main':{'init':$author$project$Main$main($elm$json$Json$Decode$value)(0)}});}(this));
*/
export const Elm = {'Main':{'init':$author$project$Main$main($elm$json$Json$Decode$value)(0)}};
  