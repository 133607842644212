import {
  Editor,
  EditorState,
  convertFromRaw,
  CompositeDecorator,
  Modifier,
  SelectionState,
  ContentBlock,
} from "draft-js";
import { OrderedSet } from "immutable";
import React, { ReactElement } from "react";

import registerCustomElement from "./registerCustomElement.jsx";

const LINK_REGEX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;

const Link = ({ decoratedText }: { decoratedText: string }) => {
  return (
    <a
      // content within the draft-js editor doesn't respond to clicks the way I expect, so we have to force it;
      // leaving as an `a` tag for accessibility.
      onClick={() => window.open(decoratedText, "_blank", "noreferrer")}
      style={{ cursor: "pointer" }}
      href={decoratedText}
      target="_blank"
    >
      {decoratedText}
    </a>
  );
};

const findWithRegex = (
  regex: RegExp,
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void
) => {
  const text = contentBlock.getText();
  let matchArr, start;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
};

type CalleeField = {
  id: string | null;
  dataSourceFieldName: string;
  name: string;
  builtInField: string;
};

function DraftJs({
  script: scriptString,
  calleedata: calleeDataString,
  calleefields: calleeFieldsString,
}: {
  script: string;
  calleedata: string;
  calleefields: string;
}) {
  const script = JSON.parse(scriptString);
  const calleeData = JSON.parse(calleeDataString || "{}") as {
    [key: string]: string;
  };
  const calleeFields = JSON.parse(calleeFieldsString || "[]") as CalleeField[];

  const calleeFieldsById = calleeFields.reduce((acc, field) => {
    if (!field.id) return acc;

    acc[field.id] = field;
    return acc;
  }, {} as { [id: string]: CalleeField });

  const findBuiltInField = (builtInField: string) =>
    calleeFields.find(
      (field) => field.builtInField && field.builtInField === builtInField
    );

  const CalleeFieldSpan = ({ children }: { children: ReactElement }) => {
    return (
      <span
        style={{
          background: "#D7D5E0",
          borderRadius: "1rem",
          padding: "0 0.5rem",
          display: "inline-block",
          lineHeight: "1.5",
        }}
      >
        {children}
      </span>
    );
  };

  const getCalleeFieldDisplayText = (entityData?: {
    calleeFieldId?: string;
    builtInField?: string;

    // v1 editor structure; pre-rich-text
    field?: {
      id?: string;
      builtInField?: string;
    };
  }) => {
    if (!entityData) throw new Error("No entity data provided");

    if (entityData.calleeFieldId || entityData.field?.id) {
      const field = calleeFields.find(
        (field) =>
          (!!field.id && field.id === entityData.calleeFieldId) ||
          field.id === entityData.field?.id
      );

      if (
        field &&
        field.dataSourceFieldName &&
        calleeData[field.dataSourceFieldName]?.length
      )
        return calleeData[field.dataSourceFieldName];
    }

    if (entityData.builtInField || entityData.field?.builtInField) {
      // We should not reach this code path, but as a fallback
      const builtInFieldName =
        entityData.builtInField || entityData.field?.builtInField || "";
      console.warn(
        `Callee field ID not found; relying on builtInField for ${builtInFieldName}`
      );

      const builtInField = findBuiltInField(builtInFieldName);
      if (builtInField?.id) {
        const field = calleeFieldsById[builtInField.id];
        return calleeData[field?.dataSourceFieldName] || builtInField.name;
      }
    }

    return null;
  };

  const decorator = new CompositeDecorator([
    {
      strategy: function (contentBlock, callback, contentState) {
        contentBlock.findEntityRanges((character) => {
          const entityKey = character.getEntity();
          if (entityKey === null) {
            return false;
          }
          return contentState.getEntity(entityKey).getType() === "CALLEE_FIELD";
        }, callback);
      },
      component: CalleeFieldSpan,
    },
    {
      strategy: function (contentBlock, callback) {
        return findWithRegex(LINK_REGEX, contentBlock, callback);
      },
      component: Link,
    },
  ]);

  let contentWithCalleeData = convertFromRaw(script);

  let blocks = contentWithCalleeData.getBlockMap();
  let blockKey = 0;

  while (blockKey < blocks.size) {
    const originalBlock = blocks.get(blocks.keySeq().get(blockKey));
    if (!originalBlock) {
      return;
    }
    let block = originalBlock;
    const blockEntityRanges: { start: number; end: number }[] = [];

    block.findEntityRanges(
      (block) => block.getEntity() !== null,
      (start, end) => {
        const entity = contentWithCalleeData.getEntity(
          block.getEntityAt(start)
        );
        if (entity.getType() === "CALLEE_FIELD") {
          blockEntityRanges.push({ start, end });
        }
      }
    );

    if (blockEntityRanges.length === 0) {
      blockKey++;
      continue;
    } else {
      const { start, end } = blockEntityRanges[0];

      const entity = contentWithCalleeData.getEntity(block.getEntityAt(start));
      const fieldBlock = entity.getData();

      const fieldData = getCalleeFieldDisplayText(fieldBlock) || "";

      const selection = SelectionState.createEmpty(block.getKey()).merge({
        anchorOffset: start,
        focusOffset: end,
      });

      contentWithCalleeData = Modifier.replaceText(
        contentWithCalleeData,
        selection,
        fieldData,
        OrderedSet.of("BOLD"),
        entity.getData().key
      );
      blocks = contentWithCalleeData.getBlockMap();
    }
  }

  const editorState = EditorState.createWithContent(
    contentWithCalleeData,
    decorator
  );

  const editor = React.useRef<Editor>(null);

  return (
    <Editor
      readOnly
      editorState={editorState}
      onChange={() => editor?.current?.blur()}
      ref={editor}
    />
  );
}

registerCustomElement(DraftJs, "draft-js-element");
