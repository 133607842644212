import { Device, TwilioError } from "@twilio/voice-sdk";
//@ts-ignore
import promiseAny from "core-js-pure/actual/promise/any";
import Pusher, { Channel } from "pusher-js";
import { ElmApp, ToElm } from "types/elm";

import Sentry from "./sentry";
import { FromElmData } from "./types";

type PusherState =
  | "initialized"
  | "connecting"
  | "connected"
  | "disconnected"
  | "unavailable"
  | "failed";
type PusherStateChangePayload = {
  current: PusherState;
  previous: PusherState;
};

let pusher: Pusher;
let device: Device;
let twilioDestroyed = false;
let twilioConnectionAlreadyLost = false;

function cleanupAllConnections() {
  if (pusher) {
    pusher.disconnect();
  }

  // Disconnect the webrtc connection (and end the active call)
  if (device && !twilioDestroyed) {
    // Prevent any recursive running of this code due to being
    // retriggered by listeners
    twilioDestroyed = true;
    device.destroy();
  }
}

function networkIssueMessage(errorCode: string | number): string {
  return (
    "Network connection lost [code " +
    errorCode +
    "]. Please check your network connection or report an issue if this is a persistent problem."
  );
}

type PusherConfig = { appId: string; cluster: string; key: string };

function connectToPusher({
  baseUrl,
  tenant,
  token,
  callerChannelId,
  pusherConfig: { appId, cluster, key },
  didLoseRace,
}: {
  baseUrl: string;
  tenant: string;
  token: string;
  callerChannelId: string;
  pusherConfig: PusherConfig;
  didLoseRace: () => boolean;
}): Promise<{
  pusherClient: Pusher;
  callerChannel: Channel;
  pusherConfig: PusherConfig;
}> {
  const pusherAuthUrl = `${baseUrl}/pusher/auth?tenant=${tenant}&token=${token}&app_id=${appId}`;

  const pusher = new Pusher(key, {
    cluster: cluster,
    forceTLS: true,
    authEndpoint: pusherAuthUrl,
  });

  const channel = pusher.subscribe(
    ["private-caller", tenant, token, callerChannelId].join("-")
  );

  return new Promise((resolve, reject) => {
    function tearDownEventHandlers() {
      pusher.connection.unbind("state_change");
      pusher.connection.unbind("error");
      channel.unbind("pusher:subscription_succeeded");
      channel.unbind("pusher:subscription_error");
    }

    function tearDownConnection() {
      tearDownEventHandlers();
      pusher.disconnect();
    }

    let hasResolvedOrRejected = false;
    function handleFailure(err: Error) {
      if (hasResolvedOrRejected) {
        return;
      }
      hasResolvedOrRejected = true;

      tearDownConnection();
      reject(err);
    }

    function handleSuccess() {
      if (hasResolvedOrRejected) {
        return;
      }
      hasResolvedOrRejected = true;

      if (didLoseRace()) {
        // we connected to a different Pusher region first -- tear everything
        // down; we're not going to use this connection
        tearDownConnection();
      } else {
        // Just un-bind event handlers; we'll use this connection but the caller
        // will set up the real event handlers
        tearDownEventHandlers();
      }

      resolve({
        pusherClient: pusher,
        callerChannel: channel,
        pusherConfig: { appId, cluster, key },
      });
    }

    // Set up event handlers for initial connection success/failure. Once we
    // determine which Pusher region we're going to use, we'll tear down these
    // event handlers and set up new ones for the actual event processing --
    // we're just trying to determine initial connection success/failure here
    pusher.connection.bind(
      "state_change",
      function (states: PusherStateChangePayload) {
        if (
          ["failed", "disconnected", "unavailable"].includes(states.current)
        ) {
          handleFailure(
            new Error(`Pusher connection failed; state: ${states.current}}`)
          );
        }
      }
    );

    pusher.connection.bind("error", function (error: Error) {
      handleFailure(error);
    });

    channel.bind("pusher:subscription_succeeded", function () {
      handleSuccess();
    });

    channel.bind("pusher:subscription_error", function (data: any) {
      handleFailure(
        new Error(`Pusher subscription error: ${JSON.stringify(data)}`)
      );
    });
  });
}

function startPusherSubscriptions({
  pusher,
  callerChannel,
  sendEvent,
}: {
  pusher: Pusher;
  callerChannel: Channel;
  sendEvent: (payload: ToElm) => void;
}): void {
  const closeConnectionAndSendEvent = (context: any) => {
    Sentry.addBreadcrumb({
      category: "pusher",
      message: "connection lost",
      level: "info",
      data: context,
    });

    sendEvent({
      name: "connection_lost",
      value: {
        message: networkIssueMessage("notification"),
      },
    });
    cleanupAllConnections();
  };

  let pusherTimeoutId: NodeJS.Timeout | undefined;
  const pusherTimeoutSeconds = 30;
  // See: https://github.com/pusher/pusher-js#connection-states
  pusher.connection.bind(
    "state_change",
    function (states: PusherStateChangePayload) {
      if (window.DEBUG) console.log("Pusher state change: ", states);
      switch (states.current) {
        case "failed":
          closeConnectionAndSendEvent({
            message: "Failed to establish connection",
          });
          break;
        case "unavailable":
          // connection lost temporarily, give Pusher some time to re-establish
          // the connection but transition to a failed state if we can't after 30s
          // TODO: we might want to send an event to the elm code to give some
          //   indication that we are reconnecting
          if (!pusherTimeoutId) {
            pusherTimeoutId = setTimeout(() => {
              closeConnectionAndSendEvent({
                message: `Pusher failed to re-establish connection within ${pusherTimeoutSeconds}s timeout`,
              });
            }, pusherTimeoutSeconds * 1000);
          }
          break;
        default:
          // This catches 'initialize', 'connecting', 'connected' and 'disconnected'.
          //  The latter is triggered when the connection is closed intentionally. If we
          //  write code to close the connection intentionally, it should also handle sending
          //  the appropriate event to the elm code.
          if (pusherTimeoutId) {
            // We are back in a good state so cancel the timeout
            clearTimeout(pusherTimeoutId);
            pusherTimeoutId = undefined;
          }
          break;
      }
    }
  );
  pusher.connection.bind("error", function (error: Error) {
    console.error("Pusher connection error: ", error);
    // Note: we handle the state changes triggered by errors in the state change
    // handler above rather than handling the errors here.
  });

  callerChannel.bind_global(function (eventName: string, data: any) {
    if (window.DEBUG) {
      console.log("channel event: ", eventName, data);
    }

    if (eventName === "pusher:subscription_error") {
      if (window.DEBUG) console.error("Pusher error: ", { eventName, data });
      closeConnectionAndSendEvent({ channel: true, eventName, data });
    } else if (!eventName.match(/^pusher/)) {
      sendEvent(data);
    } else {
      if (window.DEBUG) {
        console.error("Ignored pusher event: ", eventName, data);
      }
    }
  });
}

function connectToTwilio({
  twilioToken,
  pusherConfig,
  sendEvent,
  data,
}: {
  twilioToken: string;
  pusherConfig: PusherConfig;
  sendEvent: (payload: ToElm) => void;
  data: {
    phone: string;
    name: string;
    email: string;
    referralCode?: string;
    caller_channel_id: string;
  };
}) {
  if (!Device.isSupported) {
    return alert(
      "Your browser is not currently supported. Please use a recent version of Firefox, Chrome, Edge or Safari."
    );
  }

  device = new Device(twilioToken, {
    logLevel: "DEBUG",
  });

  const twilioErrorHandler = (twilioError: TwilioError.TwilioError) => {
    if (twilioConnectionAlreadyLost) {
      return;
    }

    const message =
      "Telephony error " +
      (twilioError && twilioError.code ? twilioError.code : "unknown") +
      (twilioError
        ? ": " + (twilioError.description || twilioError.message)
        : "") +
      ". Please report an issue if this is a persistent problem.";

    Sentry.addBreadcrumb({
      category: "twilio",
      message: message,
      level: "info",
    });
    if (window.DEBUG) {
      console.error(twilioError);
    }
    twilioConnectionAlreadyLost = true;
    // Unfortunately Twilio seems to throw a generic error when the websocket
    // connection is lost. For everything else, ensure that we pass the
    // message to be displayed on the error screen.
    if (
      twilioError &&
      [31000, 53000, 53405, 31009, 31005].includes(twilioError.code)
    ) {
      sendEvent({
        name: "connection_lost",
        value: {
          message: networkIssueMessage(twilioError.code),
        },
      });
    } else if (twilioError && twilioError.code === 31401) {
      sendEvent({
        name: "error",
        value: {
          message:
            "Permission is denied to your microphone. Please accept the permission if prompted, or update the permissions in your web browser.",
        },
      });
    } else if (twilioError && twilioError.code === 31402) {
      sendEvent({
        name: "error",
        value: {
          message:
            "Connection lost to your audio hardware. Check that your microphone and speakers are connected.",
        },
      });
    } else {
      sendEvent({ name: "error", value: { message: message } });
    }
    cleanupAllConnections();
  };
  device.on("error", twilioErrorHandler);
  device
    .connect({
      params: {
        From: data.phone,
        name: data.name,
        email: data.email,
        callerChannelId: data.caller_channel_id,
        pusherAppId: pusherConfig.appId,
        ...(data.referralCode !== undefined
          ? { referralCode: data.referralCode }
          : {}),
      },
    })
    .then(function (call) {
      call.on("error", twilioErrorHandler);
    })
    .catch(function (connectErr) {
      console.error(connectErr);
    });
}

export default async function connect(
  data: FromElmData<"connect">,
  app: ElmApp
): Promise<void> {
  function sendEvent(payload: ToElm) {
    if (window.DEBUG) {
      console.info("Received event: ", { payload });
    }

    app.ports.interopToElm.send(payload);
  }

  // First: connect to Pusher. We have multiple pusher configs since we
  // operate in multiple Pusher regions, so we're going to race here: we'll
  // try to connect to all the available pusher configs simultaneously, and
  // then use whichever one connects first.
  let pusherConnectionFinished = false;
  let selectedPusherConfig: PusherConfig;
  try {
    const { pusherClient, callerChannel, pusherConfig } = await promiseAny(
      data.pusherConfigs.map((c) =>
        connectToPusher({
          baseUrl: data.base_url,
          tenant: data.tenant,
          token: data.token,
          callerChannelId: data.caller_channel_id,
          pusherConfig: c,
          didLoseRace: () => pusherConnectionFinished,
        })
      )
    );
    pusherConnectionFinished = true;
    pusher = pusherClient;
    selectedPusherConfig = pusherConfig;

    startPusherSubscriptions({
      pusher: pusherClient,
      callerChannel,
      sendEvent,
    });

    console.log(`Selected pusher region: ${pusherConfig.cluster}`);

    sendEvent({
      name: "subscribed",
      value: pusherConfig,
    });
  } catch (e: any) {
    pusherConnectionFinished = true;

    console.error("All pusher connections failed", e);

    if (e && e.errors && Array.isArray(e.errors)) {
      e.errors.forEach((innerErr: any) => console.error(innerErr));
    }

    sendEvent({
      name: "error",
      value: {
        message: networkIssueMessage("notification"),
      },
    });

    return;
  }

  if (data.dial_in) {
    // Not using WebRTC; we're done
    return;
  }

  // We're using WebRTC; connect to Twilio
  connectToTwilio({
    twilioToken: data.twilio_token!,
    pusherConfig: selectedPusherConfig,
    sendEvent,
    data,
  });
}
