import * as Sentry from "@sentry/browser";

const useSentry = !window.location.hostname.match(/localhost/);

if (useSentry) {
  Sentry.init({
    dsn: "https://4b5244772d28422e8683999c91353668@o401912.ingest.sentry.io/5748186",
    release: "1.0.0",
    beforeSend(event) {
      Sentry.showReportDialog({
        eventId: event.event_id,
        labelSubmit: "Submit",
        title: "Report an issue with the dialer",
      });
      return event;
    },
  });
}

export default Sentry;

export function reportIssue(): void {
  Sentry.captureMessage("user error reported");
}
