import "../src/web-components/DraftJs.jsx";

import { Elm } from "../src/Main.elm";
import elmMessageHandlers from "./elmMessageHandlers";
import "./sentry";

window.DEBUG = false;

const app = Elm.Main.init({
  node: document.getElementById("elm-app")!,
  flags: {
    domain: window.location.hostname,
    query: window.location.search,
    phone: localStorage.getItem("caller.phone"),
    name: localStorage.getItem("caller.name"),
    email: localStorage.getItem("caller.email"),
    lastMedium: localStorage.getItem("caller.lastMedium"),
    currentTimeMillis: Date.now(),
  },
});

// Prevent users from accidentally exiting the tool when the back button is used
history.pushState(null, document.title, location.href);
window.addEventListener("popstate", function () {
  const leavePage = confirm(
    "Do you wish to leave this page and end your dialer session?"
  );
  if (leavePage) {
    history.back();
  } else {
    history.pushState(null, document.title, location.href);
  }
});

export { app };

app.ports.interopFromElm.subscribe(function (message) {
  if (window.DEBUG) {
    console.info("Received message from elm: ", { message });
  }

  const handler = elmMessageHandlers[message.tag] as any;
  if (handler) {
    handler(message.data as any, app);
  }
});
