import React from "react"
import ReactDOM from "react-dom"
import "document-register-element"


function render(ComponentClass) {
  return function() {
    const attributesCopy = [].slice.call(this.attributes)
    const props = attributesCopy.reduce((attrs, attr) => {
      attrs[attr.nodeName] = attr.nodeValue
      return attrs
    }, {})

    ReactDOM.render(
      <ComponentClass {...props} />, this
    )
  }
}

function detach() {
  ReactDOM.unmountComponentAtNode(this)
}

function created() {}

function registerCustomElement(ComponentClass, tagName) {
  const elementPrototype = Object.create(HTMLElement.prototype)

  elementPrototype.attachedCallback = render(ComponentClass)
  elementPrototype.attributeChangedCallback = render(ComponentClass)
  elementPrototype.detachedCallback = detach
  elementPrototype.createdCallback = created

  try {
    return document.registerElement(tagName || ComponentClass.displayName, {
      prototype: elementPrototype,
    })
  } catch(e) {
    console.error(e)
  }
}

export default registerCustomElement
